import { Checkbox } from "antd";
import { useEffect, useState } from "react";

function DocPrintModal(props) {

  ////Checkbox control
  const CheckboxGroup = Checkbox.Group;

  useEffect(() => {
    // //발주처 파일 확인 문구 적용.
    const selectOption = document?.getElementsByClassName('ant-checkbox-wrapper ant-checkbox-group-item');
    // selectOption.item(7).style.fontSize = "10px";
    // selectOption.item(7).style.justifySelf = "right";
    // selectOption.item(7).style.cursor = "context-menu";
    // selectOption.item(7).style.marginTop = "-25px";
    // selectOption.item(7).style.fontWeight = "600";
    // selectOption.item(7).getElementsByClassName('ant-checkbox').item(0).style.display = "none";
    // selectOption.item(9).style.fontSize = "10px";
    // selectOption.item(9).style.justifySelf = "right";
    // selectOption.item(9).style.cursor = "context-menu";
    // selectOption.item(9).style.marginTop = "-25px";
    // selectOption.item(9).style.fontWeight = "600";
    // selectOption.item(9).getElementsByClassName('ant-checkbox').item(0).style.display = "none";
    selectOption.item(13).style.marginBottom = "10px"
    selectOption.item(15).style.marginBottom = "10px"
    selectOption.item(19).style.marginBottom = "10px"
    selectOption.item(21).style.marginBottom = "10px"
  },[])


  const contractOptions = ['견적서', '수의계약서', '표준계약서', '사용인감계', '계약보증금지급각서', '계약보증금납부서', '청렴계약이행', '공사계약특수조건', '계약붙임서류생략서']
  const beginOptions = ['착공계', '예정공정표', '현장대리인계', '재직증명원', '안전관리비 항목별 사용계획', '산업안전보건관리비 사용계획서', '환경관리비 항목별 사용계획', '환경관리비 사용계획서']
  const completionOptions = ['준공계', '준공검사원', '안전관리비 항목별 사용내역', '산업안전보건관리비 사용내역서', '환경관리비 항목별 사용내역', '환경관리비 사용내역서', '사진대장 (2칸)', '사진대장 (4칸)']
  const claimOptions = ['청구서', '입금계좌지정서']

  const [contractCheckedList, setContractCheckedList] = useState();
  const [beginCheckedList, setBeginCheckedList] = useState();
  const [completionCheckedList, setCompletionCheckedList] = useState();
  const [claimCheckedList, setClaimCheckedList] = useState();

  const [contractCheckAll, setContractCheckAll] = useState(false);
  const [beginCheckAll, setBeginCheckAll] = useState(false);
  const [completionCheckAll, setCompletionCheckAll] = useState(false);
  const [claimCheckAll, setClaimCheckAll] = useState(false);

  const contractOnChange = (list) => {
    setContractCheckedList(list);
    setContractCheckAll(list.length === contractOptions.length);
    props.parentContractData(list);
  }
  const beginOnChange = (list) => {
    setBeginCheckedList(list);
    setBeginCheckAll(list.length === beginOptions.length);
    props.parentBeginData(list);
  }
  const completionOnChange = (list) => {
    setCompletionCheckedList(list);
    setCompletionCheckAll(list.length === completionOptions.length);
    props.parentCompletionData(list);
  }
  const claimOnChange = (list) => {
    setClaimCheckedList(list);
    setClaimCheckAll(list.length === claimOptions.length);
    props.parentClaimData(list);
  }

  const contractOnCheckAllChange = (e) => {
    setContractCheckedList(e.target.checked ? contractOptions : []);
    setContractCheckAll(e.target.checked);
    props.parentContractData(e.target.checked ? contractOptions : []);
  }
  const beginOnCheckAllChange = (e) => {
    setBeginCheckedList(e.target.checked ? beginOptions : []);
    setBeginCheckAll(e.target.checked);
    props.parentBeginData(e.target.checked ? beginOptions : []);
  }
  const completionOnCheckAllChange = (e) => {
    setCompletionCheckedList(e.target.checked ? completionOptions : []);
    setCompletionCheckAll(e.target.checked);
    props.parentCompletionData(e.target.checked ? completionOptions : []);
  }
  const claimOnCheckAllChange = (e) => {
    setClaimCheckedList(e.target.checked ? claimOptions : []);
    setClaimCheckAll(e.target.checked);
    props.parentClaimData(e.target.checked ? claimOptions : []);
  }

  return (
    <div>
      <div className="doc-card-wrapper">
        <div className="doc-one-card">
          <Checkbox className="doc-card-title" onChange={contractOnCheckAllChange} checked={contractCheckAll}>계약서류</Checkbox>
          <CheckboxGroup className="doc-card-child" options={contractOptions} value={contractCheckedList} onChange={contractOnChange} />
          <span style={{position: "absolute", fontSize: "10px", fontWeight: "600", width: "90px", left: "200px", top: "280px"}}>(※ 발주처 파일 확인)</span>
          <span style={{position: "absolute", fontSize: "10px", fontWeight: "600", width: "90px", left: "200px", top: "305px"}}>(※ 발주처 파일 확인)</span>
        </div>


        <div className="doc-one-card">
          <Checkbox className="doc-card-title" onChange={beginOnCheckAllChange} checked={beginCheckAll}>착공서류</Checkbox>
          <CheckboxGroup className="doc-card-child" options={beginOptions} value={beginCheckedList} onChange={beginOnChange} />
          <span style={{position: "absolute", fontSize: "10px", fontWeight: "600", width: "120px", left: "470px", top: "245px"}}>(※ 직접 입력이 필요한 서류)</span>
          <span style={{position: "absolute", fontSize: "10px", fontWeight: "600", width: "120px", left: "470px", top: "305px"}}>(※ 직접 입력이 필요한 서류)</span>
        </div>

        <div className="doc-one-card">
          <Checkbox className="doc-card-title" onChange={completionOnCheckAllChange} checked={completionCheckAll}>준공서류</Checkbox>
          <CheckboxGroup className="doc-card-child" options={completionOptions} value={completionCheckedList} onChange={completionOnChange} />
          <span style={{position: "absolute", fontSize: "10px", fontWeight: "600", width: "120px", left: "770px", top: "195px"}}>(※ 직접 입력이 필요한 서류)</span>
          <span style={{position: "absolute", fontSize: "10px", fontWeight: "600", width: "120px", left: "770px", top: "255px"}}>(※ 직접 입력이 필요한 서류)</span>
        </div>

        <div className="doc-one-card">
          <Checkbox className="doc-card-title" onChange={claimOnCheckAllChange} checked={claimCheckAll}>청구서류</Checkbox>
          <CheckboxGroup className="doc-card-child" options={claimOptions} value={claimCheckedList} onChange={claimOnChange} />
        </div>
      </div>
    </div>
  );
}

export default DocPrintModal;