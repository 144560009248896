/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../config/api";
import "./myPage.scss";
import AlertContext from "../../contexts/AlertContext";
import { pages } from "../../constants/PagePaths";

interface Props {}

interface BoardModel {
  content: string;
  created: string;
  email: string;
  file?: string;
  id: number;
  inquiry_type: string;
  is_admin_visible: boolean;
  name: string;
  phone_number: string;
  title: string;
  user_id: number;
  status: string;
}

export default () => {
  const alert = useContext(AlertContext);
  const history = useHistory();
  const windowLocation = window.location.href;
  const paths = windowLocation.split("/");
  let lastpath = paths[paths.length - 1];
  const id = lastpath.split("?")[0];
  const [qna, setQna] = useState<BoardModel>();

  useEffect(() => {
    new Promise(async () => {
      const res = await api.getMyInquiryDetail(Number(id));
      setQna(res.data.data);
    });
  }, []);

  return (
    <section className="content" id="qna_list">
      <h3>문의 게시판</h3>
      <div className="round-box">
        <div className="col-12">
          <div className="board-content" id="qna_board_detail">
            <div className="badge text-bg-primary">{qna?.status}</div>
            <div className="title">{qna?.title}</div>
            <div className="author">
              <div>의뢰분야 : {qna?.inquiry_type}</div>
              <div>
                작성일
                <span className="ms-2">
                  {new Date(qna?.created).format("yyyy-MM-dd HH:mm:ss")}
                </span>
              </div>
            </div>
            <div className="py-4 border-bottom">{qna?.content}</div>
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center mt-5">
                {/* <div className="d-flex gap-2">
                  <a href="#" className="btn btn-lg btn-danger">
                    <span className="material-symbols-outlined me-2">
                      delete
                    </span>
                    삭제
                  </a>
                </div> */}
                <div>
                  <Link
                    to={pages.inquiry}
                    className="btn btn-lg btn-primary-soft"
                  >
                    <span className="material-symbols-outlined me-2">edit</span>
                    문의하기
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
