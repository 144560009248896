export default () => {
  return (
    <section>
      <div className="banner-wrap">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="banner-txt">
              <h2 className="banner-txt">뚜껑설계 가입시 7일 쿠폰 드려요!</h2>
              <div>쿠폰은 회원가입과 동시에 자동 적용됩니다.</div>
            </div>
            <div className="banner-img">
              <img src="/images/ddusul/3d-coupon2.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
