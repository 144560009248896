import React, { useContext } from "react"
import { useHistory } from "react-router-dom";
import { pages } from "../constants/PagePaths";
import AlertContext from "./AlertContext";

export interface DupLoginContextProps {
  checkDupLogin: (method: Function) => undefined | any;
}

const props: DupLoginContextProps = {
  checkDupLogin: async (method: Function) => await method(),
};

export const DupLoginContext = React.createContext(props);

export interface DupLoginContextProviderProps {
  children?: React.ReactNode;
}

export const DupLoginContextProvider = ({
  children,
}: DupLoginContextProviderProps) => {
  const alert = useContext(AlertContext);
  const history = useHistory();
  
  const checkDupLogin = async (method: Function) => {
    try {
      return await method() ?? undefined;
    } catch (err) {
      if (err?.response?.status === 401) {
        alert.showAlert(
          "다른 브라우저에서 로그인 혹은 로그아웃하여\n현재 브라우저에서 로그아웃 합니다.",
          () => {
            history.push(pages.index);
          }
        );
      }
      return err.response;
    }
  };

  return (
    <DupLoginContext.Provider
      value={{checkDupLogin}}
    >
      {children}
    </DupLoginContext.Provider>
  );
};

export default DupLoginContext;