import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { LevelTestResContext } from "../../contexts/LevelTestContext";

export default () => {
  const levelTestRes = useContext(LevelTestResContext);
  const history = useHistory();
  const [userType, setUserType] = useState("major");

  const next = () => {
    levelTestRes.setUserType(userType);
    history.push(pages.leveltest + "/1");
  };

  return (
    <main id="wrap">
      <section className="bg-primary">
        <div className="container content" id="level">
          <div className="form-wrap col-md-7 mx-auto text-center">
            <h2 className="text-dark mb-5">레벨 테스트</h2>
            <div
              className="leveltest-main aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="leveltest-main-item">
                <input
                  type="radio"
                  className="btn-check"
                  name="options"
                  id="option1"
                  checked={userType === "major"}
                  onChange={(e) => setUserType(e.target.value)}
                  value={"major"}
                />
                <label className="btnbox " htmlFor="option1">
                  <img src="/images/ddusul/4-5.png" />
                  <p>전공자 입니다</p>
                </label>
              </div>
              <div className="leveltest-main-item">
                <input
                  type="radio"
                  className="btn-check"
                  name="options"
                  id="option2"
                  value={"nomajor"}
                  checked={userType === "nomajor"}
                  onChange={(e) => setUserType(e.target.value)}
                />
                <label className="btnbox " htmlFor="option2">
                  <img src="/images/ddusul/10-26.png" />
                  <p>전공자가 아닙니다</p>
                </label>
              </div>
            </div>
            <div className="level-btn py-3">
              <a
                onClick={() => {
                  next();
                }}
                className="btn btn-lg btn-primary w-100"
              >
                시작하기
                <span className="material-symbols-outlined ms-2">
                  arrow_right_alt
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
