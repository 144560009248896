export class Auth{
    static authInstance = null;
    username;
    email;
    firstName;
    lastName;


    /**
     * @returns {Auth}
     */
    static getInstance() {
        if (!Auth.authInstance) {
            Auth.authInstance = new Auth();
        }

        return this.authInstance;
    }
}