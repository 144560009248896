import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./config/util";
import { AlertContextProvider } from "./contexts/AlertContext";

//ie11 react
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { LoadingContextProvider } from "./contexts/LoadingContext";
import { DupLoginContextProvider } from "./contexts/DupLoginContext";
import { pages } from "./constants/PagePaths";
import VideoMain from "./pages/video/components/main/VideoMain";
import LectureList from "./pages/video/components/lectureList/LectureList";
import LectureContent from "./pages/video/components/lectureContent/LectureContent";
import VideoLogin from "./pages/video/components/login/VideoLogin";
import VideoSignUp from "./pages/video/components/videoSignUp/VideoSignUp";
import VideoMyPage from "./pages/video/components/videoMyPage/VideoMyPage";
import MyLecture from "./pages/video/components/myLecture/MyLecture";
import Notice from "./pages/video/components/notice/Notice";
import VideoPayment from "./pages/video/components/payment/VideoPayment";
import Video from "./pages/video/components/video/Video";
import VideoFindAccount from "./pages/video/components/findAccount/VideoFindAccount";
import PaymentContainer from "./pages/payment/PaymentContainer";

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <LoadingContextProvider>
      <AlertContextProvider>
        <DupLoginContextProvider>
          <App />
        </DupLoginContextProvider>
      </AlertContextProvider>
    </LoadingContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
