type IEnumTpProp<R> = { [key in keyof typeof PageNames]: R };

export const pages: IEnumTpProp<string> = {
  index: "/",
  login: "/login",
  signup: "/signup",
  worklists: "/worklists",
  costprojects: "/costprojects",
  mypage: "/mypage",
  noticelist: "/noticelist",
  alarmlist: "/alarmlist",
  lectureroom: "/lectureroom",
  statementlist: "/statementlist",
  statementdetail: "/statementdetail",
  inquiry: "/inquiry",
  faq: "/faq",
  findaccount: "/findaccount",
  payment: "/payment",
  withdrawal: "/withdrawal",
  coupon: "/coupon",
  changepassword: "/changepassword",
  doclist: "/doclist",
  newwork: "/newwork",
  notice: "/notice",
  contact: "/contact",
  requestapplication: "/requestapplication",
  inquiryboard: "/inquiryboard",
  userboardlist: "/userboardlist",
  userboarddetail: "/userboarddetail",
  userboardform: "/userboardform",
  video: "/video",
  data: "/data",
  excelX2: "/excelX2",
  excelContract: "/excelContract",
  leveltest: "/levelTest",
  levelComplete: "/levelComplete",
  excel: "/excel",
  paymentComplete: "/paymentComplete",
  oauth2: "/oauth2",
  cert: "/cert",
  mypageqna: "/mypage/qna",
  mypagecontact: "/mypage/contact",
  community: "/community",
  communityAi: "/community_ai",
};

export const videoPages = {
  index: "/video",
  login: "/video/login",
  signup: "/video/signup",
  findaccount: "/video/findaccount",
  mypage: "/video/mypage",
  content: "/video/content",
  curriculum: "/video/curriculum",
  signUp: "/video/signUp",
  myPage: "/video/myPage",
  myLecture: "/video/myLecture",
  notice: "/video/notice",
  payment: "/video/payment",
  changepassword: "/video/changepassword",
  withdrawal: "/video/withdrawal",
  course: "/video/course",
};

enum PageNames {
  index = "index",
  login = "login",
  oauth2 = "oauth2",
  cert = "cert",
  worklists = "worklists",
  newwork = "newwork",
  doclist = "doclist",
  signup = "signup",
  costprojects = "costprojects",
  mypage = "mypage",
  noticelist = "noticelist",
  alarmlist = "alarmlist",
  lectureroom = "lectureroom",
  statementlist = "statementlist",
  statementdetail = "statementdetail",
  inquiry = "inquiry",
  faq = "faq",
  notice = "notice",
  contact = "contact",
  findaccount = "findaccount",
  payment = "payment",
  withdrawal = "withdrawal",
  coupon = "coupon",
  changepassword = "changepassword",
  inquiryboard = "inquiryboard",
  requestapplication = "requestapplication",
  userboardlist = "userboardlist",
  userboarddetail = "userboarddetail",
  userboardform = "userboardform",
  video = "video",
  data = "data",
  excel = "excel",
  excelX2 = "excelX2",
  excelContract = "excelContract",
  leveltest = "leveltest",
  levelComplete = "levelComplete",
  paymentComplete = "paymentComplete",
  mypageqna = "mypageqna",
  mypagecontact = "mypagecontact",
  community = "community",
  communityAi = "communityAi",
}
