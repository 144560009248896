import styles from "./simpleAlert.module.scss";

interface Props {
  title: string;
  text: string;
  onClickCancel: () => void;
  onClickOk: () => void;
  cancelText: string;
  okText: string;
}

export default (props: Props) => {

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      props.onClickOk();
    }
  };

  return (
    <div onKeyPress={handleKeyPress}>
      <div className={styles.wrapper}>
        <div className={styles.alerts}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.content}>{props.text}</div>
          <div className={styles.btn_wrapper}>
            <div style={{marginRight: "10px", backgroundColor: "#bebebe"}} onClick={props.onClickCancel}>{props.cancelText}</div>
            <div onClick={props.onClickOk}>{props.okText}(Enter)</div>
          </div>
        </div>
      </div>
    </div>
  );
};
