/* eslint-disable array-callback-return */
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { util } from "../../../config/util";
import { EstimateService } from "../../../models/EstimateService";
import ProgressBar from "../../progressBar/ProgressBar";

interface ModalDefaultType {
  estimateService: EstimateService;
  onClickToggleModal: () => void;
}

export default (props:ModalDefaultType ) => {
  return (
    <ModalContainer>
      <DetailBox>
        <TitleBox>{props.estimateService.request_contents}</TitleBox>
        <ContentsDetailBox>
          <BlueLine />
          <ContentsDetail>
            <>
              <DetailTitle>용역구분</DetailTitle>
              <DetailContent>{props.estimateService.service_type}</DetailContent>
            </>
          </ContentsDetail>
          <ContentsDetail>
            <>
              <DetailTitle>계약업체</DetailTitle>
              <DetailContent>{props.estimateService.company_name}</DetailContent>
            </>
          </ContentsDetail>
          <ContentsDetail>
            <>
              <DetailTitle>날짜</DetailTitle>
              <DetailContent>{props.estimateService.start_date.toString().substring(0,7)}</DetailContent>
            </>
          </ContentsDetail>
          <ContentsDetail>
            <>
              <DetailTitle>지역</DetailTitle>
              <DetailContent>{props.estimateService.address}</DetailContent>
            </>
          </ContentsDetail>
          <ContentsDetail>
            <>
              <DetailTitle>금액</DetailTitle>
              <DetailContent>{util.addComma(props.estimateService.amount)}원</DetailContent>
            </>
          </ContentsDetail>
          <ContentsDetail>
            <>
              <DetailTitle>진행상태</DetailTitle>
              <DetailContent>
                {<ProgressBar completed={props.estimateService.status} />}
              </DetailContent>
            </>
          </ContentsDetail>
          {/* <ContentsDetail>
            <>
              <DetailTitle>소요기간</DetailTitle>
              <DetailContent>{children.workingDays}일</DetailContent>
            </>
          </ContentsDetail> */}
          {/* <ProcessDetail>
            <>
              <DetailTitle>진행세부내용</DetailTitle>
              <DetailContent>{children.content}</DetailContent>
            </>
          </ProcessDetail> */}
        </ContentsDetailBox>
      </DetailBox>
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailBox = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;
const TitleBox = styled.div`
  width: 100%;
  height: 2em;
  padding: 2px;
  margin-bottom: 0px;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const ContentsDetailBox = styled(TitleBox)`
  height: auto;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  border-bottom: none;
`;

const ContentsDetail = styled.div`
  width: 80%;
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 10px;
  height: 40px;
  line-height: 40px;
`;

const BlueLine = styled.div`
  width: 80%;
  height: 3px;
  background-color: var(--color-primary);
`;

const DetailTitle = styled.div`
  width: 50%;
  height: auto;
  text-align: center;
`;
const DetailContent = styled.div`
  width: 50%;
  height: auto;
  font-weight: lighter;
  white-space: pre-wrap;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProcessDetail = styled(ContentsDetail)`
  height: auto;
`;

