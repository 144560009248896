/* eslint-disable import/no-anonymous-default-export */
import {
  CloseOutlined,
  DeleteOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dialog } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { api } from "../../../config/api";
import styles from "./workListModal.module.scss";
import React from "react";
import AddAgentModal from "../../../pages/newWork/components/AddAgentModal";
import AddCompanyModal from "../../../pages/newWork/components/AddCompanyModal";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DupLoginContext from "../../../contexts/DupLoginContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { set } from "lodash";

interface Props {
  type: number;
  onClickCancel: () => void;
  onClickWrite: (item?: any) => void;
  open: any;
}

export default (props: Props) => {
  const [companyInfo, setCompanyInfo] = useState([]);
  const [deputyInfo, setDeputyInfo] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const loading = useContext(LoadingContext);

  const dupLogin = useContext(DupLoginContext);
  useEffect(() => {
    new Promise(async () => {
      await getDeputyInfo();
      await getCompanyInfo();
    });
  }, []);

  const getCompanyInfo = async () => {
    api
      .getWorkCompanyList({ offset: 0, limit: companyInfo.length })
      .then((result) => {
        const companyInfo = result.data.company_list;
        setCompanyInfo(companyInfo);
      });
  };
  const getDeputyInfo = async () => {
    api
      .getDeputyList({ offset: 0, limit: deputyInfo.length })
      .then((result) => {
        const deputyInfo = result.data.deputy_list;
        console.log(deputyInfo);
        setDeputyInfo(deputyInfo);
      });
  };

  const handleAgentDelete = (event: any, id: number) => {
    event.preventDefault();
    event.stopPropagation();

    if (window.confirm("삭제 하시겠습니까?")) {
      api.deleteDeputy(id).then((res) => {
        getDeputyInfo();
      });
    }
  };

  const handleCompanyDelete = (event: any, id: number) => {
    event.preventDefault();
    event.stopPropagation();

    if (window.confirm("삭제 하시겠습니까?")) {
      api.deleteWorkCompany(id).then((res) => {
        getCompanyInfo();
      });
    }
  };

  const handleOk3 = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);
    var values: any = {};

    // FormData의 키와 값을 반복하여 values 객체에 저장
    formData.forEach(function (value, key) {
      values[key] = value;
    });
    loading.showLoading();
    await dupLogin.checkDupLogin(() =>
      selectedCompany
        ? api
            .putWorkCompany(values, selectedCompany.company_id)
            .then(async () => {
              await dupLogin.checkDupLogin(() =>
                api
                  .getWorkCompanyList({ offset: 0, limit: companyInfo.length })
                  .then((result) => {
                    const companyInfo = result.data.company_list;
                    setCompanyInfo(companyInfo);
                  })
                  .catch((error) => {
                    console.error("에러발생 : ", error);
                  })
                  .finally(() => {
                    // clear form data
                    event.target.reset();
                    loading.closeLoading();
                  })
              );
            })
            .catch((error) => {
              loading.closeLoading();
              throw error;
            })
        : api
            .postWorkCompany(values)
            .then(async () => {
              await dupLogin.checkDupLogin(() =>
                api
                  .getWorkCompanyList({ offset: 0, limit: companyInfo.length })
                  .then((result) => {
                    const companyInfo = result.data.company_list;
                    setCompanyInfo(companyInfo);
                  })
                  .catch((error) => {
                    console.error("에러발생 : ", error);
                  })
                  .finally(() => {
                    // clear form data
                    event.target.reset();
                    loading.closeLoading();
                  })
              );
            })
            .catch((error) => {
              loading.closeLoading();
              throw error;
            })
    );
  };

  //현장대리인 정보 신규 등록에서 확인 클릭 시 동작
  const handleOk4 = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);
    var values: any = {};

    // FormData의 키와 값을 반복하여 values 객체에 저장
    formData.forEach(function (value, key) {
      values[key] = value;
    });
    loading.showLoading();
    if (values.deputyRegistDate === undefined)
      values.deputyRegistDate = moment(new Date());
    dupLogin.checkDupLogin(() => {
      selectedAgent
        ? api
            .putDeputy(values, selectedAgent.deputy_id)
            .then((result) => {
              dupLogin.checkDupLogin(() =>
                api
                  .getDeputyList({ offset: 0, limit: 100 })
                  .then((result) => {
                    const deputyInfo = result.data.deputy_list;
                    setDeputyInfo(deputyInfo);
                  })
                  .catch((error) => {
                    console.error("에러발생 : ", error);
                  })
                  .finally(() => {
                    event.target.reset();
                    loading.closeLoading();
                  })
              );
            })
            .catch((error) => {
              loading.closeLoading();
              throw error;
            })
        : api
            .postDeputy(values)
            .then((result) => {
              dupLogin.checkDupLogin(() =>
                api
                  .getDeputyList({ offset: 0, limit: 100 })
                  .then((result) => {
                    const deputyInfo = result.data.deputy_list;
                    setDeputyInfo(deputyInfo);
                  })
                  .catch((error) => {
                    console.error("에러발생 : ", error);
                  })
                  .finally(() => {
                    event.target.reset();
                    loading.closeLoading();
                  })
              );
            })
            .catch((error) => {
              loading.closeLoading();
              throw error;
            });
    });
  };

  return (
    <React.Fragment>
      <div
        className="modal fade"
        id="AgentModal"
        aria-labelledby="AgentLabel"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="AgentLabel">
                {props.type == 1 ? "현장대리인 목록" : "업체정보 목록"}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="agent-list">
                {props.type == 1
                  ? deputyInfo?.map((deputy: any, i: number) => (
                      <div
                        className="agent-item"
                        onClick={() => {
                          setSelectedAgent(deputy);
                        }}
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#addAgent"
                        >
                          <div className="user">
                            <span className="material-symbols-outlined">
                              person_pin_circle
                            </span>
                            <div className="profile">
                              <div className="fw-bold text-dark">
                                {deputy.deputyName}
                              </div>
                              <div className="date">
                                {new Date(deputy.created).format("yyyy.MM.dd")}
                              </div>
                            </div>
                          </div>
                        </a>
                        <div className="delete">
                          <button
                            type="button"
                            className="btn text-danger"
                            onClick={(e) =>
                              handleAgentDelete(e, deputy.deputy_id)
                            }
                          >
                            <span className="material-symbols-outlined">
                              delete
                            </span>
                          </button>
                        </div>
                      </div>
                    ))
                  : companyInfo?.map((company: any, i: number) => (
                      <div
                        className="agent-item"
                        onClick={() => {
                          setSelectedCompany(company);
                        }}
                      >
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#addCompany"
                        >
                          <div className="user">
                            <span className="material-symbols-outlined">
                              person_pin_circle
                            </span>
                            <div className="profile">
                              <div className="fw-bold text-dark">
                                {company.companyName}
                              </div>
                              <div className="date">
                                {new Date(company.created).format("yyyy.MM.dd")}
                              </div>
                            </div>
                          </div>
                        </a>
                        <div className="delete">
                          <button
                            type="button"
                            className="btn text-danger"
                            onClick={(event) =>
                              handleCompanyDelete(event, company.company_id)
                            }
                          >
                            <span className="material-symbols-outlined">
                              delete
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
              </div>
              <button
                type="button"
                className="btn btn-primary-soft w-100 btn-lg mb-3"
                data-bs-target={props.type == 1 ? "#addAgent" : "#addCompany"}
                data-bs-toggle="modal"
                onClick={() => {
                  setSelectedAgent(null);
                  setSelectedCompany(null);
                }}
              >
                <span className="material-symbols-outlined me-2">add</span>
                추가하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddCompanyModal handleOk3={handleOk3} company={selectedCompany} />
      <AddAgentModal handleOk4={handleOk4} agent={selectedAgent} />
    </React.Fragment>
  );
};
