import "./index.scss";
import { useState } from "react";

function CompanyInputInfo(props) {
  const worklist = props.worklist;

  //수정, 저장 버튼에 대한 동작.
  const [input, setinput] = useState(true);

  const changeInput = (e) => {
    e.preventDefault();
    if (input === false) {
      setinput(!input);
    }
  };

  const handleSubmitAsync = async (e) => {
    let data = {};
    for (let index = 0; index < e.target.length; index++) {
      const element = e.target[index];
      if (element.type === "text") {
        data[element.name] = element.value;
      }
    }
    data = {
      ...props.worklist,
      ...data,
    };

    e.preventDefault();
    if (input === true) {
      setinput(!input);
    }

    await props.putWorkListAsync(data);
  };

  return worklist ? (
    <div>
      <div className="doc-container-box">               
        <form name="infoForm" onSubmit={handleSubmitAsync}>
          <div className="doc-header">
            <h1>업체 정보</h1>
          </div>
          <div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                업체명
              </div>
              <input
                defaultValue={worklist?.companyName}
                className="doc-submit-input"
                placeholder="ex) ㅇㅇ건설"
                disabled={!input}
                name="companyName"
                maxLength="20"
              />
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                대표자명
              </div>
              <input
                defaultValue={worklist?.representativeName}
                className="doc-submit-input"
                placeholder="ex) 김ㅇㅇ"
                disabled={!input}
                name="representativeName"
                maxLength="10"
              />
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                사업자증록번호
              </div>
              <input
                defaultValue={worklist?.corporateRegistNum}
                className="doc-submit-input"
                placeholder="ex) 000-00-00000"
                disabled={!input}
                name="corporateRegistNum"
                maxLength="12"
              />
            </div>
            <div className="info_sign">
              ( - )를 포함하여 입력해주세요.
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                법인번호
              </div>
              <input
                defaultValue={worklist?.corporateNum}
                className="doc-submit-input"
                placeholder="ex) 000000-0000000"
                disabled={!input}
                name="corporateNum"
                maxLength="14"
              />
            </div>
            <div className="info_sign">
              ( - )를 포함하여 입력해주세요.
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                업체주소
              </div>
              <input
                defaultValue={worklist?.businessAddress}
                className="doc-submit-input"
                placeholder="ex) 서울시 마포구 공덕동 일원"
                disabled={!input}
                name="businessAddress"
                maxLength="100"
              />
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                업종
              </div>
              <input
                defaultValue={worklist?.typeOfBusiness}
                className="doc-submit-input"
                placeholder="ex) 실내건축공사 / ex) 토공사 / ex) 조경식재공사"
                disabled={!input}
                name="typeOfBusiness"
                maxLength="10"
              />
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                전화번호
              </div>
              <input
                defaultValue={worklist?.phoneNum}
                className="doc-submit-input"
                placeholder="ex) 000-0000-0000"
                disabled={!input}
                name="phoneNum"
                maxLength="13"
              />
            </div>
            <div className="info_sign">
              ( - )를 포함하여 입력해주세요.
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                팩스번호
              </div>
              <input
                defaultValue={worklist?.faxNum}
                className="doc-submit-input"
                placeholder="ex) 000-0000-0000"
                disabled={!input}
                name="faxNum"
                maxLength="13"
              />
            </div>
            <div className="info_sign">
              ( - )를 포함하여 입력해주세요.
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                작성자
              </div>
              <input
                defaultValue={worklist?.writer}
                className="doc-submit-input"
                placeholder="ex) 한ㅇㅇ"
                disabled={!input}
                name="writer"
                maxLength="10"
              />
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                은행명
              </div>
              <input
                defaultValue={worklist?.bankName}
                className="doc-submit-input"
                placeholder="ex) ㅇㅇ은행"
                disabled={!input}
                name="bankName"
                maxLength="10"
              />
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                계좌번호
              </div>
              <input
                defaultValue={worklist?.accountNum}
                className="doc-submit-input"
                placeholder="ex) 000-0000-0000"
                disabled={!input}
                name="accountNum"
                maxLength="20"
              />
            </div>
            <div className="info_sign">
              ( - )를 포함하여 입력해주세요.
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                예금주
              </div>
              <input
                defaultValue={worklist?.accountHolder}
                className="doc-submit-input"
                placeholder="ex) (주)ㅇㅇ건설 / ex) 김ㅇㅇ"
                disabled={!input}
                name="accountHolder"
                maxLength="10"
              />
            </div>

            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                예금종류
              </div>
              <input
                defaultValue={worklist?.typeOfDeposit}
                className="doc-submit-input"
                placeholder="ex) 보통예금"
                disabled={!input}
                name="typeOfDeposit"
                maxLength="10"
              />
            </div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                점포명
              </div>
              <input
                defaultValue={worklist?.storeName}
                className="doc-submit-input"
                placeholder="ex) ㅇㅇ지부"
                disabled={!input}
                name="storeName"
                maxLength="10"
              />
            </div>
          </div>
          
          <div className="button_container">
            <button className={`doc_button_modi ${!input ? '' : 'btn_hidden'}`} onClick={changeInput}>
              <span style={{ fontSize: "15px", color: "white" }}>수정</span>
            </button>
            <button className={`doc_submit ${input ? '' : 'btn_hidden'}`} htmlType="submit">
              <span style={{ fontSize: "15px", color: "white" }}>저장</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default CompanyInputInfo;
