import styles from "./excel.module.scss";
import { Link } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import React, { useRef, useState } from "react";
import { Checkbox, Radio } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ExcelX2File, ExcelX2FileSheet } from "../../models/excelModel/excelX2";
import CategorySelector from "../../components/selector/categorySelector/CategorySelector";
import { CategoryItem } from "../../models/CategoryItem";
import { ExcelItem } from "../../models/excelModel/excelItem";

interface Props {
  fileInfos: ExcelX2File[];
  fileCountLimit: number;
  originOption: string;
  divideOption: string;
  originRowOption: boolean;
  isHiddenSheetShow: boolean;
  macroType: ExcelItem[];
  selectedCategoryIndex: number;
  handleDragOver: (e: React.DragEvent) => void;
  handleDrop: (e: React.DragEvent) => void;
  handleChangeFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (index: number) => (e: React.MouseEvent) => void;
  handleOnOriginChange: (value: string) => void;
  handleOnDivideChange: (value: string) => void;
  handleOnOriginRowChange: (value: boolean) => void;
  handleEntireCheck: (index: number) => void;
  handleSheetClick: (fileIndex: number, sheet: ExcelX2FileSheet) => void;
  handleExecute: () => void;
  handleHiddenSheetClick: () => void;
  onClickCategory: (index: number) => void;
}

export default (props: Props) => {
  const [dragOver, setDragOver] = useState(false);
  const dragCounter = useRef(0);

  const divStyle = dragOver ? { display: "block" } : { display: "none" };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (dragCounter.current > 0) {
      setDragOver(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOver(false);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setDragOver(false);
    props.handleDrop(e);
  };

  const handleHelpClick = (item: ExcelItem) => {
    window.open(
      process.env.REACT_APP_URL + "lectureroom/0/" + item?.lectureId,
      "",
      "width=#, height=#"
    );
  };
  return (
    <div className={styles.excelWrapper}>
      <div
        className={styles.workArea}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <div className={styles.dropArea} style={{ ...divStyle }}>
          파일을 놓으세요.
        </div>
        <div className={styles.categorySelector}>
          <CategorySelector
            categories={props.macroType}
            selectedCategoryIndex={props.selectedCategoryIndex}
            onClickCategory={props.onClickCategory}
          />
        </div>
        {props.fileInfos.length > 0 ? (
          <div className={styles.uploadedArea}>
            <div className={styles.leftArea}>
              <div className={styles.explain}>실행할 시트를 선택해주세요.</div>
              <div className={styles.excelFileWrapper}>
                {props.fileInfos.map((file, fileIndex) => {
                  return (
                    <div key={fileIndex} className={styles.excelCard}>
                      <div className={styles.excelName}>
                        <Checkbox
                          checked={file.isEntireChecked}
                          onClick={() => props.handleEntireCheck(fileIndex)}
                          className={styles.checkbox}
                          name="excel"
                        />
                        <span className={styles.excelTitle} title={file.name}>
                          {file.name}
                        </span>
                        <div
                          onClick={props.handleDelete(fileIndex)}
                          className={styles.delete}
                        >
                          <Close />
                        </div>
                      </div>
                      <div className={styles.sheetNames}>
                        {file?.sheets
                          ?.filter((sheet) => {
                            return props.isHiddenSheetShow
                              ? true
                              : !sheet.isHidden;
                          })
                          ?.map((sheet) => (
                            <div
                              key={sheet.sheetIndex}
                              onClick={() =>
                                props.handleSheetClick(fileIndex, sheet)
                              }
                              title={sheet.name}
                              className={styles.sheetRow}
                            >
                              <Checkbox
                                checked={sheet.checked}
                                name="sheetName"
                              />
                              <span
                                className={
                                  styles.sheetName +
                                  " " +
                                  (sheet.isHidden ? styles.hiddenSheet : "")
                                }
                              >
                                {sheet.name}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.pannel}>
              <div className={styles.title}>
                엑셀 {props.macroType[props.selectedCategoryIndex].title}
              </div>
              {props.fileInfos.length < props.fileCountLimit && (
                <div className={styles.addFileWrapper}>
                  <label htmlFor="selectFile2" className={styles.addFile}>
                    파일 더 추가하기({props.fileInfos?.length}/
                    {props.fileCountLimit})
                    <input
                      maxLength={100}
                      id="selectFile2"
                      style={{ display: "none" }}
                      type="file"
                      accept=".xlsx, .xls"
                      name="files"
                      multiple
                      onChange={props.handleChangeFiles}
                    />
                  </label>
                </div>
              )}
              <div className={styles.options}>
                {[0, 1, 2, 7].includes(props.selectedCategoryIndex) && (
                  <div className={styles.option}>
                    <span className={styles.optionTitle}>
                      당초 흑서/적서 여부
                      <div className={styles.tooltip_container}>
                        <QuestionCircleOutlined />
                        <div className={styles.tooltip_text}>
                          엑셀을 나눌 때 윗줄을 검정색으로 하고
                          <br />
                          아랫줄을 적색으로 할 지,
                          <br />
                          윗줄을 적색으로 하고
                          <br />
                          아랫줄을 검정색으로 할 지 선택하는 옵션입니다.
                        </div>
                      </div>
                    </span>
                    <div className={styles.optionContent}>
                      <div
                        onClick={() => props.handleOnOriginChange("black")}
                        className={styles.optionItem}
                      >
                        <Radio
                          checked={props.originOption === "black"}
                          name="original"
                        ></Radio>
                        <span>흑서</span>
                      </div>
                      <div
                        onClick={() => props.handleOnOriginChange("red")}
                        className={styles.optionItem}
                      >
                        <Radio
                          checked={props.originOption === "red"}
                          name="original"
                        ></Radio>
                        <span style={{ color: "red" }}>적서</span>
                      </div>
                    </div>
                  </div>
                )}
                {[0, 5, 6, 7].includes(props.selectedCategoryIndex) && (
                  <div className={styles.option}>
                    <span className={styles.optionTitle}>
                      행고정 인식 여부
                      <div className={styles.tooltip_container}>
                        <QuestionCircleOutlined />
                        <div className={styles.tooltip_text}>
                          시트에 행고정이 되어있다면 그 이후부터 실행할지,
                          <br />
                          (공종, 품명, 규격등이 두줄이 되지 않기 위해)
                          <br />
                          시트 전체를 실행할지 선택하는 옵션입니다.
                        </div>
                      </div>
                    </span>
                    <div className={styles.optionContent}>
                      <div
                        className={styles.optionItem}
                        onClick={() => props.handleOnDivideChange("fixed")}
                      >
                        <Radio
                          checked={props.divideOption === "fixed"}
                          name="fixed"
                        ></Radio>
                        <span>행고정 이후부터 실행</span>
                      </div>
                      <div
                        onClick={() => props.handleOnDivideChange("all")}
                        className={styles.optionItem}
                      >
                        <Radio
                          checked={props.divideOption === "all"}
                          name="fixed"
                        ></Radio>
                        <span>시트 전체 실행</span>
                      </div>
                    </div>
                  </div>
                )}
                {[0, 1, 2, 5].includes(props.selectedCategoryIndex) && (
                  <div className={styles.option}>
                    <span className={styles.optionTitle}>
                      변경행 위치 설정
                      <div className={styles.tooltip_container}>
                        <QuestionCircleOutlined />
                        <div className={styles.tooltip_text}>
                          수식이 연결되어 있는 행 위치를
                          <br />
                          아래쪽으로 할지, 위쪽으로 할지
                          <br />
                          선택하는 옵션입니다.
                        </div>
                      </div>
                    </span>
                    <div className={styles.optionContent}>
                      <div
                        className={styles.optionItem}
                        onClick={() => props.handleOnOriginRowChange(true)}
                      >
                        <Radio
                          checked={props.originRowOption}
                          name="originRow"
                        ></Radio>
                        <span>변경행 하단으로</span>
                      </div>
                      <div
                        onClick={() => props.handleOnOriginRowChange(false)}
                        className={styles.optionItem}
                      >
                        <Radio
                          checked={!props.originRowOption}
                          name="originRow"
                        ></Radio>
                        <span>변경행 상단으로</span>
                      </div>
                    </div>
                  </div>
                )}
                {[6].includes(props.selectedCategoryIndex) && (
                  <div className={styles.option}>
                    <span className={styles.optionTitle}>
                      삭제행 위치 설정
                      <div className={styles.tooltip_container}>
                        <QuestionCircleOutlined />
                        <div className={styles.tooltip_text}>
                          두줄 중 하나를 삭제할 때 삭제할 행 위치를
                          <br />
                          아래쪽으로 할지, 위쪽으로 할지
                          <br />
                          선택하는 옵션입니다.
                        </div>
                      </div>
                    </span>
                    <div className={styles.optionContent}>
                      <div
                        className={styles.optionItem}
                        onClick={() => props.handleOnOriginRowChange(true)}
                      >
                        <Radio
                          checked={props.originRowOption}
                          name="originRow"
                        ></Radio>
                        <span>위쪽 행 삭제</span>
                      </div>
                      <div
                        onClick={() => props.handleOnOriginRowChange(false)}
                        className={styles.optionItem}
                      >
                        <Radio
                          checked={!props.originRowOption}
                          name="originRow"
                        ></Radio>
                        <span>아래쪽 행 삭제</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.option}>
                  <span className={styles.optionTitle}>숨김 시트 표시</span>
                  <div className={styles.optionContent}>
                    <div
                      className={styles.optionItem}
                      onClick={props.handleHiddenSheetClick}
                    >
                      <Checkbox
                        checked={props.isHiddenSheetShow}
                        name="fixed"
                      />
                      <span>숨겨진 시트 표시하기</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.executeWrapper}>
                <button
                  onClick={props.handleExecute}
                  className={styles.execute}
                >
                  실행하기
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.uploadArea}>
            <div className={styles.titleWrapper}>
              <div>
                <div className={styles.title}>
                  엑셀 {props.macroType[props.selectedCategoryIndex].title}
                  {props.macroType[props.selectedCategoryIndex].lectureId && (<a
                    className="btn text-gray-400"
                    onClick={() =>
                      handleHelpClick(
                        props.macroType[props.selectedCategoryIndex]
                      )
                    }
                  >
                    <span className="material-symbols-outlined">help</span>
                  </a>)}
                </div>
              </div>
              <div className={styles.subtitle}>
                {props.macroType[props.selectedCategoryIndex].description}
              </div>
            </div>
            <label htmlFor="selectFile" className={styles.selectFile}>
              엑셀 파일 선택
              <input
                maxLength={100}
                id="selectFile"
                style={{ display: "none" }}
                type="file"
                accept=".xlsx"
                name="files"
                multiple
                onChange={props.handleChangeFiles}
              />
            </label>
            <div className={styles.dragInfo}>
              또는 엑셀파일을 여기에 두기(최대 {props.fileCountLimit}개)
              <div className={styles.dragText}>
                xlsx 확장자 파일을 사용해주세요. <br />
                xls, xlsm 등 다른 확장자는 엑셀을 열고 [파일-다른이름으로
                저장-xlsx로 저장] 후 사용
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
