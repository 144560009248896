import React from "react";

interface Props {
  location?: any;
}

const notIncludePaths = [
  "documents/",
]
class ScrollToTop extends React.Component<Props> {
  componentDidUpdate = () => {
  }

  getSnapshotBeforeUpdate(prevProps: any) {
    const { location = {} } = prevProps;

    // 컴포넌트 업데이트 시 페이지 이동인지 확인
    if (this.props.location?.pathname !== location?.pathname && !location.pathname.includes(notIncludePaths)) {
      window.scrollTo(0, 0);
    }

    return null;
  }
  
  render() {
    return this.props.children;
  }
}

export default ScrollToTop;