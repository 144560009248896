import { Dialog } from "@material-ui/core";
import React from "react";
import DateCalculationService from "../../config/dateCalculation";
import styles from "./simpleDatePicker.module.scss";
import _ from "lodash";
import AlertContext from "../../contexts/AlertContext";

interface appProps {
  onClose: (result: Date) => void;
  open: boolean;
  selectedDate: Date;
  isPreventSelectPastDate?: boolean;
}
interface appState {
  calendarDate: Date;
  type: string;
  selectedDate: Date;
}
export default class SimpleDatePicker extends React.Component<
  appProps,
  appState
> {
  dateCalculation = new DateCalculationService();
  dateArray: Array<Array<any>> = [];
  onClose: (result: Date) => void;
  constructor(props: any) {
    super(props);
    this.onClose = this.props.onClose.bind(this);
    this.state = {
      calendarDate: _.clone(this.props.selectedDate),
      type: "date",
      selectedDate: this.props.selectedDate,
    };
  }
  changeMode = (kind: string) => {
    this.setState({ type: kind });
  };
  dayDiffer(d: any) {
    if (d.monthDifferent !== 0) {
      return true;
    }
    return false;
  }
  monthMove(index: number) {
    const prevCalendarDate = this.state.calendarDate;
    prevCalendarDate.setMonth(prevCalendarDate.getMonth() + index);
    this.setState({
      calendarDate: prevCalendarDate,
    });
  }
  yearMove(index: number) {
    const prevCalendarDate = this.state.calendarDate;
    prevCalendarDate.setFullYear(prevCalendarDate.getFullYear() + index);
    this.setState({
      calendarDate: prevCalendarDate,
    });
  }
  getYearList() {
    const y = this.state.calendarDate.getFullYear();
    const yFirst = Math.floor(y / 10) * 10;
    const ys = [];
    for (const i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
      ys.push(yFirst + i);
    }
    return ys;
  }
  setMonth(month: number) {
    const prevCalendarDate = this.state.calendarDate;
    prevCalendarDate.setMonth(month - 1);
    this.setState({
      calendarDate: prevCalendarDate,
      type: "date",
    });
  }
  setYear(year: number) {
    const prevCalendarDate = this.state.calendarDate;
    prevCalendarDate.setFullYear(year);
    this.setState({
      calendarDate: prevCalendarDate,
      type: "month",
    });
  }
  render() {
    const calendarDate = this.state.calendarDate;
    const dateArray = this.dateCalculation.getTableData(calendarDate);
    const type = this.state.type;
    const selectedDate = this.state.selectedDate;
    return (
      <Dialog disableEnforceFocus open={this.props.open} onClose={() => this.onClose(undefined!)}>
        <div style={{ width: "300px", height: "300px", padding: "24px" }}>
          <div className={styles.datePickerWrapper}>
            {type === "date" ? (
              <React.Fragment>
                <div className={styles["year-month-container"]}>
                  <div onClick={() => this.changeMode("year")}>
                    {calendarDate.format("yyyy-MM")}
                  </div>
                  <div>
                    <span
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        this.monthMove(-1);
                      }}
                    >
                      &lt;
                    </span>
                    <span
                      onClick={() => {
                        this.monthMove(1);
                      }}
                    >
                      &gt;
                    </span>
                  </div>
                </div>
                {dateArray.map((week, i) => (
                  <div className={styles.week} key={i}>
                    {week.map((day, di) => (
                      <div
                        key={di}
                        className={`${styles.day} ${
                          this.dayDiffer(day) ? styles["day-differ"] : ""
                        } ${this.props.isPreventSelectPastDate && day.isPast ? styles["past"]: ""}`}
                      >
                        <div
                          className={
                            day.date.format("yyyy-MM-dd") ===
                            selectedDate.format("yyyy-MM-dd")
                              ? styles.select
                              : ""
                          }
                          onClick={() => {
                            if(this.props.isPreventSelectPastDate && day.isPast) {
                              this.context.showAlert("오늘 이전의 날짜는 선택할 수 없습니다.");
                              return;
                            }
                            this.onClose(day.date)
                          }}
                        >
                          {day.date.format("dd")}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ) : type === "year" ? (
              <React.Fragment>
                <div className={styles["year-month-container"]}>
                  <div>&nbsp;</div>
                  <div>
                    <span
                      style={{ marginRight: "10px" }}
                      onClick={() => this.yearMove(-10)}
                    >
                      &lt;
                    </span>
                    <span onClick={() => this.yearMove(10)}>&gt;</span>
                  </div>
                </div>
                <div className={styles.years}>
                  {this.getYearList().map((y, i) => (
                    <div
                      key={i}
                      className={styles.year}
                      onClick={() => this.setYear(y)}
                    >
                      {y}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={styles["year-month-container"]}>
                  <div>&nbsp;</div>
                  <div>
                    <span
                      style={{ marginRight: "10px" }}
                      onClick={() => this.yearMove(-1)}
                    >
                      &lt;
                    </span>
                    <span onClick={() => this.yearMove(1)}>&gt;</span>
                  </div>
                </div>
                <div className={styles.years}>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(1)}
                  >
                    01월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(2)}
                  >
                    02월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(3)}
                  >
                    03월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(4)}
                  >
                    04월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(5)}
                  >
                    05월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(6)}
                  >
                    06월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(7)}
                  >
                    07월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(8)}
                  >
                    08월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(9)}
                  >
                    09월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(10)}
                  >
                    10월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(11)}
                  >
                    11월
                  </div>
                  <div
                    className={styles.month}
                    onClick={() => this.setMonth(12)}
                  >
                    12월
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}

SimpleDatePicker.contextType = AlertContext;