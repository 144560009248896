import { Dialog } from "@material-ui/core";
import React from "react";
import styles from "./createProject.module.scss";

interface appProps {
  onClose: (
    title?: string,
    estimate?: boolean,
    tempRow?: boolean,
    copy?: boolean
  ) => void;
  estimate: boolean;
  copy?: boolean;
  open: boolean;
  tempRow: boolean;
  isMoveWarning?: boolean;
  defaultValue?: string;
}
interface appState {
  title: string;
}

export default class CreateProjectModal extends React.Component<
  appProps,
  appState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      title: props.defaultValue || "",
    };
  }

  private clearTitle() {
    this.setState({
      title: this.props.defaultValue || "",
    });
  }

  render() {
    return (
      <div
        className="modal fade"
        id="newprojectModal"
        tabIndex={-1}
        aria-labelledby="newprojectLabel"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="newprojectLabel">
                프로젝트 추가
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-content">
                <div className="input-item">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="프로젝트 이름을 입력해 주세요"
                    aria-label="project-name"
                    aria-describedby="basic-addon1"
                    data-bs-keyboard="true"
                    value={this.state.title}
                    onChange={(event) =>
                      this.setState({ title: event.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-footer-wrap">
                <button
                  type="button"
                  className="btn btn-light btn-lg flex-1"
                  data-bs-dismiss="modal"
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-lg flex-2"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    this.props.onClose(
                      this.state.title?.trim(),
                      this.props.copy,
                      this.props.estimate,
                      this.props.tempRow
                    );
                    this.clearTitle();
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
