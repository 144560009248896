/* eslint-disable import/no-anonymous-default-export */
import { Close } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../../config/api";
import AlertContext from "../../../contexts/AlertContext";
import {
  SurveyQuestionModel,
  SurveySectionModel,
} from "../../../models/requestApplication/RequestApplicationModel";
import SimpleDatePicker from "../../simpleDatePicker/SimpleDatePicker";
import styles from "./surveyFormItem.module.scss";

interface Props {
  question: SurveyQuestionModel;
  defaultValue?: string | string[];
  defaultFileValue?: File[];
  onChangeOption: (
    option: string | string[],
    child?: SurveySectionModel
  ) => void;
  onChangeFile: (files: File[]) => void;
}

export default (props: Props) => {
  const { question } = props;
  const [etcInputText, setEtcInputText] = useState("");
  const [isEtcInputChecked, setIsEtcInputChecked] = useState(false);
  const [isEtcRadioSelected, setIsEtcRadioSelected] = useState(false);
  const [checkedOption, setCheckedOption] = useState<string[]>([]);
  const [etcCheckedInput, setEtcCheckedInput] = useState<string>();
  const [etcRadioInput, setEtcRadioInput] = useState<string>();
  const [isFormSetup, setIsFormSetup] = useState(false);
  const textareaRef = useRef(null);
  const megaByte = 1000 * 1000;
  const fileSizeLimit = 100; //10MB
  const fileCountLimit = 5;
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [applicationFiles, setApplicationFiles] = useState<File[]>([]);
  const alertContext = useContext(AlertContext);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>();
  const location = useLocation();
  const type = location.pathname.split("/")[2];

  useEffect(() => {
    if (question.type === "checkbox") {
      const titles = question.options
        ?.filter((option) => {
          return props.defaultValue?.includes(option.title);
        })
        .map((op) => op.title);
      setCheckedOption(titles ?? []);

      if (props.defaultValue) {
        const etcValue = (props.defaultValue as string[])?.filter(
          (value) =>
            !question.options?.map((option) => option.title)?.includes(value)
        );
        if (etcValue.length > 0) {
          setEtcCheckedInput(etcValue[0]);
          setEtcInputText(etcValue[0]);
          setIsEtcInputChecked(true);
        }
      }
    } else if (question.type === "radio") {
      if (props.defaultValue) {
        const etcValue = question.options?.find(
          (option) => option.title === props.defaultValue
        );
        if (etcValue == undefined) {
          setEtcRadioInput(props.defaultValue as string);
          setIsEtcRadioSelected(true);
        }
      }
    } else if (question.type === "file") {
      if (props.defaultFileValue) {
        setApplicationFiles(props.defaultFileValue ?? []);
        // defaultFileValue를 통해 fileNames를 설정해줘야함
        setFileNames(props.defaultFileValue?.map((file) => file.name) ?? []);
      }
    } else if (question.type === "date") {
      // 기존데이터 없을 때 현재 날짜에서 일주일 더한 날짜로 초기화.
      const date = new Date(
        props.defaultValue
          ? (props.defaultValue as string)
          : new Date().getTime() + 7 * 24 * 60 * 60 * 1000
      );
      setCurrentDate(date);
      props.onChangeOption(date?.format("yyyy-MM-dd"));
    }

    setIsFormSetup(true);
  }, []);

  const handleChangeFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      if (e.target.files?.length === 0) return;
      const files = applicationFiles;
      const filenames: string[] = fileNames;

      let lengthSum = 0;
      for (let i = 0; i < (e.target.files.length || 0); i++) {
        let file = e.target.files[i];
        lengthSum += file.size;
        if (
          lengthSum < fileSizeLimit * megaByte &&
          fileNames.length < fileCountLimit
        ) {
          files.push(file);
          filenames.push(file.name);
        } else {
          alertContext.showAlert(
            `파일은 최대 ${fileCountLimit}개, 합계 ${fileSizeLimit}MB를 넘을 수 없습니다.`
          );
          break;
        }
      }
      setApplicationFiles([...files]);
      setFileNames([...filenames]);
      props.onChangeFile(files);
    }
  };

  const handleDeleteFile = (index: number) => {
    const newFiles = [...applicationFiles];
    const newFileNames = [...fileNames];
    newFiles.splice(index, 1);
    newFileNames.splice(index, 1);
    setApplicationFiles(newFiles);
    setFileNames(newFileNames);
    props.onChangeFile(newFiles);
  };

  const handleTextareaHeightResize = () => {
    if (textareaRef.current) {
      (textareaRef.current as any).style.height = "auto";
      (textareaRef.current as any).style.height =
        (textareaRef.current as any).scrollHeight + "px";
    }
  };

  return (
    <React.Fragment>
      {isDateOpen && currentDate && (
        <SimpleDatePicker
          onClose={(date) => {
            setIsDateOpen(false);
            if (date) {
              setCurrentDate(date);
              props.onChangeOption(date?.format("yyyy-MM-dd"));
            }
          }}
          open={isDateOpen}
          selectedDate={currentDate}
          isPreventSelectPastDate={true}
        ></SimpleDatePicker>
      )}
      <div
        className={question.size == "half" ? "col-md-6 pb-4" : "col-md-12 pb-4"}
      >
        {question.isRequire ? (
          <label className="form-label">
            {question.title}
            <span className="text-danger ms-2">*</span>
          </label>
        ) : (
          <div className="form-label">{question.title}</div>
        )}
        {question.description ? (
          <p className="body2">{question.description}</p>
        ) : (
          ""
        )}
        {question.captions ? (
          <p className="caption1">
            {question.captions.map((caption, index) => {
              if (index == 0) {
                return <>{caption}</>;
              }
              return (
                <>
                  <br />
                  {caption}
                </>
              );
            })}
          </p>
        ) : (
          ""
        )}
        {question.type === "shortText" && (
          <React.Fragment>
            <input
              name={question.title}
              className="form-control"
              required={question.isRequire}
              defaultValue={props.defaultValue}
              type={question.inputType}
              onChange={(e) => props.onChangeOption(e.target.value)}
              placeholder={question.placeholder ?? "내 답변"}
            />
          </React.Fragment>
        )}
        {question.type === "longText" && (
          <React.Fragment>
            <textarea
              name={question.title}
              ref={textareaRef}
              className="form-control"
              required={question.isRequire}
              defaultValue={props.defaultValue}
              onChange={(e) => {
                handleTextareaHeightResize();
                props.onChangeOption(e.target.value);
              }}
              placeholder={question.placeholder ?? "내 답변"}
              rows={3}
            ></textarea>
          </React.Fragment>
        )}
        {question.type === "radio" && (
          <>
            {question.options?.map((option, index) => {
              return (
                <div className="form-check mb-3" key={index}>
                  <input
                    type="radio"
                    className="form-check-input"
                    id={"validationFormCheck" + (index + 1)}
                    name={question.title}
                    required={question.isRequire}
                    defaultChecked={
                      props.defaultValue === option.title ||
                      type === option.typeName
                    }
                    onClick={() => {
                      props.onChangeOption(option.title, option.childSection);
                      setIsEtcRadioSelected(false);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={"validationFormCheck" + (index + 1)}
                  >
                    {option.title}
                  </label>
                </div>
              );
            })}
            {question.isAllowEtc && isFormSetup && (
              <div className="form-check  d-flex align-items-center gap-2 mb-3">
                <input
                  type="radio"
                  className="form-check-input"
                  id="validationFormCheck100"
                  name={question.title}
                  required={question.isRequire}
                  onChange={(e) => {
                    props.onChangeOption(
                      etcRadioInput as string,
                      question.etcChildSection
                    );
                    setIsEtcRadioSelected(e.target.checked);
                  }}
                  defaultChecked={etcRadioInput != undefined}
                />
                <label
                  className="form-check-label"
                  htmlFor="validationFormCheck100"
                >
                  기타
                </label>
                <input
                  name={question.title}
                  className="form-control form-control-sm"
                  placeholder=""
                  aria-label=""
                  disabled={!isEtcRadioSelected}
                  required={question.isRequire}
                  defaultValue={etcRadioInput}
                  type={question.inputType}
                  onChange={(e) =>
                    props.onChangeOption(
                      e.target.value,
                      question.etcChildSection
                    )
                  }
                />
              </div>
            )}
          </>
        )}
        {question.type === "checkbox" && (
          <>
            {question.options?.map((option, index) => (
              <div className="form-check mb-3" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={
                    index == 0
                      ? "flexCheckDefault"
                      : "flexCheckChecked" + (index + 1)
                  }
                  name={question.title}
                  required={
                    question.isRequire &&
                    checkedOption.length === 0 &&
                    !isEtcInputChecked
                  }
                  defaultChecked={props.defaultValue?.includes?.(option.title)}
                  onChange={(e) => {
                    // 체크되어있으면 checkedOption에 append, 안되어있으면 option.title을 삭제
                    if (e.target.checked) {
                      setCheckedOption([...checkedOption, option.title]);
                    } else {
                      const filtered = checkedOption.filter(
                        (value) => value !== option.title
                      );
                      setCheckedOption([...filtered]);
                    }
                    const combinedChecked = etcCheckedInput
                      ? checkedOption
                          .concat(option.title)
                          .concat(etcCheckedInput!)
                      : checkedOption.concat(option.title);

                    props.onChangeOption(combinedChecked, option.childSection);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={
                    index == 0
                      ? "flexCheckDefault"
                      : "flexCheckChecked" + (index + 1)
                  }
                >
                  {option.title}
                </label>
              </div>
            ))}
            {question.isAllowEtc && (
              <div className="form-check d-flex align-items-center gap-2 mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={question.title}
                  required={
                    question.isRequire &&
                    checkedOption.length === 0 &&
                    !isEtcInputChecked
                  }
                  onChange={(e) => {
                    props.onChangeOption(e.target.value);
                    setIsEtcInputChecked(e.target.checked);
                    if (e.target.checked) {
                      setEtcCheckedInput(etcInputText);
                    }
                    const combinedChecked =
                      etcInputText && e.target.checked
                        ? checkedOption.concat(etcInputText!)
                        : checkedOption;
                    props.onChangeOption(combinedChecked);
                  }}
                  checked={isEtcInputChecked}
                />
                <label className="form-check-label" htmlFor="flexCheckChecked8">
                  기타
                </label>
                <input
                  className="form-control form-control-sm"
                  placeholder=""
                  aria-label=""
                  disabled={!isEtcInputChecked}
                  required={question.isRequire}
                  defaultValue={etcCheckedInput}
                  type={question.inputType}
                  onChange={(e) => {
                    setEtcInputText(e.target.value);
                    setEtcCheckedInput(e.target.value);
                    const combinedChecked = e.target.value
                      ? checkedOption.concat(e.target.value!)
                      : checkedOption;
                    props.onChangeOption(combinedChecked);
                  }}
                />
              </div>
            )}
          </>
        )}
        {question.type === "file" && (
          <React.Fragment>
            <div className="mb-3">
              <input
                className="form-control"
                maxLength={100}
                required={question.isRequire}
                id={question.title}
                type="file"
                accept={question.acceptFileExtensions
                  ?.map((ex) => `.${ex}`)
                  ?.join(",")}
                name={question.title}
                multiple
                onChange={handleChangeFiles}
              />
            </div>
          </React.Fragment>
        )}
        {question.type === "date" && (
          <input
            type="text"
            className="form-control hasDatepicker"
            onClick={() => setIsDateOpen(true)}
            id="datepicker"
            value={currentDate?.format("yyyy-MM-dd")}
            required
          />
        )}
        {question.type === "explainPicture" && (
          <img src={question.explainPictureUrl} className="w-100" />
        )}
      </div>
    </React.Fragment>
  );
};
