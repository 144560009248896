import { useContext, useRef, useState } from "react";
import { api } from "../../../config/api";
import AlertContext from "../../../contexts/AlertContext";
import DupLoginContext from "../../../contexts/DupLoginContext";
import CouponBoxModel from "../../../models/coupon/CouponBoxModel";
import CouponPaymentModel from "../../../models/coupon/CouponPaymentModel";

interface Props {
  coupons?: CouponBoxModel[];
  isSelectable?: boolean;
  selectedCoupon?: CouponPaymentModel;
  refreshCoupons: () => void;
  onConfirm: (key?: string) => void;
}

function calculateDday(selectedDate: Date) {
  var currentDate = new Date();

  // 두 날짜 간의 시간 차이 계산 (밀리초 단위)
  var timeDiff = selectedDate.getTime() - currentDate.getTime();

  // 밀리초를 일 단위로 변환
  var daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff > 0) {
    return "D-" + daysDiff;
  } else if (daysDiff === 0) {
    return "D-day";
  } else {
    return "D+" + Math.abs(daysDiff);
  }
}

export default (props: Props) => {
  const couponInput = useRef<HTMLInputElement>(null);
  const alert = useContext(AlertContext);
  const dlContext = useContext(DupLoginContext);
  const [selectedCouponKey, setSelectedCouponKey] = useState(
    props.selectedCoupon?.key
  );
  //   const [coupons, setCoupons] = useState(props.coupons);
  const handleClickCouponAdd = async () => {
    const couponKey = couponInput.current?.value + "";
    const res = await dlContext.checkDupLogin(() =>
      api.getCouponUsableAsync(couponKey)
    );
    if (res.data.can_use) {
      const registCouponRes = await api.postRegistCouponAsync(couponKey);
      if (registCouponRes.status === 200 && registCouponRes.data.data === 1) {
        // props.refreshCoupons();
        // setIsRegistMode(false);
      } else {
        alert.showAlert(registCouponRes.data.message, () => {
          //   props.refreshCoupons();
        });
      }
    } else {
      alert.showAlert(res.data.message, () => {
        // props.refreshCoupons();
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="CouponModal"
      tabIndex={-1}
      aria-labelledby="CouponModalLabel"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="CouponModalLabel">
              보유중인 쿠폰
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="needs-validation">
              <div className="input-group has-validation mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="validationCustomUsername"
                  placeholder="쿠폰코드를 입력해주세요."
                  aria-describedby="inputGroupPrepend"
                  ref={couponInput}
                  required
                />
                <button
                  className="btn btn-primary left-icon"
                  type="button"
                  id="coupon-submit"
                  onClick={() => handleClickCouponAdd()}
                >
                  <span className="material-symbols-outlined me-2">add</span>
                  쿠폰 등록
                </button>
                <div className="invalid-feedback">
                  올바른 쿠폰코드를 입력해주세요.
                </div>
              </div>
            </form>
            <div className="modal-coupon-list">
              <div
                className="coupon-list aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {props.coupons?.map((coupon, index) => {
                  return (
                    <div className="col-12">
                      <input
                        type="radio"
                        className="btn-check"
                        name="coupons"
                        id="coupon-option1"
                        disabled={false}
                        onChange={() => {
                          if (selectedCouponKey === coupon.key) {
                            setSelectedCouponKey("");
                          } else {
                            setSelectedCouponKey(coupon.key);
                          }
                        }}
                      />
                      <label className="btnbox w-100" htmlFor="coupon-option1">
                        <div className="coupon-list-item bg-white">
                          <div className="price-wrap">
                            <h4>
                              <span></span>원
                            </h4>
                            <span className="badge text-bg-secondary">
                              {calculateDday(new Date(coupon.expiryDate))}
                            </span>
                          </div>
                          <div className="flex-2">
                            <div className="title-lg">{coupon.name}</div>
                            <div className="caption">
                              <span>
                                {new Date(coupon.expiryDate).format(
                                  "yyyy-MM-dd"
                                )}
                              </span>
                              까지
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="modal-footer-wrap">
              <button
                type="button"
                className="btn btn-light btn-lg flex-1"
                data-bs-dismiss="modal"
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary btn-lg flex-2"
                onClick={() => props.onConfirm(selectedCouponKey)}
                data-bs-dismiss="modal"
              >
                쿠폰 적용
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
