import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, Card, Space, Button, List, Divider, message, Modal, Input, Spin, Tooltip } from 'antd';
import { LikeOutlined, LikeFilled, DislikeOutlined, DislikeFilled, MessageOutlined, ArrowLeftOutlined, FlagOutlined, EditOutlined, DeleteOutlined, StarOutlined } from '@ant-design/icons';
import styles from './AIPostDetail.module.scss';
import CommentForm from './CommentForm';
import { api } from '../../config/api';
import PostForm from './PostForm';
import { pages } from '../../constants/PagePaths';
import { AIAnswer, AIPost } from '../../models/aiCommunityModel';

const { Title, Text, Paragraph } = Typography;

const AIPostDetail: React.FC = () => {
  const { id } = useParams<{ id: string; category: string }>();
  const history = useHistory();
  const [post, setPost] = useState<AIPost | null>(null);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [aiAnswer, setAIAnswer] = useState<AIAnswer | undefined>(undefined);
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  const [editingContent, setEditingContent] = useState('');

  const handleBack = useCallback(() => {
    history.push(`${pages.communityAi}`);
  }, [history]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    
    const fetchPost = async () => {
      try {
        const response = await api.getAIPostDetail(parseInt(id));
        setPost(response);
        setIsAuthor(response.isAuthor);
        
        if (!response.answers?.length) {
          setIsAnswerLoading(true);
          intervalId = setInterval(async () => {
            const updatedPost = await api.getAIPostDetail(parseInt(id));
            if (updatedPost.answers?.length) {
              setPost(updatedPost);
              setAIAnswer(updatedPost.answers.find(Boolean));
              setIsAnswerLoading(false);
              if (intervalId) {
                clearInterval(intervalId);
                intervalId = null;
              }
            }
          }, 3000);
        } else {
          setAIAnswer(response.answers.find(Boolean));
        }
      } catch (error) {
        console.error('게시글을 불러오는 데 실패했습니다:', error);
        message.error('게시글을 찾을 수 없습니다.');
        handleBack();
      }
    };

    fetchPost();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [id, handleBack]);

  if (!post) {
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" tip="게시글을 불러오는 중..." />
      </div>
    );
  }

  const handleLike = async () => {
    try {
      await api.toggleAIPostLike(post.id);
      setPost(prevPost => {
        if (!prevPost) return null;
        const newLikeCount = prevPost.isLiked ? prevPost.likes - 1 : prevPost.likes + 1;
        return {
          ...prevPost,
          likes: newLikeCount,
          isLiked: !prevPost.isLiked
        };
      });
    } catch (error) {
      console.error('좋아요 처리 중 오류 발생:', error);
      if ((error as any).response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error('좋아요 처리에 실패했습니다.');
      }
    }
  };

  const handleAIAnswerReaction = async (type: 'like' | 'dislike') => {
    try {
      if (!aiAnswer) return;
      
      const isCancellation = type === aiAnswer.reaction;
      const isDislikeCancellation = type === 'dislike' && aiAnswer.reaction === 'dislike';
      
      await api.reactToAIAnswer(aiAnswer.id, type);
      setAIAnswer(prev => {
        if (!prev) return undefined;
        return {
          ...prev,
          reaction: isCancellation ? null : type
        };
      });
      
      if (type === 'dislike' && !isDislikeCancellation) {
        setFeedbackModalVisible(true);
      }
    } catch (error) {
      console.error('AI 답변 반응 처리 실패:', error);
    }
  };

  const handleAddComment = async (content: string) => {
    setIsSubmittingComment(true);
    try {
      const newComment = await api.createAIPostComment(post.id, { content });
      setPost(prevPost => prevPost ? {
        ...prevPost, 
        comments: [...prevPost.comments, {
          ...newComment,
          isAuthor: true
        }]
      } : null);
      message.success('댓글이 등록되었습니다!');
    } catch (error) {
      console.error('댓글 등록 중 오류 발생:', error);
      if ((error as any).response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error('댓글 등록에 실패했습니다.');
      }
    } finally {
      setIsSubmittingComment(false);
    }
  };

  const handleReport = () => {
    if (isAuthor) {
      message.error('자신의 게시글은 신고할 수 없습니다.');
      return;
    }
    setIsReportModalVisible(true);
  };

  const handleReportSubmit = async () => {
    if (isAuthor) {
      message.error('자신의 게시글은 신고할 수 없습니다.');
      return;
    }

    if (!reportReason.trim()) {
      message.error('신고 사유를 입력해주세요.');
      return;
    }

    try {
      await api.reportAIPost(post.id, { reason: reportReason });
      message.success('게시글이 신고되었습니다.');
      setIsReportModalVisible(false);
      setReportReason('');
    } catch (error: any) {
      console.error('게시글 신고 중 오류 발생:', error);
      if (error.response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error(error.response?.data?.detail || '신고 처리 중 오류가 발생했습니다.');
      }
    }
  };

  const handleDelete = () => {
    if (!isAuthor) {
      message.error('자신이 작성한 게시글만 삭제할 수 있습니다.');
      return;
    }

    Modal.confirm({
      title: '게시글 삭제',
      content: '정말로 이 게시글을 삭제하시겠습니까?',
      onOk: async () => {
        try {
          await api.deleteAIPost(post.id);
          message.success('게시글이 삭제되었습니다.');
          history.replace(`${pages.communityAi}`);
        } catch (error) {
          console.error('게시글 삭제 중 오류 발생:', error);
          message.error('게시글 삭제에 실패했습니다.');
        }
      },
    });
  };

  const handleEdit = () => {
    if (!isAuthor) {
      message.error('자신이 작성한 게시글만 수정할 수 있습니다.');
      return;
    }
    setIsEditModalVisible(true);
  };

  const handleFeedbackSubmit = async () => {
    if (!aiAnswer || !isAuthor) return;

    try {
      await api.submitAIAnswerFeedback(aiAnswer.id, feedback);
      message.success('피드백이 제출되었습니다.');
      setFeedbackModalVisible(false);
      setFeedback('');
    } catch (error: any) {
      console.error('피드백 제출 중 오류 발생:', error);
      message.error('피드백 제출에 실패했습니다.\n' + error.response?.data?.error);
    }
  };

  const handleEditComment = async (commentId: number, content: string) => {
    try {
      const updatedComment = await api.updateAIComment(post.id, commentId, content);
      setPost(prevPost => {
        if (!prevPost) return null;
        return {
          ...prevPost,
          comments: prevPost.comments.map(comment =>
            comment.id === commentId ? { ...updatedComment, isAuthor: true } : comment
          )
        };
      });
      setEditingCommentId(null);
      message.success('댓글이 수정되었습니다.');
    } catch (error) {
      console.error('댓글 수정 중 오류 발생:', error);
      if ((error as any).response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error('댓글 수정에 실패했습니다.');
      }
    }
  };

  const handleDeleteComment = async (commentId: number) => {
    Modal.confirm({
      title: '댓글 삭제',
      content: '정말로 이 댓글을 삭제하시겠습니까?',
      onOk: async () => {
        try {
          await api.deleteAIComment(post.id, commentId);
          setPost(prevPost => {
            if (!prevPost) return null;
            return {
              ...prevPost,
              comments: prevPost.comments.filter(comment => comment.id !== commentId)
            };
          });
          message.success('댓글이 삭제되었습니다.');
        } catch (error) {
          console.error('댓글 삭제 중 오류 발생:', error);
          if ((error as any).response?.status === 401) {
            message.error('로그인이 필요합니다.');
          } else {
            message.error('댓글 삭제에 실패했습니다.');
          }
        }
      }
    });
  };

  // 스타일 추가
  const likedStyle = { fill: '#1890ff' };
  const dislikedStyle = { fill: '#ff4d4f' };

  return (
    <div className={styles.aiPostDetailContainer}>
      <Button 
        icon={<ArrowLeftOutlined />} 
        onClick={handleBack} 
        className={styles.backButton}
      >
        목록으로 돌아가기
      </Button>
      <Card className={styles.aiPostDetailCard}>
        <Title level={2}>{post.title}</Title>
        <div className={styles.postHeader}>
          <Text strong>{post.author}</Text>
          <Text type="secondary">{new Date(post.createdAt).format("yyyy-MM-dd HH:mm")}</Text>
        </div>
        <div 
          className={styles.postContent}
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
        <div className={styles.postFooter}>
          <Space>
            <Button 
              icon={post.isLiked ? <LikeFilled className={styles.likeIcon} /> : <LikeOutlined className={styles.likeIcon} />}
              onClick={handleLike}
              className={`${styles.iconButton} ${post.isLiked ? styles.liked : ''}`}
            >
              좋아요 {post.likes}
            </Button>
            {!isAuthor && (
              <Button 
                icon={<FlagOutlined />} 
                onClick={handleReport}
                className={styles.iconButton}
              >
                신고
              </Button>
            )}
            {isAuthor && (
              <>
                <Button 
                  icon={<DeleteOutlined />} 
                  onClick={handleDelete}
                  className={styles.iconButton}
                  danger
                >
                  삭제
                </Button>
              </>
            )}
          </Space>
        </div>
      </Card>

      {isAnswerLoading ? (
        <Card className={styles.aiAnswerCard}>
          <div className={styles.loadingContainer}>
            <Spin size="large" />
            <Text>AI가 답변을 생성하고 있습니다...</Text>
          </div>
        </Card>
      ) : aiAnswer && (
        <Card className={styles.aiAnswerCard}>
          <Title level={3}>AI 답변</Title>
          <div 
            className={styles.aiAnswerContent}
            dangerouslySetInnerHTML={{ __html: aiAnswer.answer.replace(/\n/g, '<br>') }} 
          />
          {aiAnswer.citations && aiAnswer.citations.length > 0 && (
            <div className={styles.citations}>
              <Divider />
              <Text strong>참조 링크: </Text>
              <span className={styles.citationLinks}>
                {aiAnswer.citations.map((citation, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && ", "}
                    <Tooltip title={citation}>
                      <a 
                        href={citation} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className={styles.citationLink}
                      >
                        [{index + 1}]
                      </a>
                    </Tooltip>
                  </React.Fragment>
                ))}
              </span>
            </div>
          )}
          {isAuthor && (
            <div className={styles.aiAnswerFooter}>
              <Space>
                <Button 
                  icon={aiAnswer.reaction === 'like' ? <LikeFilled /> : <LikeOutlined />}
                  onClick={() => handleAIAnswerReaction('like')}
                  className={aiAnswer.reaction === 'like' ? styles.reactionLike : ''}
                >
                </Button>
                <Button 
                  icon={aiAnswer.reaction === 'dislike' ? <DislikeFilled /> : <DislikeOutlined />}
                  onClick={() => handleAIAnswerReaction('dislike')}
                  className={aiAnswer.reaction === 'dislike' ? styles.reactionDislike : ''}
                >
                </Button>
                <Button onClick={() => setFeedbackModalVisible(true)}>
                  피드백 남기기
                </Button>
              </Space>
            </div>
          )}
        </Card>
      )}

      <Divider orientation="left">댓글</Divider>
      
      <List
        itemLayout="horizontal"
        dataSource={post.comments}
        renderItem={comment => (
          <List.Item
            actions={comment.isAuthor ? [
              <Space key="comment-actions">
                {editingCommentId === comment.id ? (
                  <>
                    <Button 
                      size="small" 
                      type="primary"
                      onClick={() => handleEditComment(comment.id, editingContent)}
                    >
                      저장
                    </Button>
                    <Button 
                      size="small"
                      onClick={() => setEditingCommentId(null)}
                    >
                      취소
                    </Button>
                  </>
                ) : (
                  <>
                    <Button 
                      size="small"
                      onClick={() => {
                        setEditingCommentId(comment.id);
                        setEditingContent(comment.content);
                      }}
                    >
                      수정
                    </Button>
                    <Button 
                      size="small" 
                      danger
                      onClick={() => handleDeleteComment(comment.id)}
                    >
                      삭제
                    </Button>
                  </>
                )}
              </Space>
            ] : []}
          >
            <List.Item.Meta
              title={<Text strong>{comment.author}</Text>}
              description={
                <>
                  <div className={styles.commentContent}>
                    {editingCommentId === comment.id ? (
                      <Input.TextArea
                        value={editingContent}
                        onChange={(e) => setEditingContent(e.target.value)}
                        autoSize
                      />
                    ) : (
                      comment.content
                    )}
                  </div>
                  <Text type="secondary">
                    {new Date(comment.createdAt).format("yyyy-MM-dd HH:mm")}
                  </Text>
                </>
              }
            />
          </List.Item>
        )}
      />

      <Divider orientation="left">댓글 작성</Divider>
      <CommentForm onSubmit={handleAddComment} isSubmitting={isSubmittingComment} />
      
      <Modal
        title="게시글 신고"
        visible={isReportModalVisible}
        onOk={handleReportSubmit}
        onCancel={() => setIsReportModalVisible(false)}
        zIndex={1060}
      >
        <Input.TextArea
          rows={4}
          placeholder="신고 사유를 입력해주세요"
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
        />
      </Modal>

      <Modal
        title="AI 답변 피드백"
        visible={feedbackModalVisible}
        onOk={handleFeedbackSubmit}
        onCancel={() => setFeedbackModalVisible(false)}
        zIndex={1060}
      >
        <Input.TextArea
          rows={4}
          placeholder="AI 답변에 대한 피드백을 입력해주세요"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default AIPostDetail;
