/* eslint-disable import/no-anonymous-default-export */
import { CircularProgress, Dialog } from "@material-ui/core";
import React, { useState } from "react";

export const LoadingContext = React.createContext({
  showLoading: (message?: string) => {},
  closeLoading: () => {},
});

export interface LoadingContextProviderProps {
  children?: React.ReactNode;
}

export const LoadingContextProvider = (props: LoadingContextProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const showLoading = (message?: string) => {
    setIsOpen(true);
    setMessage(message ?? "");
  };

  const closeLoading = () => {
    setIsOpen(false);
    setMessage("");
  };

  return (
    <LoadingContext.Provider
      value={{
        showLoading,
        closeLoading,
      }}
    >
      <Dialog open={isOpen} disableScrollLock={true}>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            whiteSpace: "pre",
          }}
        >
          <CircularProgress />
          <div style={{marginTop: "20px"}}>{message}</div>
        </div>
      </Dialog>
      {props.children}
    </LoadingContext.Provider>
  );
};
