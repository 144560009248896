// Curriculum component
import { useState, useEffect, useRef } from "react";
import styles from "./LectureList.module.scss";
import { useHistory } from "react-router-dom";
import { videoApi } from "../../../../config/videoApi";
import { util } from "../../../../config/util";
import { pages, videoPages } from "../../../../constants/PagePaths";
import React from "react";
import {
  lectureCategories,
  lectureModels,
} from "../../../main/components/RecommendLeture";

const LectureListSection = () => {
  const history = useHistory();
  const [lectures, setLectures] = useState<any>([]);

  useEffect(() => {
    new Promise(async () => {
      const cardRes = await videoApi.getMainCardCourseAsync();
      getCourse();
    });
  }, []);

  const getCourse = async (filter?: string) => {
    const res = await videoApi.getCourseListAsync();
    res.data.courses.sort(function (a: any, b: any) {
      return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
    });

    let searchModels = lectureModels;
    if (filter) {
      searchModels = lectureModels.filter(
        (lecture) => lecture.category == filter
      );
    }
    const orderedCourses = searchModels.map((lectureModel) =>
      res.data.courses.find((course: any) => course.id === lectureModel.id)
    );
    setLectures(orderedCourses);
  };

  const lectureView = () => {
    return (
      <>
        <div
          className="card-wrap column3"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {lectures.map((lecture: any) => {
            return (
              <div
                className="card pointer"
                onClick={() => history.push(`video/curriculum/${lecture.id}`)}
              >
                <img
                  src={lecture.card_image}
                  className="card-img-top"
                  style={{ minHeight: "291px", objectFit: "contain" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">{lecture.title}</h5>
                  <div className="body2 mb-2">{lecture.description}</div>
                  <div className="card-text mb-2">
                    <div className="card-caption">
                      {lecture.rating != null ? (
                        <div className="rating">
                          <span>{lecture.rating.toFixed(1)}</span>
                          <div className="star-wrap">
                            {Array.from({ length: lecture.rating }).map(
                              (_, index) => (
                                <span></span>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="price">
                        <span className="discount">
                          <span>{lecture.origin_amount.toLocaleString()}</span>
                          원
                        </span>
                        <span>{lecture.amount.toLocaleString()}</span>원
                      </div>
                    </div>
                  </div>
                  <div className="badge-wrap">
                    <span className="badge rounded-pill text-bg-primary-soft">
                      {
                        lectureModels.filter(
                          (lectureModel) => lectureModel.id == lecture.id
                        )[0].userCount
                      }
                      명
                    </span>
                    <span className="badge rounded-pill text-bg-primary">
                      {lecture.service_product}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <section>
      <div className="container page">
        <div className="section-header text-white">강의목록</div>
        <div className="frame" style={{ overflow: "hidden" }}>
          <ul
            className="nav nav-tabs slidee white-ver"
            id="myTab"
            role="tablist"
            style={{ transform: "translateZ(0px)", width: 588 }}
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="all-tab"
                data-bs-toggle="tab"
                data-bs-target="#all-tab-pane"
                type="button"
                role="tab"
                aria-controls="all-tab-pane"
                aria-selected="true"
                onClick={() => {
                  getCourse();
                }}
              >
                전체
              </button>
            </li>
            {lectureCategories.map((category) => {
              return (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="one-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#one-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="one-tab-pane"
                    aria-selected="false"
                    tabIndex={-1}
                    onClick={() => {
                      getCourse(category);
                    }}
                    disabled={category.includes("준비")}
                  >
                    {category}
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="tab-content mt-6" id="myTabContent">
            <div className="tab-pane fade show active" tabIndex={0}>
              {lectureView()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LectureListSection;
