/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { pages } from "../../constants/PagePaths";
import * as crypto from "crypto-js";
import { SampleStatementDetailModel } from "../../constants/SampleStatementPageData";
import AlertContext from "../../contexts/AlertContext";
import DupLoginContext from "../../contexts/DupLoginContext";
import SampleStatementDetailPresenter from "./SampleStatementDetailPresenter";
import { LoadingContext } from "../../contexts/LoadingContext";
import { FreeUseDay } from "../../constants/General";

interface Props {}

export default (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const alert = useContext(AlertContext);
  const [isRecommended, setIsRecommended] = useState(false);
  const [
    statementDetailModel,
    setStatementDetailModel,
  ] = useState<SampleStatementDetailModel>();
  const id = +location.pathname.split("/")[2];
  const isProcessing = useRef(false);
  const dupLogin = useContext(DupLoginContext);
  const loading = useContext(LoadingContext);
  const [isImporting, setIsImporting] = useState(false);
  const isFreeUser = useRef(false);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " +
          FreeUseDay +
          "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
      return;
    }

    new Promise(async () => {
      const res = await dupLogin.checkDupLogin(() =>
        api.getSampleStatementDetail(id)
      );
      const detailData = res.data?.model;
      const isOwner = res.data?.isOwner;
      setIsOwner(isOwner);
      const downloadUrl = detailData?.download_urls?.replaceAll("'", '"');
      const item: SampleStatementDetailModel = {
        ...detailData,
        created: new Date(detailData?.created),
        keywords:
          (detailData?.keywords &&
            JSON.parse(detailData?.keywords?.replaceAll("'", '"'))) ||
          [],
        downloadUrls: JSON.parse(downloadUrl),
        products: JSON.parse(detailData?.products),
        mainType: detailData?.main_type,
        recommendCount: detailData?.recommend_count,
      };
      setStatementDetailModel(item);

      const res2 = await api.getSampleStatementIsRecommend(id);
      setIsRecommended(res2.data?.is_recommended);

      const info = await dupLogin.checkDupLogin(api.getMyInfo);
      if (
        info?.data?.expiry_date?.toString() === "None" ||
        util.subtractionDateFromNow(new Date(info?.data?.expiry_date)) < 0
      ) {
        isFreeUser.current = true;
      }
    });
  }, []);

  const checkIsLogin = () => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert("로그인 후 이용해주세요.", () => {
        history.push(pages.login);
        return;
      });
      return false;
    }
    return true;
  };

  const handleRecommendClick = async () => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    if (isRecommended) {
      await api.deleteSampleStatementRecommend(id);
      setIsRecommended(false);
      await refreshRecommendCount();
    } else {
      await api.postSampleStatemnetRecommend({ id: id });
      setIsRecommended(true);
      await refreshRecommendCount();
    }
    isProcessing.current = false;
  };

  const refreshRecommendCount = async () => {
    const res = await api.getSampleStatementDetail(id);
    if (statementDetailModel) {
      setStatementDetailModel({
        ...statementDetailModel,
        recommendCount: res?.data?.model?.recommend_count,
      });
    }
  };

  const handleDownloadClick = () => {
    if (checkIsLogin()) {
      statementDetailModel?.downloadUrls?.forEach((url) => {
        if (url) {
          let link = document.createElement("a") as HTMLAnchorElement;
          link.href = url;
          link.target = "_blank";
          link.download = "download";
          document.body.appendChild(link);
          link.click();
        } else {
          alert.showAlert(
            "다운로드할 파일이 없습니다.\n우측 하단의 고객센터를 통해 문의바랍니다."
          );
        }
      });
    }
  };

  const handleToListClick = () => {
    const state: any = location?.state;
    if (state?.prevPath) {
      history.replace(state?.prevPath);
    } else {
      history.push(pages.statementlist);
    }
  };

  const importProject = async (url: string, title: string) => {
    if (!url) return;
    const res = await api.getFilesFromUrl(url);
    const data = new Blob([res.data]);
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = async (x) => {
      try {
        const str = fileReader.result as string;
        const key = str.substring(0, 5);
        const value = str.substring(5);
        const obj = JSON.parse(
          crypto.AES.decrypt(value, key).toString(crypto.enc.Utf8)
        );
        obj.forEach((element: any) => {
          element.name = title;
        });
        const normal = obj.filter((element: any) => element.type === "normal");

        const res = await dupLogin.checkDupLogin(() =>
          api.uploadProject({
            normal,
            contract: [],
            change: [],
            ready: [],
            complete: [],
            estimate: [],
            copy: [],
          })
        );
        if (res?.data?.code === 1) {
          const url = `${process.env.REACT_APP_COST_URL}document/${window.btoa(
            res?.data?.project_id
          )}`;
          window.location.href = url;
        } else {
          throw new Error();
        }
        loading.closeLoading();
      } catch (error) {
        loading.closeLoading();
        alert.showAlert(
          "오류가 발생하였습니다. 파일을 다시한번 확인해 주세요.",
          () => {
            history.push(pages.index);
          }
        );
      }
    };
    fileReader.readAsBinaryString(data);
  };

  const handleImportClick = () => {
    if (checkIsLogin() && !isFreeUser.current) {
      setIsImporting(true);
    } else {
      alert.showAlert("이용권을 구매해주셔야 이용가능합니다.");
    }
  };

  const handleProjectNameModalClose = (title?: string) => {
    setIsImporting(false);
    if (title) {
      loading.showLoading();
      importProject(statementDetailModel?.downloadUrls?.[0] || "", title);
    }
  };

  const handleDeleteClick = async () => {
    await alert.showConfirmAlert(
      "정말 삭제하시겠습니까?",
      "삭제하면 이전으로 되돌릴 수 없습니다.",
      "삭제",
      "취소",
      async () => {
        try {
          const res = await api.deleteSampleStatement(id);
          if (res.status === 200) {
            handleToListClick();
          }
        } catch (err: any) {
          alert.showAlert(err.response.data);
        }
      }
    );
  };

  return (
    <SampleStatementDetailPresenter
      statementDetailModel={statementDetailModel}
      isRecommended={isRecommended}
      isCreateOpen={isImporting}
      isOwner={isOwner}
      onCloseProjectNameModal={handleProjectNameModalClose}
      onClickRecommend={handleRecommendClick}
      onClickDownload={handleDownloadClick}
      onClickImport={handleImportClick}
      onClickToList={handleToListClick}
      onClickDelete={handleDeleteClick}
    />
  );
};
