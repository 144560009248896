export default () => {
  return (
    <section>
      <div className="banner-wrap-light">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="banner-txt">
              <h2 className="mb-4">강사등록 후 수익을 만들어 보세요!</h2>
              <button className="btn btn-xlg right-icon btn-dark" disabled>
                준비중 입니다
                <span className="material-symbols-outlined">chevron_right</span>
              </button>
            </div>
            <div
              className="banner-img"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <img src="/images/ddusul/CK_ti375a42906.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
