// import React from "react";
import styles from "./VideoFindAccount.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../../../config/api";
import { util } from "../../../../config/util";
import { Cookies } from "../../../../constants/Cookies";
import { videoPages } from "../../../../constants/PagePaths";
import { passwordRegex } from "../../../../constants/RegexData";
import AlertContext from "../../../../contexts/AlertContext";
import { Imp } from "../../../../models/payment/iamport/IMP";

interface Props {}

const VideoFindAccount = (props: Props) => {
  const history = useHistory();
  const alert = useContext(AlertContext);
  const [pwToken, setPwToken] = useState("");
  const [isSuccessFindPw, setIsSuccessFindPw] = useState(false);
  const [foundedIdInfo, setFoundedIdInfo] = useState("");
  const [selectedFindOption, setSelectedFindOption] = useState<number>(0);
  const [isCertificationInProgress, setIsCertificationInProgress] =
    useState(false);

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (key) {
      history.push(videoPages.index);
    }
  });

  const handleClickFindOption = (index: number) => {
    setIsSuccessFindPw(false);
    setFoundedIdInfo("");
    setIsCertificationInProgress(false);
    setSelectedFindOption(index);
    setPwToken("");
  };

  const handleClickIdCertification = (name: string, phoneNum: string) => {
    if (!name || !phoneNum) {
      alert.showAlert("이름과 휴대폰 번호를 입력해주세요 !");
      return;
    }
    Imp.certification(
      {
        merchant_uid: `mid_${new Date().getTime()}`,
        name,
        phone: phoneNum,
      },
      async (res) => {
        if (!res.success) {
          if (res.error_code === "F0000") {
            return;
          }
          alert.showAlert("인증 서비스에 문제가 발생했습니다.\n고객센터에 문의 부탁드립니다.\n이용에 불편을 드려 죄송합니다.")
          return;
        }
        const result: any = await api.postFindIdAsync({
          imp_uid: res.imp_uid,
        });
        if (result.status === 200) {
          setFoundedIdInfo(result.data.id);
        } else {
          alert.showAlert(`일치하는 정보가 없습니다.`);
        }
      }
    );
  };

  const handleClickPwCertification = (id: string, phoneNum: string) => {
    if (!id || !phoneNum) {
      alert.showAlert("아이디와 휴대폰 번호를 입력해주세요 !");
      return;
    }

    Imp.certification(
      {
        merchant_uid: `mid_${new Date().getTime()}`,
      },
      async (res) => {
        if (!res.success) {
          if (res.error_code === "F0000") {
            return;
          }
          alert.showAlert("인증 서비스에 문제가 발생했습니다.\n고객센터에 문의 부탁드립니다.\n이용에 불편을 드려 죄송합니다.")
          return;
        }
        const result = await api.postFindPwAsync({
          imp_uid: res.imp_uid,
          email: id,
        });
        if (result.status === 200) {
          setPwToken(result.data.token);
          setIsSuccessFindPw(true);
        } else {
          alert.showAlert(`일치하는 정보가 없습니다.`);
        }
      }
    );
  };

  const handleChangePhoneNumber = () => {
    setIsCertificationInProgress(false);
  };

  const handleClickBackToLogIn = () => {
    history.push(videoPages.login);
  };

  const handleClickChangePw = async (pw: string, pwConfirm: string) => {
    if (!passwordRegex.test(pw)) {
      alert.showAlert("비밀번호 양식을 맞춰주세요.");
      return;
    }

    if (pw != "" && pw === pwConfirm) {
      const res = await api.postChangePwInFindAccountAsync(pw, pwToken);
      if (res.status === 200) {
        alert.showAlert("변경이 완료되었습니다.", () => {
          history.push(videoPages.login);
        });
      }
    } else {
      alert.showAlert("변경할 비밀번호가 일치하지 않습니다.");
    }
  };

  const pwInput = useRef<HTMLInputElement>(null);
  const idInput = useRef<HTMLInputElement>(null);
  const pwInputConfirm = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneNumInputRef1 = useRef<HTMLInputElement>(null);
  const phoneNumInputRef2 = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.findAccount_wrapper}>
      <div className={styles.findbox_wrapper}>
        <div className={styles.inner_wrapper}>
          <div className={styles.accout_title_wrapper}>
            <div
              className={`${styles.title_item} ${
                selectedFindOption === 0 && styles.selected
              }`}
              onClick={() => handleClickFindOption(0)}
            >
              아이디(이메일) 찾기
              <div
                className={`${styles.under_line} ${
                  selectedFindOption === 0 && styles.selected
                }`}
              />
            </div>
            <div
              className={`${styles.title_item} ${
                selectedFindOption === 1 && styles.selected
              } selected`}
              onClick={() => handleClickFindOption(1)}
            >
              비밀번호 찾기
              <div
                className={`${styles.under_line} ${
                  selectedFindOption === 1 && styles.selected
                }`}
              />
            </div>
          </div>
          {selectedFindOption === 0 ? (
            <div>
              {!foundedIdInfo ? (
                <div>
                  <div className={styles.account_title_description}>
                    아이디는 회원가입시 인증한 이메일 계정입니다.
                  </div>
                  <div className={styles.user_info_wrapper}>
                    <div>이름</div>
                    <input ref={nameRef} key="idInput" />
                  </div>
                  <div className={styles.user_info_wrapper}>
                    <div>휴대폰번호</div>
                    <input
                      key="phoneInput"
                      ref={phoneNumInputRef1}
                      placeholder="-없이 숫자만 입력해주세요."
                      onChange={handleChangePhoneNumber}
                    />
                  </div>
                  <div className={styles.back_to_login}>
                    <span onClick={handleClickBackToLogIn}>로그인</span>으로
                    되돌아가기
                  </div>
                  <button
                    className={styles.find_account_button}
                    onClick={() =>
                      handleClickIdCertification(
                        nameRef.current?.value || "",
                        phoneNumInputRef1.current?.value || ""
                      )
                    }
                  >
                    인증 & 아이디 찾기
                  </button>
                </div>
              ) : (
                <div>
                  <div className={styles.account_title_description}>
                    고객님의 정보와 일치하는 아이디입니다.
                  </div>
                  <div className={styles.founded_id}>{foundedIdInfo}</div>
                  <div className={styles.founded_id_button_wrapper}>
                    <button
                      className={styles.login}
                      onClick={handleClickBackToLogIn}
                    >
                      로그인하기
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.find_pw_wrapper}>
              {!isSuccessFindPw ? (
                <div>
                  <div className={styles.user_info_wrapper}>
                    <div>아이디</div>
                    <input ref={idInput} type="email" key="idInput2" />
                  </div>
                  <div className={styles.user_info_wrapper}>
                    <div>휴대폰번호</div>
                    <input
                      // key="phoneInput2"
                      ref={phoneNumInputRef2}
                      placeholder="-없이 숫자만 입력해주세요."
                      onChange={handleChangePhoneNumber}
                    />
                  </div>
                  <div className={styles.back_to_login}>
                    <span onClick={handleClickBackToLogIn}>로그인</span>으로
                    되돌아가기
                  </div>
                  <button
                    className={styles.find_account_button}
                    onClick={() =>
                      handleClickPwCertification(
                        idInput.current?.value || "",
                        phoneNumInputRef2.current?.value || ""
                      )
                    }
                  >
                    인증 & 비밀번호 찾기
                  </button>
                </div>
              ) : (
                <div>
                  <div className={styles.pw_change_input}>
                    <div>변경할 비밀번호</div>
                    <input
                      ref={pwInput}
                      key="password1"
                      type="password"
                      placeholder="영문/숫자/특문 조합으로 8~15자리"
                    />
                  </div>
                  <div className={styles.pw_change_input}>
                    <div>비밀번호 확인</div>
                    <input
                      key="password2"
                      ref={pwInputConfirm}
                      type="password"
                    />
                  </div>
                  <button
                    className={styles.pw_change_button}
                    onClick={() =>
                      handleClickChangePw(
                        pwInput.current?.value || "",
                        pwInputConfirm.current?.value || ""
                      )
                    }
                  >
                    변경하기
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoFindAccount;
