import "./index.css";
import { Button, BackTop } from "antd";
import { PrinterOutlined, UpCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import $ from "jquery";

function EstimateSheet(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>;
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById("printArea").innerHTML;
    window.print();
    window.location.reload();
  };

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table
            style={{
              tableLayout: "fixed",
              borderBottom: "hidden",
              margin: "0 auto",
            }}
          >
            <tr style={{ lineHeight: "150px" }}>
              <td
                className="doc-title"
                colspan="8"
                align="center"
                style={{ paddingBottom: "50px" }}
              >
                견 적 서
              </td>
            </tr>
            <tr style={{ height: "50px" }}>
              <td style={{ borderRight: "hidden", paddingLeft: "5px" }}>
                공 사 명 :{" "}
              </td>
              <td
                style={{ wordBreak: "keep-all", wordWrap: "break-word" }}
                colSpan="3"
              >
                {worklist?.constructionName}
              </td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>등</span>
                  <span>록</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td align="center" colspan="3">
                {worklist?.corporateRegistNum}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td align="center" className="nonLine" colspan="4">{`${
                !worklist?.dear ? "" : worklist?.dear
              } 귀하`}</td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>상</span>
                  <span>호</span>
                </span>
              </td>
              <td align="center" colspan="3">
                {worklist?.companyName}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td colspan="4" align="center">
                {moment(worklist?.contractDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? ""
                  : moment(worklist?.contractDate).format("yyyy년　MM월　DD일")}
              </td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>주</span>
                  <span>소</span>
                </span>
              </td>
              <td
                style={{ wordBreak: "keep-all", wordWrap: "break-word" }}
                align="center"
                colspan="3"
              >
                {worklist?.businessAddress}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td colspan="4" align="center">
                아래와 같이 <u>견적</u> 합니다.
              </td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>대</span>
                  <span>표</span>
                </span>
              </td>
              <td
                colSpan="2"
                align="right"
                className="nonLine"
                style={{ letterSpacing: "10px" }}
              >
                {worklist?.representativeName}
              </td>
              <td
                style={{ paddingRight: "5px" }}
                align="right"
                className="nonLine"
              >
                (인)
              </td>
            </tr>
          </table>

          <table style={{ marginLeft: "1px" }}>
            <tr className="nonLine">
              <td style={{ height: "30px" }} colspan="7">
                　
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} height="30px">
                공사금액
              </td>
              <td align="center" colSpan="6" style={{ fontWeight: "600" }}>
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>

            <tr align="center">
              <td width="200px" height="30px">
                품　명
              </td>
              <td width="50px">규 격</td>
              <td width="50px">수 량</td>
              <td width="50px">단 위</td>
              <td width="50px">단 가</td>
              <td>금 액</td>
              <td width="50px">비 고</td>
            </tr>

            <tr align="center">
              <td
                style={{ wordBreak: "break-all", wordWrap: "break-word" }}
                height="30px"
              >
                {worklist?.constructionName}
              </td>
              <td></td>
              <td style={{ fontSize: "13px" }}>1</td>
              <td style={{ fontSize: "13px" }}>식</td>
              <td></td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                {props.commaContractPrice}
              </td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr align="center" style={{ fontWeight: "600" }}>
              <td className="center" height="30px">
                합　　　계
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                {props.commaContractPrice}
              </td>
              <td>　</td>
            </tr>
          </table>
        </div>
      </div>

      <Button
        className="circleBtn"
        style={{
          backgroundColor: "rgb(68, 132, 255)",
          float: "right",
          position: "fixed",
          right: "63px",
          bottom: "200px",
        }}
        onClick={printedBtn}
      >
        <div>
          <PrinterOutlined
            className="printer-icon"
            style={{ fontSize: "30px" }}
          />
        </div>
        <div style={{ color: "white" }}>
          현재 페이지<div style={{ color: "white" }}>출력</div>
        </div>
      </Button>
      <BackTop style={{ bottom: "150px" }}>
        <UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} />
      </BackTop>
    </div>
  );
}

export default EstimateSheet;
