import styles from "./VideoChoiceAlert.module.scss";

interface Props {
  title: string;
  text: string;
  onClickCancel: () => void;
  onClickOk: () => void;
  cancelText: string;
  okText: string;
}


export default (props: Props) => {
  console.log(props)

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      props.onClickOk();
    }
  };

  return (
    <div onKeyPress={handleKeyPress}>
      <div className={styles.wrapper}>
        <div className={styles.alerts}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.content}>{props.text}</div>
          <div className={styles.btn_wrapper}>
            <div className={styles.disagree_button} 
            onClick={props.onClickCancel}
            >{props.cancelText}</div>
            
            <div
            className={styles.confirm_button} 
            onClick={props.onClickOk}
            >{props.okText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};