/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styles from "./categorySelector.module.scss";
import { CategoryItem } from "../../../models/CategoryItem";

interface Props {
  categories?: CategoryItem[];
  selectedCategoryIndex: number;
  onClickCategory: (index: number) => void;
}

export default (props: Props) => {
  return (
    <React.Fragment>
      <div className={styles.category_wrapper}>
        {props.categories?.map(
          (category: CategoryItem, index: number) => (
            <div
              key={index}
              className={`${styles.category_box}  ${
                index === props.selectedCategoryIndex && styles.selected
              }`}
              onClick={() => props.onClickCategory(index)}
            >
              {category.tag && (
                <span className={styles.tag}>{category.tag}</span>
              )}
              <span>{category?.title}</span>
            </div>
          )
        )}
      </div>
    </React.Fragment>
  );
};
