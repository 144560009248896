/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { FreeUseDay } from "../../constants/General";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import DataListPresenter from "./DataListPresenter";

interface Props {}

export default (props: Props) => {
  const alertContext = useContext(AlertContext);
  const loadingContext = useContext(LoadingContext);
  const [likeCount, setLikeCount] = useState<number>(0);
  const [isActiveLikeButton, setIsActiveLikeButton] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const id: any = location.pathname.split("/")[2];
  const [result, setResult]: any = useState();
  const commentRef = useRef(null);
  const [state, setState] = useState(0);
  useEffect(() => {
    if (!util.isLogin()) {
      alertContext.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " +
          FreeUseDay +
          "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
      return;
    } else {
      new Promise(async () => {
        const isPurchased = await util.getIsPurchased();
        if (!isPurchased) {
          alertContext.showAlert(
            "이용권 구매 후 이용해주세요.\n회원가입시 " +
              FreeUseDay +
              "일 무료기간 제공중입니다.",
            () => {
              history.replace(pages.payment);
            }
          );
          return;
        }
      });
    }
    new Promise(async () => {
      const res = await api.getDownloadDataDetail(parseInt(id));
      console.log(res.data);
      setResult(res.data.result);
      setLikeCount(res.data.result.like);
      setIsActiveLikeButton(res.data.result.post.user_liked);
    });
  }, [state]);

  const handleLikeButtonClick = () => {
    let count: number = likeCount;
    if (isActiveLikeButton) {
      setLikeCount(--count);
      api.unLikeDownloadData(id);
    } else {
      setLikeCount(++count);
      api.likeDownloadData(id);
    }
    setIsActiveLikeButton(!isActiveLikeButton);
    // refresh();
  };

  const handleFileClick = async (id: number) => {
    try {
      await loadingContext.showLoading("다운로드 중입니다.");
      const res = await api.getDownloadDataLink(id);
      const url = res.data.result;
      const fileName = url.split("/")[url.split("/").length - 1];
      const fileNameWithoutId = fileName.split("_").slice(2).join("_");

      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          "Content-Disposition": "attachment; filename=remnantwatch.hwp",
        },
      });

      const url2 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download", fileNameWithoutId);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    loadingContext.closeLoading();
    refresh();
  };

  const postComment = async () => {
    if (commentRef) {
      const res = await api.postDownloadDataComment(
        id,
        commentRef.current.value
      );
      if (res.status === 200) {
        alertContext.showAlert("등록되었습니다.", () => {
          // refresh();
        });
      } else {
        alertContext.showAlert("등록에 실패하였습니다.", () => {
          // refresh();
        });
      }
      refresh();
      commentRef.current.value = "";
    }
  };

  const deleteComment = async (id: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const res = await api.deleteDownloadDataComment(id);
      if (res.status === 200) {
        alertContext.showAlert("삭제되었습니다.", () => {
          // refresh();
        });
      } else {
        alertContext.showAlert("삭제에 실패하였습니다.", () => {
          // refresh();
        });
      }
      refresh();
    }
  };

  const refresh = () => {
    setState(state + 1);
  };

  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">자료실</div>

            <div className="col-12 pt-4">
              <div className="round-box">
                <div className="col-12">
                  <Link
                    to={pages.data}
                    className="btn text-gray-400 mb-3 left-icon back-btn"
                  >
                    <span className="material-symbols-outlined me-2">
                      keyboard_backspace
                    </span>
                    목록으로
                  </Link>
                  <div className="board-content" id="qna_board_detail">
                    <div className="title">{result?.post?.title}</div>
                    <div className="author">
                      <div>
                        등록일<span className="ms-2">{result?.post?.date}</span>
                      </div>

                      <div className="file-down d-flex justify-content-between align-items-center">
                        <div className="d-inline text-dark fw-bold text-decoration-underline">
                          {result?.post?.file_name}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <button
                            className={"btn btn-primary right-icon"}
                            onClick={() => {
                              handleFileClick(id);
                            }}
                          >
                            다운로드
                          </button>
                          <div
                            style={{ color: "#0F62FE" }}
                            className="material-symbols-outlined"
                            onClick={() => {
                              handleFileClick(id);
                            }}
                          >
                            download
                          </div>
                          {result?.post?.download_count}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 py-2">
                      <div className="d-flex justify-content-between align-items-center ">
                        <div>
                          <button
                            className={
                              isActiveLikeButton
                                ? "btn btn-primary right-icon"
                                : "btn btn-outline-primary right-icon"
                            }
                            onClick={() => {
                              handleLikeButtonClick();
                            }}
                          >
                            좋아요 {likeCount}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-2 border-top d-flex gap-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="댓글을 입력해보세요!"
                      ref={commentRef}
                    />
                    <button
                      className="btn btn-lg btn-primary-soft"
                      onClick={() => {
                        postComment();
                      }}
                    >
                      등록하기
                    </button>
                  </div>
                  <div className="d-flex flex-column gap-1">
                    {result?.comments.map((comment: any) => {
                      return (
                        <article className="py-2 px-2 d-flex justify-content-between">
                          <div className="d-flex flex-column">
                            <div className="d-flex gap-2">
                              <div className="fw-bold">{comment.user_name}</div>
                              {/* <div className="text-muted">2024.06.08</div> */}
                            </div>
                            <div>{comment.comment}</div>
                          </div>
                          {comment.deletable ? (
                            <button
                              type="button"
                              className="btn btn-danger-soft"
                              onClick={() => {
                                deleteComment(comment.id);
                              }}
                            >
                              삭제
                            </button>
                          ) : (
                            ""
                          )}
                        </article>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};
