import styles from "./LectureContent.module.scss";
import { useContext, useEffect, useState } from "react";
import { AiFillCheckSquare } from "react-icons/ai";
import { FaBed, FaHandsWash } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import Curriculum from "../curriculum/Curriculum";
import { Link, useHistory, useParams } from "react-router-dom";
import { VideoData } from "../../constants/VideoData";
import { pages, videoPages } from "../../../../constants/PagePaths";
import React from "react";
import AlertContext from "../../../../contexts/AlertContext";
import { videoApi } from "../../../../config/videoApi";
import { util } from "../../../../config/util";
import { Cookies } from "../../../../constants/Cookies";
import { LectureDataModel, ReviewModel } from "../../models/LectureDataModel";
import { useGA4React } from "ga-4-react";

const LectureContent = () => {
  const history = useHistory();
  const alert = useContext(AlertContext);
  const isLogin = util.isLogin();
  const { curriculumId } = useParams<{
    curriculumId: string;
  }>();
  const [isPurchase, setIsPurchase] = useState<boolean>();
  const Data = VideoData[0];
  const [lectureData, setLectureData] = useState<LectureDataModel>();
  const [explains, setExplains] = useState<string[]>();
  const [bannerFreeButtonParams, setBannerFreeButtonParams] = useState();
  const [lastVideoId, setLastVideoId] = useState();
  const lastVideoUrl = `${videoPages.course}/${lastVideoId}`;
  const paymentUrl = `${videoPages.payment}/${curriculumId}`;
  const loginUrl = pages.login;
  const bannerFreeUrl = `${videoPages.course}/${bannerFreeButtonParams}`;
  const ga4 = useGA4React();
  const [bestReviews, setBestReviews] = useState([]);
  const [etcReviews, setEtcReviews] = useState([]);
  const [isEtcReviewView, setIsEtcReviewView] = useState<boolean>(false);

  const loginAlert = async () => {
    alert.videoShowAlert("로그인 후 이용해주세요", () => {
      history.push(loginUrl);
    });
  };

  //강의구매 버튼
  const purchaseClick = async () => {
    try {
      if (ga4 instanceof Object) {
        // 페이지 진입 이벤트
        ga4.gtag("event", "purchase_btn", {
          page_title: lectureData?.title + "구매 클릭",
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      }
    } catch (err) {
      console.error(`ga Error`, err);
    }

    !isLogin ? loginAlert() : history.push(paymentUrl);
  };

  // 무료체험 버튼
  const previewClick = async () => {
    try {
      if (ga4 instanceof Object) {
        // 페이지 진입 이벤트
        ga4.gtag("event", "free_btn", {
          page_title: lectureData?.title + "무료체험 클릭",
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      }
    } catch (err) {
      console.error(`ga Error`, err);
    }

    !isLogin
      ? loginAlert()
      : lastVideoId === undefined
      ? //커리큘럼 무료 강의 중 첫 강의로 이동
        history.push(bannerFreeUrl)
      : history.push(lastVideoUrl);
  };

  // 이어듣기 버튼
  const lastVideoClick = async () => {
    history.push(lastVideoUrl);
  };

  useEffect(() => {
    new Promise(async () => {
      const key = util.getCookie(Cookies.authKey);
      const res = await videoApi.getCourseCurriculumAsync(curriculumId);
      const data = res?.data;
      if (res.status === 204) {
        await alert.videoShowAlert("존재하지 않는 강의입니다.");
        history.replace(videoPages.index);
        return;
      }
      const dataParams = data.curriculums.map((a: any) =>
        a.videos.filter((el: any) => el.is_free === true)
      )[0]?.[0]?.id;
      const explain = data?.explain.split(",");
      const explainData = explain?.slice(0, explain.length - 1);
      setExplains(explainData);
      setLectureData(data);
      // console.log(explainData);
      // console.log(data);
      setBestReviews(data?.reviews.slice(0, 3));
      setEtcReviews(data?.reviews.slice(3, data?.reviews.length));
      // dataParams가 없다면 첫번째 강의로 설정
      setBannerFreeButtonParams(dataParams ?? data.curriculums[0].videos[0].id);
      new Promise(async () => {
        if (key) {
          const course = await (
            await videoApi.getCourseLastViewedAsync(curriculumId)
          ).data;
          // console.log(course);
          setLastVideoId(course?.id);
          setIsPurchase(course?.is_purchased);
        } else {
          return;
        }
      });
      try {
        if (ga4 instanceof Object) {
          // 페이지 진입 이벤트
          ga4.gtag("event", "page_view", {
            page_title: data?.title + "강의 상세 페이지",
            page_location: window.location.href,
            page_path: window.location.pathname,
          });
        }
      } catch (err) {
        console.error(`ga Error`, err);
      }
    });
  }, [curriculumId]);

  return (
    <main id="wrap">
      <article>
        <section className="detail-title bg-image1">
          <div
            className="title-box aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h5>{lectureData?.type}</h5>
            <h1>{lectureData?.title}</h1>
            <div className="caption">{lectureData?.description}</div>
            {!isPurchase && (
              <div className="pay-box">
                <div className="price">
                  <span className="discount">
                    <span>{lectureData?.origin_price?.toLocaleString()}</span>원
                  </span>
                  <span className="text-white">
                    {lectureData?.price?.toLocaleString()}
                  </span>
                  원
                </div>
                <div className="col-md-4 mx-auto">
                  <Link
                    to={videoPages.payment + "/" + curriculumId}
                    className="btn btn-lg btn-white-glass text-primary w-100"
                  >
                    강의 구매하기
                  </Link>
                </div>
                {bannerFreeButtonParams && (
                  <div className="col-md-4 mx-auto mt-4">
                    <Link
                      to={bannerFreeUrl}
                      className="btn btn-lg btn-outline text-white w-100"
                    >
                      무료체험
                    </Link>
                  </div>
                )}
              </div>
            )}
            {isPurchase && (
              <div className="pay-box">
                <div className="col-md-4 mx-auto">
                  <Link
                    to={lastVideoId ? lastVideoUrl : bannerFreeUrl}
                    className="btn btn-lg btn-white-glass text-primary w-100"
                  >
                    {lastVideoId ? "이어듣기" : "시작하기"}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
        <div className="pay-box-bottom sm-view">
          <div className="d-flex flex-row">
            <div className="price flex-1">
              <span className="discount">
                <span>{lectureData?.origin_price}</span>원
              </span>
              <div>
                <span className="text-white">{lectureData?.price}</span>원
              </div>
            </div>
            <Link
              to={pages.payment + "/" + curriculumId}
              className="btn flex-1 btn-lg btn-primary w-100"
            >
              구매하기
            </Link>
          </div>
        </div>
        <section className="bg-primary py-120">
          <div className="col-md-2 col-6 mx-auto pb-3">
            <img src="/images/ddusul/3d-checklist.png" className="w-100" />
          </div>
          <div className="container page">
            <div className="text-white text-center  mb-4 mt-4">
              <h4 className="fw-normal">이 강의가 특별한 이유</h4>
              <h1>수강 완료 후 당신의 모습</h1>
            </div>
            <div className="pt-5">
              <ul
                className="shadow-box aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {lectureData?.explain.split(",").map((text, index) => {
                  if (index === lectureData?.explain.split(",").length - 1) {
                    return "";
                  }
                  return (
                    <li>
                      <span>
                        {text}
                        {/* <strong>계약내역서로 변환하여 작성가능</strong> */}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>
        <section className="bg-light py-120">
          <div className="container page">
            <div className="text-dark text-center mb-7 mt-4">
              <h4 className="fw-normal text-primary">강의 추천</h4>
              <h1 className="">이런분들에게 추천 드립니다.</h1>
            </div>
            <div
              className="row-content justify-content-between gap-4 aos-init"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {Data.RecommendationTitle.map((item, index) => {
                return (
                  <div className="flex-1 text-center round-box">
                    <div className="mb-4 text-primary">
                      <span className="material-symbols-outlined fs-1">
                        {index === 0
                          ? "pending"
                          : index === 1
                          ? "edit_document"
                          : "front_hand"}
                      </span>
                    </div>
                    <h3 className="text-dark mb-3">{item.title}</h3>
                    <div>{item.subTitle}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {bestReviews.length > 0 ? (
          <section className="bg-white py-120">
            <div className="container page">
              <div className="text-dark text-center mb-7 mt-4">
                <h4 className="fw-normal text-primary">고객의 평가로 증명된</h4>
                <h1 className="text-dark">뚜껑설계 강의&amp;서비스 만족도</h1>
              </div>
              <div className="review-wrap">
                {bestReviews.map((review: ReviewModel, index) => {
                  return (
                    <div className="review-item" key={review.id}>
                      <div className="name">
                        <div>
                          <div className="mb-2 fw-bold text-dark">
                            {review.user__last_name} 님
                          </div>
                          <div className="rating">
                            <span>{review.rating}</span>
                            <div className="star-wrap">
                              {Array.from({ length: review.rating }).map(
                                (_, index) => (
                                  <span></span>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="txt">{review.review}</div>
                    </div>
                  );
                })}
                {etcReviews.length > 0 ? (
                  <>
                    {!isEtcReviewView ? (
                      <div
                        style={{ cursor: "pointer" }}
                        className="text-end cursor"
                        onClick={() => {
                          setIsEtcReviewView(true);
                        }}
                      >
                        더 보기
                      </div>
                    ) : (
                      etcReviews.map((review: ReviewModel, index) => {
                        return (
                          <div className="review-item" key={review.id}>
                            <div className="name">
                              <div>
                                <div className="mb-2 fw-bold text-dark">
                                  {review.user__last_name} 님
                                </div>
                                <div className="rating">
                                  <span>{review.rating}</span>
                                  <div className="star-wrap">
                                    {Array.from({ length: review.rating }).map(
                                      (_, index) => (
                                        <span></span>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="txt">{review.review}</div>
                          </div>
                        );
                      })
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        <Curriculum
          isPurchase={isPurchase}
          paymentUrl={paymentUrl}
          hour={lectureData?.hour}
          loginAlert={loginAlert}
        />
      </article>
    </main>
  );
};

export default LectureContent;
