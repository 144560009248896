import React, { useContext, useEffect, useState } from "react";
import "./VideoPayment.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import arrow from "../../../../assets/img/arrow.png";
import {
  CreditCardOutlined,
  DownOutlined,
  DollarCircleOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import AlertContext from "../../../../contexts/AlertContext";
import DupLoginContext from "../../../../contexts/DupLoginContext";
import {
  IamportPaymentRequestModel,
  IamportPayMethod,
  ImpCallbackResponseModel,
} from "../../../../models/payment/iamport/ImpModels";
import { api } from "../../../../config/api";
import { util } from "../../../../config/util";
import { Cookies } from "../../../../constants/Cookies";
import { Imp } from "../../../../models/payment/iamport/IMP";
import GlobalEnvironments from "../../../../modules/globalEnvironment/GlobalEnvironment";
import { pages, videoPages } from "../../../../constants/PagePaths";
import { UserInfoModel } from "../../../../models/user/UserInfo";
import { videoApi } from "../../../../config/videoApi";
import CouponModal from "./components/CouponModal";

const VideoPayment = () => {
  const { id } = useParams() as any;
  const [beforeAmount, setBeforeAmount] = useState<number>();
  const [totalAmount, setTotalAmount] = useState<number>();
  const [courseTitle, setCourseTitle] = useState();
  const [course, setCourse] = useState<any>();
  const history = useHistory();
  const alertContext = useContext(AlertContext);
  const [selectedPayMethod, setSelectedPayMethod] =
    useState<IamportPayMethod>();
  const [vbankInfo, setVbankInfo] = useState<any>();
  const dupLogin = useContext(DupLoginContext);
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<UserInfoModel>();

  useEffect(() => {
    new Promise(async () => {
      const res = await videoApi.getCoursePaymentInfoAsync(id);
      const cardRes = await videoApi.getCourseListAsync();
      const data = cardRes.data.courses.filter((card: any) => card.id == id)[0];
      setCourse(data);
      if (res.status === 204) {
        await alertContext?.showAlert("잘못된 링크입니다.");
        history.replace(videoPages.index);
        return;
      }
      const course = res?.data;
      if (course.isPurchased) {
        await alertContext?.showAlert("이미 구매한 강의입니다.");
        history.replace(videoPages.index);
      }
      setBeforeAmount(course?.originAmount);
      setTotalAmount(course?.amount);
      setCourseTitle(course?.title);
      const key = util.getCookie(Cookies.authKey);
      if (key) {
        const info = await dupLogin.checkDupLogin(() => api.getMyInfo());
        setUserInfo(info?.data);
        if (!info) {
          return;
        }
      } else {
        return;
      }
    });
  }, []);

  const startPayment = async () => {
    // 모바일에서는 알림을 띄우고 결제 진행 안함
    const isMobile = () => {
      const filter = "win16|win32|win64|mac|macintel";
      if (navigator.platform) {
        if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
          return true;
        } else {
          return false;
        }
      }
    };
    if (isMobile()) {
      alertContext.showAlert(
        "모바일에서는 결제가 불가능합니다. PC로 접속해주세요."
      );
      return;
    }
    let totalPrice = totalAmount;
    try {
      var date = new Date();
      date.setDate(date.getDate() + 3);
      const noticeUrls = [`${process.env.REACT_APP_API_URL}video/approval/`];
      if (GlobalEnvironments.getIsSendLocalNotice()) {
        noticeUrls.push(
          "https://b275-115-92-180-68.ngrok-free.app/video/approval/"
        );
      }
      const requestData: IamportPaymentRequestModel = {
        pg: "html5_inicis",
        amount: totalPrice ?? 0,
        buyer_tel: userInfo?.phone || "",
        pay_method: selectedPayMethod || IamportPayMethod.default,
        merchant_uid: `mid_${new Date().getTime()}`,
        name: courseTitle,
        buyer_name: userInfo?.last_name || "",
        buyer_addr: userInfo?.addr || "",
        buyer_email: userInfo?.email || "",
        buyer_postcode: userInfo?.post_code || "",
        custom_data: {
          course_ids: [id],
        },
        vbank_due: date.format("yyyyMMdd"),
        notice_url: noticeUrls,
        display: {
          card_quota: [1, 2, 3, 4, 5, 6],
        },
      };
      Imp.requestPay(requestData, handleRequestPaymentCallback);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRequestPaymentCallback = async (
    res: ImpCallbackResponseModel
  ) => {
    try {
      if (res?.success === false || res?.imp_success === false) {
        if (res.error_msg?.includes("취소")) {
          alertContext.showAlert("사용자의 요청으로 취소되었습니다.");
          return;
        }
        if (res.error_msg == undefined) {
          alertContext.showAlert(
            "결제가 정상적으로 완료되지 않았습니다.\n우측하단 고객센터를 통해 문의 바랍니다."
          );
        } else {
          alertContext.showAlert(res.error_msg);
        }
        return;
      }
      const completeRes = await dupLogin.checkDupLogin(() =>
        videoApi.postCoursePaymentCompleteAsync(res)
      );
      if (completeRes?.status === 200) {
        if ((selectedPayMethod || res.pay_method) === IamportPayMethod.card) {
          alertContext.showAlert("결제가 완료되었습니다.", () => {
            history.push(videoPages.mypage);
          });
        } else {
          setVbankInfo({
            amount: res.paid_amount,
            prodName: res.name,
            vbankDate: new Date(completeRes?.data.vbank_date * 1000).format(
              "yyyy년 MM월 dd일"
            ),
            vbankName: completeRes?.data.vbank_name,
            vbankNum: completeRes?.data.vbank_num,
            name: res.buyer_name,
            email: res.buyer_email,
            phone: res.buyer_tel,
            companyName: res.vbank_holder,
          });
          alertContext.showAlert(
            "가상계좌 정보는 마이페이지 결제이력의\n'계좌 확인'을 통해 확인해주세요.",
            () => {
              history.push(videoPages.mypage);
            }
          );
        }
      } else if ([400, 500].includes(completeRes?.status)) {
        // 결제 실패. custom_data가 안가서 결제검증을 진행할 수 없음.
        history.push(`${videoPages.payment}/${id}`);
        alertContext.showAlert(
          "결제 중 오류가 발생하였습니다.\n고객센터를 통해 문의 부탁드립니다."
        );
      }
    } catch (error) {
      history.push(`${videoPages.payment}/${id}`);
      alertContext.showAlert(
        "상품등록 중 오류가 발생하였습니다.\n고객센터를 통해 문의 부탁드립니다."
      );
      console.error(error);
    }
  };

  const handleClickPayMethod = (method: IamportPayMethod) => {
    setSelectedPayMethod(method);
  };

  const handlePaymentClick = async () => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      await alertContext.showAlert("로그인 후 이용해주세요.");
      history.push(pages.login);
      return;
    } else {
      const res = await dupLogin.checkDupLogin(() => api.getMyInfo());
      if (!res || res?.status === 401) {
        return;
      }
    }
    startPayment();
  };
  return (
    <main id="wrap">
      <article>
        <section className="bg-primary">
          <div className="container content">
            <h3
              className="marketing-title white pb-3 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              강의 결제하기
            </h3>
            <div
              className="flex-card aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="edu-content">
                <div className=" rowflex">
                  <div
                    className="card pointer mb-0"
                    onClick={() => {
                      history.push(videoPages.curriculum + "/" + course.id);
                    }}
                  >
                    <img
                      src={course?.card_image}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">{course?.title}</h5>
                      <div className="body2 mb-2">{course?.description}</div>
                      <div className="card-text mb-2">
                        <div className="card-caption">
                          <div className="price">
                            <span className="discount">
                              <span>
                                {course?.origin_amount?.toLocaleString()}
                              </span>
                              원
                            </span>
                            <span>{course?.amount?.toLocaleString()}</span>원
                          </div>
                        </div>
                      </div>
                      <div className="badge-wrap">
                        {/* <span className="badge rounded-pill text-bg-primary-soft">
                          명
                        </span> */}
                        <span className="badge rounded-pill text-bg-primary">
                          {course?.service_product}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edu-title">
                <h3>
                  이용기간 : <span>3개월</span>
                </h3>
                <div className="text-gray-300">
                  ※ 모든 강의는 3개월 이용입니다.
                </div>
                <div className="edu-pay">
                  카드 &amp; 간편결제, 무통장 입금, 실시간 계좌이체 중 원하시는
                  결제 방법을 선택해주세요.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-50">
          <div className="container content">
            <div className="marketing-page">
              <h3
                className="marketing-title pb-3 aos-init"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
              >
                <span>결제방법</span>을 선택해주세요.
              </h3>
            </div>
            <div className="pay-card">
              <div
                className="card-item-wrap"
                onClick={() => {
                  handleClickPayMethod(IamportPayMethod.card);
                }}
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="options-pay"
                  id="btn-check-pay"
                  autoComplete="off"
                />
                <label className="btnbox-primary" htmlFor="btn-check-pay">
                  <div className="pay-card-item">
                    <div className="pay-title">
                      <span className="material-symbols-outlined me-2">
                        credit_card
                      </span>
                      카드 &amp; 간편결제
                    </div>
                    <div className="pay-comment">
                      구매하신 이용권의 가격은{" "}
                      <span>{course?.amount?.toLocaleString()}</span>원 이며,
                      카드 &amp; 간편결제로 결제를 진행합니다.
                    </div>
                  </div>
                </label>
              </div>
              <div
                className="card-item-wrap"
                onClick={() => {
                  handleClickPayMethod(IamportPayMethod.vbank);
                }}
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="options-pay"
                  id="btn-check-pay2"
                  autoComplete="off"
                />
                <label className="btnbox-primary" htmlFor="btn-check-pay2">
                  <div className="pay-card-item">
                    <div className="pay-title">
                      <span className="material-symbols-outlined me-2">
                        savings
                      </span>
                      무통장 입금
                    </div>
                    <div className="pay-comment">
                      구매하신 이용권의 가격은{" "}
                      <span>{course?.amount?.toLocaleString()}</span>원 이며,
                      무통장 입금으로 결제를 진행합니다.
                    </div>
                  </div>
                </label>
              </div>
              <div
                className="card-item-wrap"
                onClick={() => {
                  handleClickPayMethod(IamportPayMethod.trans);
                }}
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="options-pay"
                  id="btn-check-pay3"
                  autoComplete="off"
                />
                <label className="btnbox-primary" htmlFor="btn-check-pay3">
                  <div className="pay-card-item">
                    <div className="pay-title">
                      <span className="material-symbols-outlined me-2">
                        account_balance
                      </span>
                      실시간 계좌이체
                    </div>
                    <div className="pay-comment">
                      구매하신 이용권의 가격은{" "}
                      <span>{course?.amount?.toLocaleString()}</span>원 이며,
                      실시간 계좌이체로 결제를 진행합니다.
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </section>
        <div className="payment-bottom">
          <div className="container">
            <div className="payment-bottom-wrap">
              <div className="pay-price">
                <div>
                  <span className="material-symbols-outlined">
                    shopping_bag
                  </span>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <div className="price-sale">
                    <span>{course?.origin_amount?.toLocaleString()}</span>원
                  </div>
                  <div className="price">
                    <span>{course?.amount?.toLocaleString()}</span>원
                  </div>
                </div>
              </div>
              <div className="payment-bottom-btn">
                {/* <button
                  type="button"
                  className="btn btn-white btn-lg left-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#CouponModal"
                >
                  <span className="material-symbols-outlined me-2">
                    confirmation_number
                  </span>
                  쿠폰 사용
                </button> */}
                <button
                  type="button"
                  className="btn btn-primary btn-lg left-icon"
                  onClick={handlePaymentClick}
                >
                  <span className="material-symbols-outlined me-2">paid</span>
                  결제하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
      <CouponModal />
    </main>
  );
};

export default VideoPayment;
