/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../../config/api";
import { util } from "../../../config/util";
import { Cookies } from "../../../constants/Cookies";
import { pages } from "../../../constants/PagePaths";
import AlertContext from "../../../contexts/AlertContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import {
  SurveyData,
  SurveySectionModel,
} from "../../../models/requestApplication/RequestApplicationModel";
import ServiceRequestDocPresenter from "./ServiceRequestDocPresenter";
import ServiceRequestDocPresenter_2 from "./ServiceRequestDocPresenter_2";

interface Props {}

export default (props: Props) => {
  const formData = useRef<{
    [key: string]: { [key: string]: string | string[] };
  }>({});
  const formFileData = useRef<{
    [key: string]: { [key: string]: File[] };
  }>({});
  const loading = useContext(LoadingContext);
  const alertContext = useContext(AlertContext);
  const [selectedSurveySection, setSelectedSurveySection] =
    useState<SurveySectionModel>(SurveyData);
  const [nextSection, setNextSection] = useState<SurveySectionModel>();
  const prevSection = useRef<SurveySectionModel[]>([]);

  const formRef = useRef<HTMLFormElement>(null);
  const history = useHistory();

  useEffect(() => {
    for (let i = 0; i < SurveyData.questions.length; i++) {
      const question = SurveyData.questions[i];
      // quest options 예외처리
      if (!question.options) {
        continue;
      }
      let option;
      for (let j = 0; j < question.options.length ?? 0; j++) {
        option = question.options[j];
        if (option.childSection) {
          setNextSection(option.childSection);
          break;
        }
      }
      if (option) {
        break;
      }
    }
  }, []);

  const handleOptionChange = (
    sectionTitle: string,
    questionTitle: string,
    changedValue: string | string[]
  ): void => {
    // title로 구분된 dictionary formData에 value 저장
    if (!formData.current[sectionTitle]) {
      formData.current[sectionTitle] = {};
    }
    formData.current[sectionTitle][questionTitle] = changedValue;
  };

  const handleNextSectionChange = (section: SurveySectionModel): void => {
    setNextSection(section);
  };

  const handleMoveClick = (type: "next" | "prev") => {
    if (type === "prev") {
      if (prevSection.current.length > 0) {
        const popSection = prevSection.current.pop();
        setSelectedSurveySection(popSection!);
      }
    } else if (type === "next") {
      if (!formRef.current?.reportValidity()) {
        return;
      }
      if (nextSection) {
        prevSection.current.push(selectedSurveySection);
        setSelectedSurveySection(nextSection);
      }
    }
    // scroll to top
    window.scrollTo(0, 0);
    // history.push("/documentreview2");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formRef.current?.reportValidity()) {
      return;
    }

    loading.showLoading();
    // filedata를 순회하면서 aws에 업로드하고 파일 링크를 받아와서 formData에 저장
    for (let sectionKey of Object.keys(formFileData.current)) {
      for (let questionKey of Object.keys(formFileData.current[sectionKey])) {
        const files: string[] = [];
        for (let file of formFileData.current[sectionKey][questionKey]) {
          // aws에 업로드
          const res = await api.uploadFileAsync(file).then((res) => res);
          files.push(res.data.url);
        }
        // formData에 저장
        if (!formData.current[sectionKey]) {
          formData.current[sectionKey] = {};
        }
        if (!formData.current[sectionKey][questionKey]) {
          formData.current[sectionKey][questionKey] = [];
        }
        formData.current[sectionKey][questionKey] = files;
      }
    }

    loading.closeLoading();
    await api.postServiceRequestDoc(formData.current);
    alertContext.showAlert("요청이 완료되었습니다.", () => {
      const key = util.getCookie(Cookies.authKey);
      if (key) {
        history.push(pages.mypagecontact);
      } else {
        history.push(pages.index);
      }
    });
  };

  const handleFileChange = (
    sectionTitle: string,
    questionTitle: string,
    files: File[]
  ) => {
    if (!formFileData.current[sectionTitle]) {
      formFileData.current[sectionTitle] = {};
    }
    formFileData.current[sectionTitle][questionTitle] = files;
  };

  return (
    <ServiceRequestDocPresenter
      surveyModel={selectedSurveySection}
      currentFormData={formData.current}
      currentFormFileData={formFileData.current}
      isSubmitable={!selectedSurveySection.isHasChild}
      formRef={formRef}
      onChangeOption={handleOptionChange}
      onChangeNextSection={handleNextSectionChange}
      onClickMove={handleMoveClick}
      isPrev={prevSection.current.length > 0}
      isNext={nextSection ? true : false}
      onSubmit={handleSubmit}
      onChangeFile={handleFileChange}
    />
  );
};
