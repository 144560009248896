/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";
import CouponBoxModel from "../../../models/coupon/CouponBoxModel";
import CouponList from "../../coupon/CouponList";

interface Props {
  coupons: CouponBoxModel[];
  onConfirm: () => void;
}

export default (props: Props) => {
  return (
    <Container>
      <HeaderWrap>
        <Title>쿠폰 내역</Title>
        <CouponList coupons={props.coupons} />
      </HeaderWrap>
      <Button onClick={() => props.onConfirm()}>확인</Button>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  min-height: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const Title = styled.div`
  margin: 10px auto;
  font-size: 18px;
  font-weight: bold;
`;
const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;
const Button = styled.button`
  margin: 0 auto;
  margin-bottom: 10px;
  width: 285px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 6px;
  background-color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
  height: 38px;
  border: none;
  margin-top: 20px;
`;
