export default () => {
  return (
    <section className="page-top-banner-three">
      <div className="container">
        <div className="text-box">
          <div>
            의뢰 신청이 완료되면 담당자가 내용을 확인한 후 연락을 드립니다.
          </div>
          <h2>용역 의뢰 신청서</h2>
        </div>
      </div>
    </section>
  );
};
