import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function SafetyHealthStatement(props) {
  const worklist = props.worklist;
  if (!worklist?.safetyManageState) {
    return <div>로딩중...</div>
  }
  const safetyManageState = worklist.safetyManageState.usageStateItems;
  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }
  let totalPrice = 0;
  for (let i = 0; i < safetyManageState?.length; i++) {
    totalPrice += safetyManageState[i].totalPrice;
  }
  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <div style={{ fontSize: "40px", textAlign: "center", borderBottom: "hidden", fontWeight: "600" }}>산업안전보건관리비 사용내역서</div>
          <div style={{ height: "50px", lineHeight: "50px", textAlign: "right", fontSize: "15px" }}>
            {
              moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                ? '　'
                : moment(worklist?.completionDate).format('yyyy년　MM월')
            }
          </div>
          <table style={{ textAlign: "center" }}>
            <tr style={{ height: "40px" }}>
              <td style={{ width: "100px", letterSpacing: "2px" }}>건설업체명</td>
              <td style={{ width: "220px" }}>{worklist?.companyName}</td>
              <td style={{ width: "100px", letterSpacing: "16px", paddingLeft: "12px" }}>공사명</td>
              <td style={{ width: "220px", wordBreak: "break-all", wordWrap:"word-break" }}>{worklist?.constructionName}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>소재지</td>
              <td style={{ wordBreak: "keep-all" }}>{worklist?.businessAddress}</td>
              <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>대표자</td>
              <td style={{ letterSpacing: "5px" }}>{worklist?.representativeName}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>공사금액</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>￦ {props.commaContractPrice}　원</td>
              <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>공사기간</td>
              <td>
                {
                  moment(worklist?.beginDate).format('yyyy년　MM월　DD일') === 'Invalid date' && moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : `${moment(worklist?.beginDate).format('yyyy.MM.DD')} ~ ${moment(worklist?.completionDate).format('yyyy.MM.DD')}`
                }
              </td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>발주처</td>
              <td>{worklist?.orderOrganization}</td>
              <td style={{ letterSpacing: "2px" }}>누계공정율</td>
              <td>100%</td>
            </tr>
            <tr style={{ height: "40px", borderBottom: "hidden" }}>
              <td><div style={{ letterSpacing: "16px", paddingLeft: "12px" }}>계상된</div><div style={{ letterSpacing: "2px" }}>안전관리비</div></td>
              <td colSpan="3">{`${Number(worklist?.expensedSafetyStateCost).toLocaleString()} 원`}</td>
            </tr>
          </table>
          <table style={{ tableLayout: "fixed" }}>
            <tr style={{ height: "40px", textAlign: "center" }}>
              <td colSpan="4" style={{ letterSpacing: "30px" }}>사용금액</td>
            </tr>
            <tr style={{ height: "40px", textAlign: "center" }}>
              <td colSpan="2" style={{ letterSpacing: "30px", paddingLeft: "30px" }}>항목</td>
              <td>(
                {
                  moment(worklist?.beginDate).format('yyyy년 MM월 DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.beginDate).format('M')
                }

                ) 월 사용금액</td>
              <td>누계사용금액</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ textAlign: "center" }}>계</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{totalPrice === 0 ? '-' : totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{totalPrice === 0 ? '-' : totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>1. 안전관리자 등 인건비 및 각종 업무수당 등</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[0].totalPrice === 0 ? '-' : safetyManageState[0].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[0].totalPrice === 0 ? '-' : safetyManageState[0].totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>2. 안전시설비 등</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[1].totalPrice === 0 ? '-' : safetyManageState[1].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[1].totalPrice === 0 ? '-' : safetyManageState[1].totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>3. 개인보호구 및 안전장구 구입비 등</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[2].totalPrice === 0 ? '-' : safetyManageState[2].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[2].totalPrice === 0 ? '-' : safetyManageState[2].totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>4. 안전진단비 등</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[3].totalPrice === 0 ? '-' : safetyManageState[3].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[3].totalPrice === 0 ? '-' : safetyManageState[3].totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>5. 안전보건교육비 및 행사비 등</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[4].totalPrice === 0 ? '-' : safetyManageState[4].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[4].totalPrice === 0 ? '-' : safetyManageState[4].totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>6. 근로자 건강관리비 등</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[5].totalPrice === 0 ? '-' : safetyManageState[5].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[5].totalPrice === 0 ? '-' : safetyManageState[5].totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>7. 건설재해예방 기술지도비</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[6].totalPrice === 0 ? '-' : safetyManageState[6].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[6].totalPrice === 0 ? '-' : safetyManageState[6].totalPrice.toLocaleString()}</td>
            </tr>
            <tr style={{ height: "40px" }}>
              <td colSpan="2" style={{ paddingLeft: "5px" }}>8. 본사사용비</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[7].totalPrice === 0 ? '-' : safetyManageState[7].totalPrice.toLocaleString()}</td>
              <td style={{ textAlign: "right", paddingRight: "5px" }}>{safetyManageState[7].totalPrice === 0 ? '-' : safetyManageState[7].totalPrice.toLocaleString()}</td>
            </tr>
          </table>
          <div style={{ height: "60px", wordBreak: "keep-all", marginTop: "20px", marginLeft: "45px" }}>「 건설업 산업안전보건관리비 계상 및 사용기준 」 제 10조 제 1항에 따라 위와 같이 사용계획서를 작성하였습니다.</div>
          <div style={{ textAlign: "center", height: "50px", lineHeight: "50px", marginBottom: "10px", fontSize: "15px" }}>
            {
              moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                ? '　'
                : moment(worklist?.completionDate).format('yyyy년　MM월　DD일')
            }
          </div>
          <table style={{ border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ width: "200px", borderRight: "hidden", height: "40px" }}><span style={{ letterSpacing: "15px" }}>작성자 : </span>직책</td>
              <td style={{ width: "150px", borderRight: "hidden" }}>　</td>
              <td style={{ width: "50px", borderRight: "hidden" }}>성명</td>
              <td style={{ width: "142px", borderRight: "hidden" }}>　</td>
              <td style={{ width: "100px" }}>(서명 또는 인)</td>
            </tr>
            <tr>
              <td style={{ borderRight: "hidden" }}><span style={{ letterSpacing: "15px" }}>확인자 : </span>직책</td>
              <td style={{ borderRight: "hidden" }}>　</td>
              <td style={{ borderRight: "hidden" }}>성명</td>
              <td style={{ borderRight: "hidden" }}>　</td>
              <td>(서명 또는 인)</td>
            </tr>
          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default SafetyHealthStatement;