import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './PostForm.module.scss';
import { api } from '../../config/api';

interface PostFormProps {
  onSubmit: (post: any) => void;
  initialValues?: any;
  isEditing?: boolean;
  category: number;
}

const PostForm: React.FC<PostFormProps> = ({ onSubmit, initialValues, isEditing = false, category }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setFileList(initialValues.files?.map((file: any, index: number) => ({
        uid: `-${index}`,
        name: file.fileName,
        status: 'done',
        url: file.fileUrl,
      })) || []);
    }
  }, [initialValues, form]);

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0];
      if (file) {
        try {
          // 이미지 업로드 API 호출
          const response = await api.uploadFileAsync(file, 'community');
          const imageUrl = response.data.url;

          // 에디터에 이미지 삽입
          const quill = document.querySelector('.ql-editor');
          const range = document.getSelection()?.getRangeAt(0);
          if (quill && range) {
            const img = document.createElement('img');
            img.src = imageUrl;
            range.insertNode(img);
          }
        } catch (error) {
          console.error('이미지 업로드 실패:', error);
          message.error('이미지 업로드에 실패했습니다.');
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    },
    clipboard: {
      matchVisual: false,
      allowed: {
        tags: ['img', 'p', 'br', 'strong', 'em', 'span'],
        attributes: ['src', 'alt']
      }
    }
  }), []);

  const handleSubmit = (values: any) => {
    const postData = new FormData();
    postData.append('title', values.title);
    postData.append('content', values.content);
    postData.append('category', category.toString());

    const attachments: string[] = [];

    fileList.forEach((file, index) => {
      if (file.originFileObj) {
        postData.append('files', file.originFileObj);
      } else {
        attachments.push(file.url);
      }
    });

    postData.append('attachments', JSON.stringify(attachments));

    onSubmit(postData);
    if (!isEditing) {
      form.resetFields();
      setFileList([]);
    }
  };

  const handleUpload = ({ file, onSuccess }: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const url = reader.result as string;
      onSuccess(url, file);
    };
  };

  const beforeUpload = (file: File) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('파일 크기는 10MB 이하여야 합니다!');
    }
    return isLt10M;
  };

  return (
    <div className={styles.postFormContainer}>
      <Form form={form} onFinish={handleSubmit} layout="vertical" className={styles.postForm}>
        <Form.Item name="title" label="제목" rules={[{ required: true, message: '제목을 입력해주세요' }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item name="content" label="내용" rules={[{ required: true, message: '내용을 입력해주세요' }]}>
          <ReactQuill 
            theme="snow" 
            style={{ height: '300px', marginBottom: '50px' }}
            modules={modules}
          />
        </Form.Item>
        <Form.Item name="attachments" label="파일 첨부">
          <Upload.Dragger
            customRequest={handleUpload}
            onChange={({ fileList }) => setFileList(fileList)}
            multiple
            listType="picture"
            fileList={fileList}
            beforeUpload={beforeUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">클릭하거나 파일을 이 영역으로 드래그하세요</p>
            <p className="ant-upload-hint">
              모든 종류의 파일을 업로드할 수 있습니다. 파일 크기는 10MB 이하여야 합니다.
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            {isEditing ? '수정' : '등록'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PostForm;
