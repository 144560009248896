/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import PhotoList2TablePresenter from "./PhotoList2TablePresenter";
import { Worklist } from "../../../../models/worklist/WorklistModel";
import { PhotoListData2 } from "../../../../constants/PhotoListData";

interface Props {
  worklist: Worklist;
  images: any;
  putWorkListAsync: (data: any) => void;
  postWorklistImage: (result: any, num: number, sub_num: number) => void;
  deleteWorklistImage: (id:number) => void;
}

export default (props: Props) => {
  const worklist = props.worklist;
  const images = props.images;
  const putWorkListAsync = props.putWorkListAsync;
  const postWorklistImage = props.postWorklistImage;
  const deleteWorklistImage = props.deleteWorklistImage;

  const imagesData = {
    photoListItems: images?.length === 0 ? PhotoListData2.photoListItems : images,
  } 

  return (
    <div>
      <PhotoList2TablePresenter
        worklist={worklist}
        model={imagesData}
        putWorkListAsync={putWorkListAsync} 
        postWorklistImage={postWorklistImage}
        deleteWorklistImage={deleteWorklistImage}
      />
    </div>
  );
};
