import { SafetyManagePlanModel } from "../models/beginConstDoc/SafetyManagePlanModel";
import { SafetyManageStateModel } from "../models/completionConstDoc/SafetyManageStateModel";

export const SafetyManagementItemData: SafetyManagePlanModel = {
  usagePlanItems: [
    {
      itemName: "안전관리자 등 인건비 및 각종 업무 수당 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "안전시설비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "개인보호구 및 안전장구 구입비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "안전진단비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "안전보건교육비 행사비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "근로자 건강관리비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "건설재해예방 기술지도비",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "본사사용비",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
  ]
}

export const SafetyManagementStateItemData: SafetyManageStateModel = {
  usageStateItems: [
    {
      itemName: "안전관리자 등 인건비 및 각종 업무 수당 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "안전시설비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "개인보호구 및 안전장구 구입비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "안전진단비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "안전보건교육비 행사비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "근로자 건강관리비 등",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "건설재해예방 기술지도비",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
    {
      itemName: "본사사용비",
      totalPrice: 0,
      usageItems: [{
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }, {
        usageDate: '2021-01-01',
        usageItemPrice: 0,
        productName: '',
        productNum: 0,
        productPrice: 0,
        commaProductPrice: '',
        calPrice: 0,
      }]
    },
  ]
}