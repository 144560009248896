export default (props: { previous: () => void; next: () => void }) => {
  return (
    <div className="mo-fixed-bottom w-100 d-flex gap-3 py-3">
      <a
        onClick={() => {
          props.previous();
        }}
        className="btn btn-lg btn-light flex-1"
      >
        <span className="material-symbols-outlined me-2">arrow_left_alt</span>
        <span className="pe-3">이전</span>
      </a>
      <a
        onClick={() => {
          props.next();
        }}
        className="btn btn-lg btn-primary-soft flex-1"
      >
        <span className="ps-3">다음</span>
        <span className="material-symbols-outlined ms-2">arrow_right_alt</span>
      </a>
    </div>
  );
};
