export class Project {
  id: number;
  name: string;
  companyName: string;
  clientName: string;
  userId: number;
  lastView?: Date;
  created: Date;
  startDate?: Date;
  endDate?: Date;
  type: string;
  subTypes: Array<any> = [];
  contract: boolean = false;
  change: boolean = false;
  ready: boolean = false;
  complete: boolean = false;
  isFavorite?: boolean = false;
  corporationType?: string;
  memo?: string;

  constructor(args: any) {
    this.id = args.id;
    this.name = args.name;
    this.companyName = args.company_name;
    this.clientName = args.client_name;
    this.userId = args.user_id;
    this.lastView = args.last_view ? new Date(args.last_view) : undefined;
    this.startDate = args.start_date ? new Date(args.start_date) : undefined;
    this.endDate = args.end_date ? new Date(args.end_date) : undefined;
    this.type = args.type;
    this.corporationType = args.corporation_type;
    this.isFavorite = args.is_favorite;
    this.memo = args.memo;
    this.created = new Date(args.created);
    if (args.sub_types) {
      const subTypes = JSON.parse(args.sub_types);
      this.subTypes = subTypes;
      for (const sub of subTypes) {
        switch (sub) {
          case "contract":
            this.contract = true;
            break;
          case "change":
            this.change = true;
            break;
          case "ready":
            this.ready = true;
            break;
          case "complete":
            this.complete = true;
            break;
        }
      }
    }
  }
}

export class Notice {
  id: number;
  title: string;
  text: string;
  visible: boolean;
  date: Date;
  created: Date;
  tag?: string;

  constructor(args?: {
    id: number;
    title: string;
    text: string;
    visible: boolean;
    date: Date;
    created: Date;
    tag: string;
  }) {
    if (args) {
      this.id = args.id;
      this.title = args.title;
      this.visible = args.visible;
      this.text = args.text;
      this.date = new Date(args.date);
      this.created = new Date(args.created);
      this.tag = args.tag;
    } else {
      this.id = undefined!;
      this.title = undefined!;
      this.visible = undefined!;
      this.text = undefined!;
      this.date = undefined!;
      this.created = undefined!;
    }
  }
}
