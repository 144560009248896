/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styles from "./lectureRoom.module.scss";
import { LectureItem } from "../../models/LectureModel";
import { YouTube } from "@material-ui/icons";
import { CategoryItem } from "../../models/CategoryItem";

interface Props {
  categories?: CategoryItem[];
  lectures?: LectureItem[];
  selectedCategoryIndex: number;
  onClickPlay: (index: number) => void;
}

export default (props: Props) => {
  return (
    <React.Fragment>
      <div className={styles.lecture_contents_wrapper}>
        <div className={styles.category_title}>
          {props.categories?.[props.selectedCategoryIndex]?.title}
        </div>
      </div>
      <div className={styles.lecture_contents}>
        {props.lectures?.map((lecture, index) => {
          return (
            <div
              key={index}
              className={`${styles.lecture_content_wrapper} ${
                index !== (props.lectures?.length || 0) - 1 &&
                styles.has_bottom_border
              }`}
            >
              <div
                className={styles.img_wrapper}
                onClick={() => props.onClickPlay(lecture.id)}
              >
                <img
                  alt=""
                  src={
                    lecture.thumbnailUrl ||
                    `https://img.youtube.com/vi/${lecture.link}/0.jpg`
                  }
                />
              </div>
              <div
                className={`${styles.text_wrapper}`}
                onClick={() => props.onClickPlay(lecture.id)}
              >
                <div className={styles.lecture_title}>{lecture.title}</div>
                <div className={styles.lecture_description}>
                  {lecture.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
