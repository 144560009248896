/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import LectureRoomPresenter from "./LectureRoomPresenter";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import AlertContext from "../../contexts/AlertContext";
import { LectureCategoryModel, LectureItem } from "../../models/LectureModel";
import { api } from "../../config/api";
import { CategoryItem } from "../../models/CategoryItem";
import { FreeUseDay } from "../../constants/General";

interface Props {}

export default (props: Props) => {
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [lecturesData, setLecturesData] = useState<LectureItem[]>();
  const [lectures, setLectures] = useState(lecturesData);
  const [lectureCategories, setLectureCategories] = useState<CategoryItem[]>();
  const history = useHistory();
  const location = useLocation();
  const alert = useContext(AlertContext);

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " +
          FreeUseDay +
          "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
    }

    new Promise(async () => {
      const lecturesResponse = await api.getLectureAsync();
      const lecturesData: LectureItem[] = lecturesResponse.data.lectures.map(
        (lecture: any) => {
          return {
            id: lecture.id,
            thumbnailUrl: lecture.thumbnail_url,
            description: lecture.description,
            link: lecture.link,
            created: lecture.created,
            categoryId: lecture.category_id,
            order: lecture.order,
            title: lecture.name,
          };
        }
      );
      setLecturesData(lecturesData);
      const categoryRes = await api.getLectureCategoryAsync();
      const lectureCategories: CategoryItem[] =
        categoryRes.data.lecture_categories.map(
          (lectureCategory: LectureCategoryModel) => {
            return {
              title: lectureCategory.name,
              description: lectureCategory.description,
              id: lectureCategory.id,
            };
          }
        );
      // lectureCategories중에 id가 8인 것을 제일 앞으로 보내기
      const index = lectureCategories.findIndex(
        (category: any) => category.id === 8
      );
      const temp = lectureCategories[0];
      lectureCategories[0] = lectureCategories[index];
      lectureCategories[index] = temp;
      setLectureCategories(lectureCategories);
      const categoryIndex = location.pathname.split("/")[2];
      setSelectedCategoryIndex(+categoryIndex || 0);
      setLectures(
        lecturesData.filter(
          (lecture) =>
            lecture.categoryId === lectureCategories?.[+categoryIndex]?.id
        )
      );
    });
  }, []);

  const handleClickCategory = (index: number) => {
    setSelectedCategoryIndex(index);
    setLectures(
      lecturesData?.filter(
        (lecture) => lecture.categoryId === lectureCategories?.[index]?.id
      )
    );
  };

  const handleClickPlay = (id: number) => {
    history.push(`${pages.lectureroom}/${selectedCategoryIndex}/${id}`);
    refreshLectureList(id);
  };

  const refreshLectureList = (id: number) => {
    setLectures(lecturesData?.filter((lecture) => id < lecture.id).slice(0, 3));
  };

  return (
    <LectureRoomPresenter
      categories={lectureCategories}
      lectures={lectures}
      selectedCategoryIndex={selectedCategoryIndex}
      onClickCategory={handleClickCategory}
      onClickPlay={handleClickPlay}
    />
  );
};
