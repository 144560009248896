import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function ContracteDepositMemo(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td className="doc-title" colspan="4" style={{ fontSize: "40px", textAlign: "center", height: "150px" }}>계약보증금지급각서</td>
            </tr>

            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4">　</td>
            </tr>

            <tr style={{ height: "50px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>○　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>건</span>
                <span>명</span>
              </span>　:
              </td>
              <td style={{ wordBreak: "break-all", wordWrap:"word-break" }} colspan="3">{worklist?.constructionName}</td>
            </tr>

            <tr style={{ height: "50px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>○　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>계</span>
                <span>약</span>
                <span>금</span>
                <span>액</span>
              </span>　:
              </td>
              <td colspan="3">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>

            <tr style={{ height: "50px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>○　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>계</span>
                <span>약</span>
                <span>일</span>
                <span>자</span>
              </span>　:
              </td>
              <td colspan="3">
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>

            <tr style={{ height: "50px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>○　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>계</span>
                <span>약</span>
                <span>이</span>
                <span>행</span>
                <span>기</span>
                <span>간</span>
              </span>　:
              </td>
              <td colspan="3">
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date' & moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : `${moment(worklist?.contractDate).format('yyyy년　MM월　DD일')}　~　${moment(worklist?.completionDate).format('yyyy년　MM월　DD일')}`
                }
              </td>
            </tr>
            <tr style={{ height: "50px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>○　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>계</span>
                <span>약</span>
                <span>보</span>
                <span>증</span>
                <span>금</span>
              </span>　:
              </td>
              <td colspan="3">
                {`일금 ${props.krContractGuaranteePrice}정 (￦${props.commaContractGuaranteePrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ height: "170px", borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px", textIndent: "5px" }} colspan="4">귀청과 상기 계약을 체결함에 있어서 지방자치단체를 당사자로 하는 계약에 관한 법률 제 15조 제 3항 (계약보증금세입조치) 및 동시행령 제 54조 (계약보증금세입조치)의 규정에 의하여 해당 계약보증금이 귀청에 귀속사유가 발생할 때에는 즉시 현금으로 납입할 것을 확약하고, 지방자치단체를 당사자로 하는 계약에 관한 법률 시행령 제 53조 제 2항의 규정 (계약보증금납입확약)에 의거 본 각서를 제출합니다.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "45px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colspan="4" style={{ textAlign: "center", fontSize: "15px" }}>
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "45px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "60px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ fontSize: "20px", height: "30px" }}>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default ContracteDepositMemo;