import { Link } from "react-router-dom";
import { pages } from "../../constants/PagePaths";

export default () => {
  return (
    <main id="wrap">
      <article>
        <section class="bg-gray-50">
          <div class="container content">
            <div class="col-sm-5 col-md-8 col-lg-7 col-xl-6 mx-auto white-box">
              <img
                src="/images/ddusul/3d-check.png"
                data-aos="fade-up"
                data-aos-duration="1000"
                class="aos-init aos-animate"
              />
              <h2 class="mb-6">결제가 완료되었습니다.</h2>
              <div class="bottom-btn-wrap">
                <Link
                  to={pages.mypage + "/payment"}
                  class="btn btn-lg btn-outline flex-1"
                >
                  <span class="material-symbols-outlined me-2">
                    receipt_long
                  </span>
                  결제 내역 확인하기
                </Link>
                <Link to={pages.index} class="btn btn-lg btn-primary flex-1">
                  메인으로 가기
                  <span class="material-symbols-outlined ms-2">
                    arrow_right_alt
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};
