/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { CategoryItem } from "../../models/CategoryItem";
import styles from "./lectureRoom.module.scss";
import LectureRoomContents from "./LectureRoomContents";
import CategorySelector from "../../components/selector/categorySelector/CategorySelector";
import { LectureItem } from "../../models/LectureModel";

interface Props {
  /** 전체 카테고리 리스트 */
  categories?: CategoryItem[];
  /** 선택된 카테고리의 강의 목록 */
  lectures?: LectureItem[];
  /** 선택된 카테고리의 인덱스 */
  selectedCategoryIndex: number;
  /** 카테고리 선택 콜백 함수 */
  onClickCategory: (index: number) => void;
  /** 동영상 시청하기 콜백 함수 */
  onClickPlay: (index: number) => void;
}

export default (props: Props) => {
  return (
    <div className={styles.lecture_wrapper}>
      <div className={styles.project_title}>강의실</div>
      <CategorySelector
        categories={props.categories}
        onClickCategory={props.onClickCategory}
        selectedCategoryIndex={props.selectedCategoryIndex}
      />
      <LectureRoomContents
        lectures={props.lectures}
        categories={props.categories}
        selectedCategoryIndex={props.selectedCategoryIndex}
        onClickPlay={props.onClickPlay}
      />
    </div>
  );
};
