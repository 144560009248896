import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { api } from "../../../../config/api";
import { videoPages } from "../../../../constants/PagePaths";
import AlertContext from "../../../../contexts/AlertContext";
import {
  IamportPayMethod,
  payMethodMapper,
} from "../../../../models/payment/iamport/ImpModels";
import { PaymentHistoryModel } from "../../../../models/payment/PaymentHistoryModel";
import { videoApi } from "../../../../config/videoApi";
import VideoPaymentHistory from "../videoPaymentHistory/VideoPaymentHistory";
import VideoPaymentHistoryModal from "../videoPaymentHistory/VideoPaymentHistoryModal";

const VideoMyPage = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistoryModel[]>(
    []
  );
  const alert = useContext(AlertContext);
  const history = useHistory();

  useEffect(() => {
    new Promise(async () => {
      try {
        const res = await api.getMyInfo();
        setUserName(res.data.last_name);
        setEmail(res.data.email);
        setPhone(res.data.phone);
        setAddress(res.data.addr);
      } catch (error) {
        console.error(error);
        history.replace(videoPages.index);
      }

      try {
        const payHistory = await videoApi.getVideoPaymentHistoryAsync(0, 5);
        const { paymentHistories } = getHistories(payHistory);
        setPaymentHistory(paymentHistories);
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  const handlePayHistoryMore = async () => {
    const payHistory = await videoApi.getVideoPaymentHistoryAsync();
    const { paymentHistories } = getHistories(payHistory);
    alert.showContentModal(
      <VideoPaymentHistoryModal
        histories={paymentHistories}
        handleClickVbankInfo={handleClickVbankInfo}
        onConfirm={() => alert.closeAlert()}
      />,
      {
        width: "1200px",
      }
    );
  };

  const handleChangePwClick = () => {
    history.push(videoPages.changepassword);
  };

  const handleClickVbankInfo = (index: number) => {
    const vbankDate = +(paymentHistory?.[index]?.vbankDate ?? "0");
    const date = new Date(vbankDate * 1000);
    alert.showAlert(
      `입금 기한 : ${date.format("yyyy.MM.dd HH:mm")}까지\n입금 계좌 : ${
        paymentHistory?.[index]?.vbankName
      } ${paymentHistory?.[index]?.vbankNum}\n계좌명 : ${
        paymentHistory?.[index]?.vbankHolder
      }`
    );
  };

  const getHistories = (payHistory: any) => {
    const paymentHistories = payHistory.data.histories.map(
      (historyItem: any): PaymentHistoryModel => {
        const payMethod =
          Object.values(IamportPayMethod).find(
            (value) => value === historyItem.pay_method
          ) || IamportPayMethod.card;
        const paidAt = new Date(historyItem.paid_at * 1000)
          .format("yyyy.MM.dd")
          .trim();
        const expireDate = new Date(historyItem.expire_date)
          .format("yyyy.MM.dd")
          .trim();
        const startDate = new Date(historyItem.start_date)
          .format("yyyy.MM.dd")
          .trim();
        const status =
          historyItem.paid_at == undefined || historyItem.paid_at === 0
            ? new Date(historyItem.vbank_date).getTime() < Date.now()
              ? "만료"
              : "대기"
            : historyItem.canceled_time
            ? "취소"
            : "완료";
        return {
          amount: historyItem.paid_amount?.toLocaleString() + "원",
          payMethod: payMethodMapper[payMethod],
          prodName: historyItem.product_name
            ? `[${historyItem.product_name}]`
            : "",
          receiptUrl: historyItem.receipt_url,
          payRegistDate: paidAt,
          payRequestDate: new Date(historyItem.create_time).format(
            "yyyy.MM.dd"
          ),
          payTerm: startDate && expireDate ? `${startDate}~${expireDate}` : "",
          status,
          vbankHolder: historyItem.vbank_holder,
          vbankName: historyItem.vbank_name,
          vbankNum: historyItem.vbank_num,
          vbankDate: historyItem.vbank_date,
        };
      }
    );
    return { paymentHistories };
  };

  return (
    <div className="wrapper">
      <div className="width-center">
        <div className="title">마이페이지</div>
        <div className="content">
          <div className="content-row">
            <div>
              <div className="row">
                <div className="user-name">
                  <div className="profile">
                    <UserOutlined style={{ fontSize: "20px" }} />
                  </div>
                  <span className="profile-name">{userName || ""}</span>
                  <span className="sir">님 반갑습니다.</span>
                </div>
              </div>
              <div className="row">
                <div className="row-title">이메일</div>
                <div className="row-content">{email || ""}</div>
              </div>
              <div className="row">
                <div className="row-title">연락처</div>
                <div className="row-content">{phone || ""}</div>
              </div>
              <div className="row">
                <div className="row-title">주소</div>
                <div className="row-content">{address || ""}</div>
              </div>
              <div className="row">
                <div className="row-title">개인정보</div>
                <div className="row-highlight" onClick={handleChangePwClick}>
                  비밀번호 변경
                </div>
              </div>
            </div>
          </div>
          <div className="content-title-row">
            <span className="content-title">결제내역</span>
            <div className="content-title-more" onClick={handlePayHistoryMore}>
              <div>더보기{`>`}</div>
            </div>
          </div>
          <VideoPaymentHistory
            paymentHistory={paymentHistory}
            handleClickVbankInfo={handleClickVbankInfo}
          />
          <span
            className="re-pw"
            onClick={() => history.push(videoPages.withdrawal)}
          >
            회원탈퇴&nbsp;&gt;
          </span>
        </div>
      </div>
    </div>
  );
};

export default VideoMyPage;
