/* eslint-disable import/no-anonymous-default-export */
import styles from "./simpleAlert.module.scss";

interface Props {
  text: string;
  onClickConfirm: () => void;
}

export default (props: Props) => {
  return (
    <div
      className="modal fade show"
      id="dialogs"
      tabIndex={-1}
      aria-labelledby="dialogsLabel"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
      onClick={props.onClickConfirm}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="dialogsLabel">
              알림
            </h1>
          </div>
          <div className="modal-body" style={{ whiteSpace: "pre-wrap" }}>
            {props.text}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-md text-primary"
              onClick={props.onClickConfirm}
            >
              확인
            </button>
            {/* <button type="button" className="btn btn-md text-gray-400" data-bs-dismiss="modal">취소</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
