/* eslint-disable import/no-anonymous-default-export */
import "./videoPaymentHistory.scss";
import receiptIcon from "../../../../assets/img/icon/receipt-icon.png";
import { PaymentHistoryModel } from "../../../../models/payment/PaymentHistoryModel";

interface Props {
  paymentHistory: PaymentHistoryModel[];
  handleClickVbankInfo: (index: number) => void;
}

export default (props: Props) => {
  return (
    <div>
      <div className="mypage-payment-content-table">
        <div className="mypage-content-tr">
          <div className="th">NO</div>
          <div className="th">이용권명</div>
          <div className="th">결제수단</div>
          <div className="th">결제금액</div>
          <div className="th">상태</div>
          <div className="th">영수증</div>
        </div>
        {props.paymentHistory?.length > 0 ? (
          props.paymentHistory.map((history, index) => (
            <div className="mypage-content-tr">
              <div className="td">{index + 1}</div>
              <div className="td">{history.prodName}</div>
              <div className="td">
                {history.payMethod}
                {history?.payMethod?.includes("무통장") &&
                  history.status === "대기" && (
                    <span
                      className={"tiny_button"}
                      onClick={() => props.handleClickVbankInfo(index)}
                    >
                      계좌 확인
                    </span>
                  )}
              </div>
              <div className="td">{history.amount}</div>
              <div className="td">{history.status}</div>
              <div
                className="td"
                onClick={() => {
                  window.open(history?.receiptUrl);
                }}
              >
                <img src={receiptIcon} />
              </div>
            </div>
          ))
        ) : (
          <div className="mypage-content-none-tr">
            <span>진행된 결제내역이 없습니다</span>
          </div>
        )}
      </div>
    </div>
  );
};
