
const debugSettings = {
  /** 테스트용 상품 가져오는지 여부.
   * @default: false
   *  **/
  isGetTestProduct: false,
  /** 결제시 IAMPORT의 WebHook을 받을 것인지 */
  isSendLocalNotice: false,
  
  /** 모바일 디버깅을 위해 임시 ip주소 사용
   * @default: false
   *  **/
  isMobileApiUrl: false,
}

export default class GlobalEnvironments {
  public static PRODUCTION: boolean = process.env.REACT_APP_DOMAIN !== "localhost";

  public static getIsGetTestProduct = () => {
    if(GlobalEnvironments.PRODUCTION) {
      return false;
    }
    else {
      return debugSettings.isGetTestProduct;
    }
  }
  public static getIsSendLocalNotice = () => {
    if(GlobalEnvironments.PRODUCTION) {
      return false;
    }
    else {
      return debugSettings.isSendLocalNotice;
    }
  }
  public static getApiUrl = () => {
    if(GlobalEnvironments.PRODUCTION || !debugSettings.isMobileApiUrl) {
      return process.env.REACT_APP_API_URL;
    } else {
      return "http://192.168.219.145:8000/";
    }
  }
  public static getDomainUrl = () => {
    if(GlobalEnvironments.PRODUCTION || !debugSettings.isMobileApiUrl) {
      return process.env.REACT_APP_DOMAIN;
    } else {
      return "192.168.219.145";
    }
  }
  public static getAppUrl = () => {
    if(GlobalEnvironments.PRODUCTION || !debugSettings.isMobileApiUrl) {
      return process.env.REACT_APP_URL;
    } else {
      return "http://192.168.219.106:3000/";
    }
  }
}
