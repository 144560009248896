/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import styled from 'styled-components';

interface Props {
  onConfirm: () => void;
  size?: { width?: string; height?: string };
  children?: React.ReactNode;
}

export default (props: Props) => {
  return (
    <Container size={props.size}>
      {props.children}
    </Container>
  );
}

const Container = styled.div<{size?: { width?: string; height?: string }}>`
  width: ${(props) => props.size?.width || "600px"};
  height: ${(props) => props.size?.height || "900px"};
`;