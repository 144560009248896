/* eslint-disable import/no-anonymous-default-export */
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { ThumbUp, ThumbUpOutlined } from "@material-ui/icons";
import React from "react";
import CreateProjectModal from "../../components/modal/createProject/CreateProject";
import { util } from "../../config/util";
import { pages } from "../../constants/PagePaths";
import { SampleStatementDetailModel } from "../../constants/SampleStatementPageData";
import ProjectPopup from "./components/ProjectPopup";
import styles from "./sampleStatementDetail.module.scss";

interface Props {
  statementDetailModel?: SampleStatementDetailModel;
  isRecommended?: boolean;
  isCreateOpen: boolean;
  isOwner?: boolean;
  onCloseProjectNameModal: (title?: string) => void;
  onClickRecommend: () => void;
  onClickToList: () => void;
  onClickImport: () => void;
  onClickDownload: () => void;
  onClickDelete: () => void;
}

export default (props: Props) => {
  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container page ">
            <a
              href={pages.statementlist}
              className="btn text-gray-400 mb-3 left-icon back-btn"
            >
              <span className="material-symbols-outlined me-2">
                keyboard_backspace
              </span>
              목록으로
            </a>
            <div className="col-12">
              <div className="board-content">
                <div className="title">{props.statementDetailModel?.title}</div>
                <div className="author">
                  <div>
                    공종
                    <span className="ms-2">
                      {props.statementDetailModel?.mainType}
                    </span>
                  </div>
                  <div>
                    등록일
                    <span className="ms-2">
                      {props.statementDetailModel?.created.format("yyyy.MM.dd")}
                    </span>
                  </div>
                  <div>
                    조회수
                    <span className="ms-2">
                      {util.addComma(
                        props.statementDetailModel?.recommendCount
                      )}
                    </span>
                  </div>
                </div>
                <div className="mt-4 statement-btn-wrap">
                  <button
                    type="button"
                    className="btn btn-md btn-primary-soft"
                    data-bs-toggle="modal"
                    data-bs-target="#project"
                  >
                    <span className="material-symbols-outlined">
                      exit_to_app
                    </span>
                    프로젝트에서 열기
                  </button>
                  <button
                    type="button"
                    className="btn btn-md btn-outline"
                    onClick={props.onClickDownload}
                  >
                    <span className="material-symbols-outlined">download</span>
                    .ddusul파일 다운로드
                  </button>
                </div>
                <div className="py-4 border-bottom">
                  <pre>{props.statementDetailModel?.description}</pre>
                </div>
                <div className="mt-6">
                  <div className="d-flex mb-3 item-list">
                    <div className="d-flex align-items-center me-3 text-dark fw-bold">
                      <span className="state-circle-primary"></span>공사 종류
                    </div>
                    <div className="d-flex align-items-center me-3 text-dark fw-bold">
                      <span className="state-circle-yellow"></span>세부 공종
                    </div>
                    <div className="d-flex align-items-center text-dark fw-bold">
                      <span className="state-circle"></span>품명
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">품명</th>
                        <th scope="col">규격</th>
                        <th scope="col">단위</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.statementDetailModel?.products?.map((model) => {
                        return (
                          <tr
                            className={
                              model.type === "main"
                                ? "table-primary"
                                : model.type === "sub"
                                ? "table-warning"
                                : ""
                            }
                          >
                            {model.type === "main" ? (
                              <td colspan="3" className="ps-3">
                                {model?.name}
                              </td>
                            ) : model.type === "sub" ? (
                              <td colspan="3" class="ps-5">
                                {model?.name}
                              </td>
                            ) : (
                              <>
                                <td class="ps-7">{model?.name}</td>
                                <td>{model?.standard}</td>
                                <td>{model?.unit}</td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {true ? (
                  ""
                ) : (
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center mt-5">
                      <div className="d-flex gap-2">
                        <a href="#" className="btn btn-lg btn-outline">
                          <span className="material-symbols-outlined me-2">
                            edit
                          </span>
                          수정
                        </a>
                        <a href="#" className="btn btn-lg btn-danger">
                          <span className="material-symbols-outlined me-2">
                            delete
                          </span>
                          삭제
                        </a>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-lg btn-primary-soft"
                        >
                          <span className="material-symbols-outlined me-2">
                            edit
                          </span>
                          글쓰기
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </article>
      <ProjectPopup
        open={props.isCreateOpen}
        onClose={(title) => props.onCloseProjectNameModal(title)}
        estimate={false}
        tempRow={false}
        isMoveWarning={true}
        defaultValue={`(샘플내역서) ${props.statementDetailModel?.title}`}
      />
    </main>
  );
};
