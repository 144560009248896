import { PhotoListContentModel, PhotoListModel } from "../models/photoListDoc/PhotoListModel";

export const PhotoListData2: PhotoListModel = {
  photoListItems: [
    {
      id: 0,
      image_number: 0,
      image_sub_number: 0,
      image_url: '',
    },
  ]
}

export const PhotoListData4: PhotoListModel = {
  photoListItems: [
    {
      id: 0,
      image_number: 10,
      image_sub_number: 0,
      image_url: '',
    },
  ]
}

export const defaultContent = '수정을 클릭하여 내용을 입력해주세요.';

export const PhotoListContentData: PhotoListContentModel = {
  photoListContents: [{
    image_number: 0,
    image_sub_number: 1,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 2,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 3,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 4,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 5,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 6,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 7,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 8,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 9,
    content: defaultContent,
  },{
    image_number: 0,
    image_sub_number: 10,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 1,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 2,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 3,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 4,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 5,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 6,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 7,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 8,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 9,
    content: defaultContent,
  },{
    image_number: 1,
    image_sub_number: 10,
    content: defaultContent,
  }]
}