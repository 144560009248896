/* eslint-disable import/no-anonymous-default-export */

import { Worklist } from "../../../../models/worklist/WorklistModel";
import _ from 'lodash';
import { PhotoListContent, PhotoListContentModel, PhotoListModel } from "../../../../models/photoListDoc/PhotoListModel";
import { BackTop, Button, Input } from "antd";
import { PrinterOutlined, UpCircleOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import AlertContext from '../../../../contexts/AlertContext';
import $ from 'jquery';
import styles from "./photoList4TablePage.module.scss";
import Compressor from "compressorjs";
import { useEffect } from "react";

interface Props {
  model: PhotoListModel;
  worklist: Worklist;
  putWorkListAsync: (data: any) => void,
  postWorklistImage: (result: any, num: number, sub_num: number) => void;
  deleteWorklistImage: (id: number) => void;
}

export default (props: Props) => {
  const worklist = props.worklist;
  const putWorkListAsync = props.putWorkListAsync;
  const postWorklistImage = props.postWorklistImage;
  const images = props.model.photoListItems; //모든 페이지 해당 사진 (image_num 11~20에 해당)

  const printedBtn = () => {
    const uploadOrImage = document.getElementsByClassName('uploadOrImage') as HTMLCollection;
    const photoContentModiBtn = document.getElementsByClassName(`photo_content_modi_btn`) as any;
    photoContentModiBtn[0].style.visibility = "hidden"; //수정 버튼 hidden
    for (var i = 0; i < uploadOrImage.length; i++) {
      if (uploadOrImage[i].innerHTML.match('업로드')) {
        uploadOrImage[i].innerHTML = `<div style='position: absolute; border-top: 1px solid; width: 131%; height: 100%; transform: rotate(-49deg);left: -56px'></div>
        <div style='position: absolute; border-top: 1px solid; width: 131%; height: 100%; transform: rotate(49deg);left: -56px'></div>`
      }
    }

    const printBody = document.getElementById('printArea') as HTMLElement;
    document.body.innerHTML = printBody.innerHTML;
    window.print();
    window.location.reload();
  };

  //내용 클릭 시 modal
  const [text, setText] = useState("");
  const onChange = (e: any) => {
    setText(e.target.value);
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const photoListContents = worklist?.photoListContent;
  const [useContent, setUseContent] = useState(photoListContents?.photoListContents);

  useEffect(() => {
    setUseContent(photoListContents?.photoListContents);
  }, [photoListContents])

  const handleOk1 = async () => {
    if (text === "") {
      // alert("내용입력을 입력하세요.")
      alert.showAlert("내용을 입력하세요.");
    } else {
      let data = worklist;
      data = {
        ...worklist,
        ...data,
      }
      let newArr = useContent.map((item: PhotoListContent, i: any) => {
        const cloneItem = _.cloneDeep(item);
        if (pageNum === i + 1 - 10) {
          cloneItem && (cloneItem['content'] = text);
          cloneItem && (cloneItem['image_number'] = 1);
          cloneItem && (cloneItem['image_sub_number'] = pageNum);
          return { ...cloneItem }
        } else {
          return cloneItem
        }
      })

      let tempData: PhotoListContentModel = {
        photoListContents: newArr
      }
      data.photoListContent = tempData;
      putWorkListAsync(data); //image_number 1, image_sub_number 1~10
      setUseContent(newArr);
      handleCancel();
    };
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeImage = (e: any) => {

    const fullStr = e.target.id;
    const lastChar = fullStr.charAt(fullStr.length - 1);

    // 사진 크기 조절
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    new Compressor(file, {
      quality: 0.6,

      success(result) {
        postWorklistImage(result, pageNum + 10, lastChar)
      },
      error(err) {

      },
    });
  }

  //알람 사용
  const alert = useContext(AlertContext);

  const handleDeleteImage = (e: any) => {
    const idTarget = e.target;
    const boardImageId = Number($(idTarget).attr('id'));
    alert.showConfirmAlert("알림", "사진을 삭제하시겠습니까?", "예", "아니오", () => {
      props.deleteWorklistImage(boardImageId);
    })
  };

  const [pageNum, setPageNum] = useState(1);
  const numberOnClick = (pageNum: number) => {
    setPageNum(pageNum);
  }

  const image1 = images?.filter((image: any) => image.image_number === pageNum + 10 && image.image_sub_number === 1)[0];
  const image2 = images?.filter((image: any) => image.image_number === pageNum + 10 && image.image_sub_number === 2)[0];
  const image3 = images?.filter((image: any) => image.image_number === pageNum + 10 && image.image_sub_number === 3)[0];
  const image4 = images?.filter((image: any) => image.image_number === pageNum + 10 && image.image_sub_number === 4)[0];

  return (
    <div className={styles.photoListContainer}>
      <div id="printArea">
        <div>
          <div className={styles.photo_page}>
            <div>
              <table style={{ textAlign: "center", tableLayout: "fixed" }}>
                <tr>
                  <td style={{ lineHeight: "100px", paddingBottom: "30px" }} className="title" colSpan={4} >사  진  대  장</td>
                </tr>
                <tr style={{ height: "30px" }}>
                  <td>공사명</td>
                  <td style={{ wordBreak: "keep-all" }} colSpan={3}>{worklist?.constructionName}</td>
                </tr>
                <tr style={{ height: "30px" }}>
                  <td>내용</td>
                  <td colSpan={3}>
                    <button className={`${styles.photo_content_modi_btn} photo_content_modi_btn`} onClick={showModal}>
                      수정
                    </button>
                    <Modal title="내용 입력" style={{ textAlign: "center" }} visible={isModalVisible} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} closable={false}>
                      <Input type="text" onChange={onChange} maxLength={20} />
                      <Button
                        style={{
                          backgroundColor: "var(--color-primary)",
                          float: "right",
                          marginTop: "10px",
                        }}
                        onClick={handleOk1}
                      >
                        <span style={{ color: "white" }}>확인</span>
                      </Button>
                    </Modal>
                    <span style={{ marginRight: "40px", lineHeight: "25px" }}>
                      {useContent?.[pageNum + 10 - 1].content}
                    </span>
                  </td>
                </tr>
                <tr style={{ height: "30px" }}>
                  <td style={{ borderLeft: "hidden", borderRight: "hidden" }} colSpan={4}></td>
                </tr>
                <tr style={{ height: "400px" }}>
                  <td colSpan={2}>
                    <div className={`${styles.uploadOrImage} uploadOrImage`}>
                      {
                        image1 ?
                          <div className={styles.photolist_dropdown}>
                            <div>
                              <img className={styles.upload_four_img} src={image1.image_url} />
                            </div>
                            <div className={styles.photolist_toolbar_hide}>
                              <div className={styles.photolist_sub_menu} onClick={handleDeleteImage} id={image1.id.toString()}>
                                삭제하기
                              </div>
                            </div>
                          </div>
                          :
                          <div>
                            <label className={styles.input_file_btn}>
                              업로드
                              <input type="file" id="constructionImg1" accept="image/jpeg, image/png" onChange={onChangeImage} style={{ display: "none" }} />
                            </label>
                          </div>
                      }
                    </div>
                  </td>
                  <td style={{ width: "50%" }} colSpan={2}>
                    <div className={`${styles.uploadOrImage} uploadOrImage`}>
                      {
                        image2 ?
                          <div className={styles.photolist_dropdown}>
                            <div>
                              <img className={styles.upload_four_img} src={image2.image_url} />
                            </div>
                            <div className={styles.photolist_toolbar_hide}>
                              <div className={styles.photolist_sub_menu} onClick={handleDeleteImage} id={image2.id.toString()}>
                                삭제하기
                              </div>
                            </div>
                          </div>
                          :
                          <div>
                            <label className={styles.input_file_btn}>
                              업로드
                              <input type="file" id="constructionImg2" accept="image/jpeg, image/png" onChange={onChangeImage} style={{ display: "none" }} />
                            </label>
                          </div>
                      }
                    </div>
                  </td>
                </tr>
                <tr style={{ height: "400px" }}>
                  <td style={{ width: "359px" }} colSpan={2}>
                  <div className={`${styles.uploadOrImage} uploadOrImage`}>
                      {
                        image3 ?
                          <div className={styles.photolist_dropdown}>
                            <div>
                              <img className={styles.upload_four_img} src={image3.image_url} />
                            </div>
                            <div className={styles.photolist_toolbar_hide}>
                              <div className={styles.photolist_sub_menu} onClick={handleDeleteImage} id={image3.id.toString()}>
                                삭제하기
                              </div>
                            </div>
                          </div>
                          :
                          <div>
                            <label className={styles.input_file_btn}>
                              업로드
                              <input type="file" id="constructionImg3" accept="image/jpeg, image/png" onChange={onChangeImage} style={{ display: "none" }} />
                            </label>
                          </div>
                      }
                    </div>
                  </td>
                  <td style={{ width: "400px" }} colSpan={2}>
                  <div className={`${styles.uploadOrImage} uploadOrImage`}>
                      {
                        image4 ?
                          <div className={styles.photolist_dropdown}>
                            <div>
                              <img className={styles.upload_four_img} src={image4.image_url} />
                            </div>
                            <div className={styles.photolist_toolbar_hide}>
                              <div className={styles.photolist_sub_menu} onClick={handleDeleteImage} id={image4.id.toString()}>
                                삭제하기
                              </div>
                            </div>
                          </div>
                          :
                          <div>
                            <label className={styles.input_file_btn}>
                              업로드
                              <input type="file" id="constructionImg4" accept="image/jpeg, image/png" onChange={onChangeImage} style={{ display: "none" }} />
                            </label>
                          </div>
                      }
                    </div>
                  </td>
                </tr>
              </table>
            </div>

          </div>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>

      <div id="select4Container" style={{ display: "flex", margin: "40px", height: "30px", justifyContent: "center" }}>
        {/* {
          Array.from({ length: Number(maxImgNum) - 10 }, (_, itemIndex) => {
            return (
              <div>
                <button className={`${styles.photoNumBtn4} ${imageNum === itemIndex ? styles.active : ""}`} style={{ marginRight: "10px" }}
                  onClick={() => {
                    numberOnClick(itemIndex)
                  }}
                >{itemIndex + 1}</button>
              </div>
            )
          })
        }
        <button className={styles.photoNumAddBtn} style={{ marginRight: "10px" }} onClick={addPhotoList}> + </button>
        <button className={styles.photoNumAddBtn} onClick={subPhotoList} > - </button> */}
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 1 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 11).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(1) }}>1</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 2 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 12).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(2) }}>2</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 3 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 13).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(3) }}>3</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 4 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 14).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(4) }}>4</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 5 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 15).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(5) }}>5</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 6 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 16).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(6) }}>6</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 7 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 17).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(7) }}>7</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 8 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 18).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(8) }}>8</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 9 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 19).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(9) }}>9</button>
        <button
          className={`${styles.photoNumBtn2} ${pageNum === 10 ? styles.active : ""} 
          ${images?.filter((image: any) => image.image_number === 20).length > 0 ? styles.working : ""}`}
          onClick={() => { numberOnClick(10) }}>10</button>
      </div>
    </div>
  );
};
