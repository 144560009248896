import { ReactElement, useEffect, useState } from "react";
import MyPageHeader from "../pages/myPage/MyPageHeader";
import { api } from "../config/api";
import { useHistory } from "react-router-dom";
import { pages } from "../constants/PagePaths";

export default (props: { child: ReactElement }) => {
  const history = useHistory();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    new Promise(async () => {
      try {
        const res = await api.getMyInfo();
        setUserName(res.data.last_name);
      } catch (error) {
        console.error(error);
        history.replace(pages.index);
      }
    });
  }, []);

  return (
    <main id="wrap" className="theme-lf-light">
      <div className="mypage-container page">
        <div id="mypage">
          <MyPageHeader userName={userName} />
          {props.child}
        </div>
      </div>
    </main>
  );
};
