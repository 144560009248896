import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function DeputyTotal(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table className="bbb" style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "50px", textAlign: "center", height: "150px", fontWeight: "600" }}>현 장 대 리 인 계</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ lineHeight: "30px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>1.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>공</span>
                <span>사</span>
                <span>명</span>
              </span>　:
              </td>
              <td style={{ wordBreak: "break-all", wordWrap:"word-break" }} colspan="3">{worklist?.constructionName}</td>
            </tr>
            <tr style={{ lineHeight: "30px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>2.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>공</span>
                <span>사</span>
                <span>위</span>
                <span>치</span>
              </span>　:
              </td>
              <td colspan="3">{worklist?.constructionAddress}</td>
            </tr>
            <tr style={{ lineHeight: "30px", borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>3.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>계</span>
                <span>약</span>
                <span>금</span>
                <span>액</span>
              </span>　:
              </td>
              <td colspan="3">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td style={{borderRight: "hidden"}}>　○<span style={{ marginLeft: "10px", width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>현</span>
                <span>장</span>
                <span>대</span>
                <span>리</span>
                <span>인</span>
                </span>
              </td>
              <td colspan="3">　</td>
            </tr>

            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                <span style={{ marginLeft: "40px", width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>주</span>
                <span>소</span>
              </span>　:
              </td>
              <td colspan="3">{worklist?.deputyAddress}</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}><span style={{ marginLeft: "40px", width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>성</span>
                <span>명</span>
              </span>　:
              </td>
              <td colspan="3" style={{ letterSpacing: "10px" }}>{worklist?.deputyName}</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}><span style={{ marginLeft: "40px", width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>주</span>
                <span>민</span>
                <span>등</span>
                <span>록</span>
                <span>번</span>
                <span>호</span>
              </span>　:
              </td>
              <td colspan="3">{worklist?.deputyResidentNum}</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ lineHeight: "30px", borderBottom: "hidden" }}>
              <td colspan="4" style={{ height: "30px" }}>○ 기술분야 자격</td>
            </tr>
          </table>

          <table className="aaa" border="1" style={{ tableLayout: "fixed", textAlign: "center" }}>
            <tr>
              <td rowspan="2">종　　목</td>
              <td rowspan="2">등　　급</td>
              <td rowspan="2">등 록 번 호</td>
              <td>취 득 연 월 일</td>
            </tr>
            <tr>
              <td>갱 신 연 월 일</td>
            </tr>
            <tr>
              <td rowspan="2">{worklist?.deputyEvent}</td>
              <td rowspan="2">{worklist?.deputyGrade}</td>
              <td rowspan="2">{worklist?.deputyRegistNum}</td>
              <td>
                {
                  moment(worklist?.deputyGetDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.deputyGetDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  moment(worklist?.deputyRenewDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.deputyRenewDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
          </table>

          <table className="bbb" style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "20px", textAlign: "center" }}>위와 같이 현장대리인을 선정하였기 현장대리인 선임계를 제출합니다.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colspan="4">　</td>
            </tr>

            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "15px", textAlign: "center" }}>
                {
                  moment(worklist?.beginDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.beginDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">전화</span>:　<span>{worklist?.phoneNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">팩스</span>:　<span>{worklist?.faxNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>

          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default DeputyTotal;