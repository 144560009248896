// Curriculum component
import { useState, useEffect, useRef } from "react";
import styles from "./LectureList.module.scss";
import { useHistory } from "react-router-dom";
import { videoApi } from "../../../../config/videoApi";
import { util } from "../../../../config/util";
import { videoPages } from "../../../../constants/PagePaths";
import React from "react";

const LectureList = () => {
  const [data, setData] = useState<any>();
  const [isLogin, setIsLogin] = useState(false);
  const myData = useRef(null);
  const [myPurchase, setMyPurchase] = useState([]);
  const myPurchases = myPurchase?.map((a: { id: any }) => a.id);
  const allData = useRef(null);
  const [isViewAllSelected, setIsViewAllSelected] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const isLogin = util.isLogin();
    setIsLogin(isLogin);
    new Promise(async () => {
      if (isLogin) {
        const myDataRes = await videoApi.getMyCourseAsync();
        myData.current = myDataRes.data.user_video_purchases;
        const purchaseData = await (
          await videoApi.getMyCourseAsync()
        ).data.user_video_purchases;
        setMyPurchase(purchaseData);
      }
      const res = await videoApi.getCourseListAsync();
      const course = res?.data;
      // order 숫자대로 오름차순
      allData.current = course?.courses.sort((a: any, b: any) =>
        a.order > b.order ? 1 : -1
        );
      setData(course?.courses);
    });
  }, []);

  return (
    <div className={styles.lectureList_wrapper}>
      <div className={styles.lectureList_tabs}>
        <ul>
          <li
            onClick={() => {
              setData(allData.current);
              setIsViewAllSelected(true);
            }}
            className={isViewAllSelected ? styles.checked : ""}
          >
            모든강좌
          </li>
          {isLogin && (
            <li
              className={!isViewAllSelected ? styles.checked : ""}
              onClick={() => {
                setData(myData.current);
                setIsViewAllSelected(false);
              }}
            >
              내강좌
            </li>
          )}
        </ul>
      </div>
      <div className={styles.lectureLists_container}>
        {data?.map((course: any, index: number) => (
          <div className={styles.contents_container} key={index}>
            <div
              className={styles.contentImg_container}
              style={{ backgroundImage: `url(${course.card_image})` }}
              onClick={() => {
                history.push(`${videoPages.curriculum}/${course.id}`);
              }}
            ></div>
            <div className={styles.contentInfo_container}>
              <div className={styles.title_container}>
                <div
                  className={styles.content_title}
                  onClick={() => {
                    history.push(`${videoPages.curriculum}/${course.id}`);
                  }}
                >
                  {course.title}
                </div>
                <p>
                  {course.description} <br />
                </p>
              </div>
              <div className={styles.amount_container}>
                {myPurchases.indexOf(course.id) !== -1 ? (
                  <button
                    className={styles.content_status}
                    onClick={() => {
                      history.push(`${videoPages.curriculum}/${course.id}`);
                    }}
                  >
                    수강 계속하기
                  </button>
                ) : (
                  <React.Fragment>
                    <div  className={styles.bottom_container}>
                      <div className={styles.service}>
                        {course.service_product}{course.service_product && ` 이용권 지급`}
                      </div>
                      <div className={styles.amount_wrapper}>
                        <div className={styles.origin_amount}>
                         ₩ {course.origin_amount.toLocaleString()}
                        </div>
                        <div className={styles.content_amount}>
                          {`₩ ${course.amount.toLocaleString()}`}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        ))}
        {data?.length === 0 && (
          <div className={styles.blank}>
            구매한 강의가 없습니다.
          </div>
        )}
      </div>
    </div>
  );
};

export default LectureList;
