import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import styled from "styled-components";
import img1 from "../../img/community.jpeg";
import img2 from "../../img/notion.png";

const Notice = () => {
  const notion = "https://seblog.notion.site/3dc9d52d7d6b4e719f4fb08fe4f58c6d";

  return (
    <Div>
      <Img>
        <Parent>
          <Children>
            <Centering>
              <Title>Community</Title>
              <a href={notion} target="_blank">
                <Button>커뮤니티 참여하기</Button>
              </a>
            </Centering>
          </Children>
        </Parent>
      </Img>

      <Parent>
        <Children>
          <Column>
            <Margin>
              <SubTitle>노션 커뮤니티</SubTitle>
              <div>
                해당 커뮤니티는 여러분들이 질문이 생겼을때 학생분들끼리 서로
                질문하고 답변하는 커뮤니티 입니다. 서로 모르는것을 도와주고 함께
                성장해나가는 커뮤니티입니다. ✨
              </div>
              <a href={notion} target="_blank">
                <Button2>커뮤니티 참여하기</Button2>
              </a>
            </Margin>
          </Column>
          <Img2></Img2>
        </Children>
      </Parent>
    </Div>
  );
};

export default Notice;


const Div = styled.div`
  min-height: 100vh;
  margin: 0 auto;
`
// 부모 width : 100%
const Parent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
// 자식 flex width : 1200px
const Children = styled.div`
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// 큰 제목
const Title = styled.div`
  font-size: 3rem;
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
`;

// 배경 이미지
const Img = styled.div`
  width: 100%;
  height: 400px;
  background-image: url(${img1});
  background-size: cover;
  background-position: center;
`;

// 노션 이미지
const Img2 = styled.div`
  width: 1000px;
  height: 300px;
  background-image: url(${img2});
  background-size: cover;
  background-position: center;
  box-shadow: 0 17px 20px -18px rgba(0, 0, 0, 1);
  margin: 60px 0;
  border-radius: 10px;
`;

// 이미지 내 버튼
const Button = styled.div`
  border: 2px solid #9013fe;
  background-color: #9013fe;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 100px;
  font-family: "Montserrat", Sans-Serif;
  font-size: 1em;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
`;

// 하단 버튼
const Button2 = styled(Button)`
  margin: 20px 0;
  width: fit-content;
`;

// 이미지 내 요소 센터링
const Centering = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

// 세로 방향
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

// subTitle
const SubTitle = styled(Title)`
  font-size: 2rem;
  color: black;
  margin-bottom: 10px;
  font-weight: 700;
  margin: 30px 0;
`;

//
const Margin = styled.div`
  padding: 100px;
`;
