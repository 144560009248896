import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { LevelTestResContext } from "../../contexts/LevelTestContext";
import NextPrev from "./components/NextPrev";
import Timer from "./components/Timer";

export default () => {
  const levelTestRes = useContext(LevelTestResContext);
  const history = useHistory();
  const [levelTestAnswer, setLevelTestAnswer] = useState(0);
  const levelTestAnswerTimerRef = useRef<HTMLInputElement | null>(null);

  const next = () => {
    levelTestRes.setLevelAnswer(
      9,
      levelTestAnswer,
      levelTestAnswerTimerRef.current!.value
    );
    history.push(pages.leveltest + "/10");
  };

  const previous = () => {
    history.push(pages.leveltest + "/8");
  };
  return (
    <main id="wrap">
      <div className="progress top-progress">
        <div
          className="progress-bar "
          role="progressbar"
          aria-label="Basic example"
          style={{ width: "90%" }}
          aria-valuenow="90"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <section className="bg-light-md">
        <div className="container page py-120">
          <div className="form-wrap col-md-7 mx-auto ">
            <form>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="badge body2 text-bg-secondary">Question.9</div>
                <Timer forwardedRef={levelTestAnswerTimerRef} />
              </div>
              <h4 className="text-dark mb-5">
                다음 내용의 서류는 무엇인지 보기를 보고 선택하세요.
              </h4>
              <div className="border py-4 rounded-1 text-center mb-5 bg-gray-50">
                1m 당 기준
                <br />
                0.2m × 0.3m × 1m³ = 0.06
              </div>
              <div
                className="d-flex flex-wrap gap-3 mb-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option9-1"
                    value={0}
                    checked={levelTestAnswer === 0}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option9-1"
                  >
                    수량집계표
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option9-2"
                    value={1}
                    checked={levelTestAnswer === 1}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option9-2"
                  >
                    일위대가표
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option9-3"
                    value={2}
                    checked={levelTestAnswer === 2}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option9-3"
                  >
                    단위수량산출서
                  </label>
                </div>
              </div>
              <NextPrev previous={previous} next={next} />
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};
