/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HistoryPoductModel, PaymentHistoryModel } from "../../models/payment/PaymentHistoryModel";
import receiptIconImage from "../../assets/img/icon/receipt-icon.png";
import { Dialog } from "@material-ui/core";
import SimpleAlert from "../modal/alert/SimpleAlert";
import PaymentHistory from "./PaymentHistory";

interface Props {
  histories: PaymentHistoryModel[];
  historyProducts: HistoryPoductModel[][];
  handleClickVbankInfo: (index: number) => void;
  onConfirm: () => void;
}

export default (props: Props) => {
  useEffect(() => {
    return () => {
      props.onConfirm();
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const handleConfirm = () => {
    setIsOpen(false);
  };

  const handleClickVbankInfo = (index: number) => {
    setAlertMessage(
      `입금 기한 : ${props.histories?.[index]?.vbankDate}까지\n입금 계좌 : ${props.histories?.[index]?.vbankName} ${props.histories?.[index]?.vbankNum}\n계좌명 : ${props.histories?.[index]?.vbankHolder}`
    );
    setIsOpen(true);
  };

  return (
    <Container>
      <Dialog
        open={isOpen}
        onClose={(e) => {
          setIsOpen(false);
        }}
      >
        <SimpleAlert text={alertMessage} onClickConfirm={handleConfirm} />
      </Dialog>
      <HeaderWrap>
        <Title>결제 내역</Title>
        <PaymentHistory
          historyProducts={props.historyProducts}
          paymentHistory={props.histories}
          handleClickVbankInfo={handleClickVbankInfo}
        />
      </HeaderWrap>
      <Button onClick={() => props.onConfirm()}>확인</Button>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  min-height: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const Title = styled.div`
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
`;
const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;
const Button = styled.button`
  margin: 0 auto;
  margin-bottom: 10px;
  width: 285px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 6px;
  background-color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
  height: 38px;
  border: none;
  margin-top: 20px;
`;
