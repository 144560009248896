import React, { useState, useEffect, useContext } from 'react';
import { List, Typography, Button, Modal, Pagination, Radio, Spin, Input, message } from 'antd';
// import { getCategoryName, CategoryValue } from '../../constants/Categories';
// import { getPosts, Post, deletePost } from '../../services/postService';
import AIPostCard from '../Post/AIPostCard';
import styles from './AICommunityPage.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import { AIPost } from '../../models/aiCommunityModel';
import { api } from '../../config/api';
import AIPostForm from '../Post/AIPostForm';
import AlertContext from '../../contexts/AlertContext';
import { useHistory } from 'react-router-dom';
import { pages } from '../../constants/PagePaths';
import { util } from '../../config/util';

const { Title } = Typography;

const AICommunityPage: React.FC = () => {
  const [posts, setPosts] = useState<AIPost[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortType, setSortType] = useState<'latest' | 'popular'>('latest');
  const [isLoading, setIsLoading] = useState(true);
  const pageSize = 5;
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPosts, setTotalPosts] = useState(0);
  const alert = useContext(AlertContext);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, [currentPage, sortType, searchTerm]);

  const fetchPosts = async () => {
    setIsLoading(true);
    try {
      const offset = (currentPage - 1) * pageSize;
      const response = await api.getAIPosts(offset, pageSize, searchTerm, sortType);
      setPosts(response.posts);
      setTotalPosts(response.total);
    } catch (error) {
      console.error('Failed to fetch posts:', error);
      alert.showAlert('게시글을 불러오는데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (newPost: { title: string; content: string }) => {
    try {
      setIsModalVisible(false);
      setIsSubmitting(true);
      
      const createdPost = await api.createAIPost(newPost);
      message.success('게시글이 작성되었습니다.');
      history.push(`${pages.communityAi}/${createdPost.id}`);
    } catch (error) {
      console.error('Failed to create post:', error);
      if ((error as any).response?.status === 401) {
        alert.showAlert('로그인이 필요합니다.');
        history.push(pages.login);
      } else if ((error as any).response?.status === 403) {
        alert.showAlert('로그인 상태를 확인해주세요.');
      } else {
        alert.showAlert('게시글 작성에 실패했습니다.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (e: any) => {
    setSortType(e.target.value);
    setCurrentPage(1);
    // 정렬 변경 시 게시글 다시 가져오기
    const offset = 0; // 첫 페이지로 돌아가므로 offset은 0
    api.getAIPosts(offset, pageSize, searchTerm, e.target.value)
      .then(response => {
        setPosts(response.posts);
        setTotalPosts(response.total);
      })
      .catch(error => {
        console.error('Failed to fetch posts:', error);
        alert.showAlert('게시글을 불러오는데 실패했습니다.');
      });
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleWriteButtonClick = () => {
    try {
      if (!util.isLogin()) {
        message.warning('로그인이 필요한 서비스입니다.');
        history.push(pages.login);
        return;
      }
      
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error checking auth status:', error);
      message.error('오류가 발생했습니다.');
    }
  };

  return (
    <div className={styles.communityPageContainer}>
      <div className={styles.communityPageHeader}>
        <Title level={2} className={styles.categoryTitle}>AI 질의응답</Title>
      </div>
      <div className={styles.controlsContainer}>
        <div className={styles.searchContainer}>
          <Input
            placeholder="검색어를 입력하세요"
            prefix={<SearchOutlined className={styles.searchIcon} />}
            onPressEnter={(e) => handleSearch((e.target as HTMLInputElement).value)}
            onChange={(e) => {
              if (!e.target.value) {
                handleSearch('');
              }
            }}
            className={styles.searchInput}
            allowClear
          />
        </div>
        <Radio.Group onChange={handleSortChange} value={sortType} buttonStyle="solid">
          <Radio.Button value="latest" className={styles.sortButton}>최신순</Radio.Button>
          <Radio.Button value="popular" className={styles.sortButton}>인기순</Radio.Button>
        </Radio.Group>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <List
            className={styles.postList}
            itemLayout="vertical"
            dataSource={posts}
            renderItem={(post: AIPost) => (
              <List.Item>
                <AIPostCard
                  id={post.id}
                  title={post.title}
                  author={post.author}
                  date={post.createdAt}
                  likes={post.likes}
                  comments={post.commentCount}
                  hasAIAnswer={post.answers?.length > 0}
                />
              </List.Item>
            )}
          />
          <div className={styles.writeButtonContainer}>
            <Button 
              type="primary" 
              onClick={handleWriteButtonClick} 
              className={styles.writeButton}
            >
              글쓰기
            </Button>
          </div>
          <div className={styles.paginationContainer}>
            <Pagination
              current={currentPage}
              total={totalPosts}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
      <Modal
        title="AI 질의응답 글쓰기"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className={styles.writeModal}
        width={900}
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
        zIndex={1200}
        mask={true}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        getContainer={() => document.body}
      >
        <AIPostForm onSubmit={handleSubmit} />
      </Modal>
      {isSubmitting && (
        <div className={styles.globalLoadingContainer}>
          <Spin size="large" tip="AI 답변을 생성하는 중입니다..." />
        </div>
      )}
    </div>
  );
};

export default AICommunityPage;
