import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function EmploymentProof(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const birthYear = worklist?.deputyResidentNum.substring(0, 2);
  const birthMonth = worklist?.deputyResidentNum.substring(2, 4);
  const birthDay = worklist?.deputyResidentNum.substring(4, 6);
  const birth = `19${birthYear}년 ${birthMonth}월 ${birthDay}일`;

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table style={{ tableLayout: "fixed", textAlign: "center" }}>
            <tr>
              <td className="doc-title" colspan="9" style={{ fontSize: "50px", textAlign: "center", height: "200px" }}>재 직 증 명 원</td>
            </tr>
            <tr>
              <td style={{ height: "30px" }}>
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>주</span>
                  <span>소</span>
                </span>
              </td>
              <td colspan="8">{worklist?.deputyAddress}</td>
            </tr>
            <tr>
              <td style={{ height: "30px" }}>
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>생</span>
                  <span>년</span>
                  <span>월</span>
                  <span>일</span>
                </span>
              </td>
              <td colspan="4">{birth}</td>
              <td rowspan="2" colspan="2">주 민 등 록 번 호</td>
              <td rowspan="2" colspan="2">{worklist?.deputyResidentNum}</td>
            </tr>
            <tr>
              <td style={{ height: "30px" }}>
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>성</span>
                  <span>명</span>
                </span>
              </td>
              <td colspan="4" style={{ letterSpacing: "10px" }}>{worklist?.deputyName}</td>
            </tr>
            <tr>
              <td colspan="9" style={{ height: "30px" }}>자　격　종　목　　및　　등　급</td>
            </tr>
            <tr style={{ height: "30px" }}>
              <td rowspan="2" colSpan="2">종　　　목</td>
              <td rowspan="2">　등　급　</td>
              <td rowspan="2" colSpan="2">등　록　번　호</td>
              <td colspan="4">취　득　연　월　일</td>
            </tr>
            <tr>
              <td colspan="4" style={{ height: "30px" }}>갱　신　연　월　일</td>
            </tr>
            <tr style={{ height: "30px" }}>
              <td rowspan="2" colspan="2">{worklist?.deputyEvent}</td>
              <td rowspan="2">{worklist?.deputyGrade}</td>
              <td rowspan="2" colSpan="2">{worklist?.deputyRegistNum}</td>
              <td colspan="4">
                {
                  moment(worklist?.deputyGetDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.deputyGetDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ height: "30px" }}>
              <td colspan="4">
                {
                  moment(worklist?.deputyRenewDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.deputyRenewDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr>
              <td colspan="9" style={{ height: "30px" }}>재　직　사　항</td>
            </tr>
            <tr style={{ height: "30px" }}>
              <td colspan="4" style={{ width: "300px" }}>기　　　　　　　　간</td>
              <td colspan="2">근　무　처</td>
              <td colspan="2">직　　　위</td>
              <td>비고</td>
            </tr>
            <tr style={{ height: "30px" }}>
              <td colspan="4" style={{ letterSpacing: "0.3px", fontSize: "13px", textAlign: "left", paddingLeft: "5px" }}>
                {
                  moment(worklist?.deputyJoinDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.deputyJoinDate).format('yyyy년 MM월 DD일')
                }
                {`부터 ${moment(Date()).format('yyyy년 MM월')} 현재까지`}
              </td>
              <td colSpan="2">{worklist?.deputyWorkPlace}</td>
              <td colSpan="2">{worklist?.deputyPosition}</td>
              <td>　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px", borderRight: "hidden", borderLeft: "hidden" }}>
              <td colspan="9">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", borderRight: "hidden", borderLeft: "hidden" }}>
              <td colspan="9" style={{ fontSize: "20px", textAlign: "center" }}>위와 같이 현재 당사에 재직하고 있음을 증명합니다.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px", borderRight: "hidden", borderLeft: "hidden" }}>
              <td colspan="9">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", borderRight: "hidden", borderLeft: "hidden" }}>
              <td colspan="9" style={{ fontSize: "15px", textAlign: "center" }}>
                {
                  moment(worklist?.beginDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.beginDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px", borderRight: "hidden", borderLeft: "hidden" }}>
              <td colspan="9">　</td>
            </tr>
          </table>
          <table style={{ tableLayout: "fixed" }}>
            <tr style={{ height: "30px", border: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">전화</span>:　<span>{worklist?.phoneNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">팩스</span>:　<span>{worklist?.faxNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", borderRight: "hidden", borderLeft: "hidden", height: "40px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", borderRight: "hidden", borderLeft: "hidden" }}>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default EmploymentProof;