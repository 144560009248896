import React from 'react';
import { Form, Input, Button } from 'antd';
import styles from './CommentForm.module.scss';

const { TextArea } = Input;

interface CommentFormProps {
  onSubmit: (content: string) => Promise<void>;
  isSubmitting: boolean;
}

const CommentForm: React.FC<CommentFormProps> = ({ onSubmit, isSubmitting }) => {
  const [form] = Form.useForm();

  const handleSubmit = async (values: { content: string }) => {
    await onSubmit(values.content);
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical" className={styles.commentForm}>
      <Form.Item name="content" rules={[{ required: true, message: '댓글 내용을 입력해주세요' }]}>
        <TextArea rows={4} placeholder="댓글을 입력하세요" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          댓글 등록
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CommentForm;
