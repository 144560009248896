import _ from "lodash";
import { Cookies } from "../constants/Cookies";
import { api } from "./api";

// eslint-disable-next-line no-extend-native
Number.prototype.pad = function (len: any) {
  return this.toString().pad(len);
};
// eslint-disable-next-line no-extend-native
String.prototype.pad = function (len: any) {
  return `${"0".repeat(len)}${this}`.slice(-len);
};
// eslint-disable-next-line no-extend-native
Date.prototype.format = function (f, utc?: boolean): string {
  if (!this.valueOf()) return " ";

  this.getUTCFullYear();
  const d = this;
  let h;
  if (utc) {
    return f.replace(
      /(yyyy|yy|MM|M|dd|d|E|NN|HH|hh|mm|ss|a\/p|fff|T)/gi,
      ($1): string => {
        switch ($1) {
          case "yyyy":
            return d.getUTCFullYear().toString();
          case "yy":
            return (d.getUTCFullYear() % 1000).pad(2);
          case "MM":
            return (d.getUTCMonth() + 1).pad(2);
          case "M":
            return (d.getUTCMonth() + 1).toString();
          case "dd":
            return d.getUTCDate().pad(2);
          case "d":
            return d.getUTCDate().toString();
          case "E": {
            // tslint:disable-next-line: max-line-length
            const weekName =
              util.currentLan === "ko"
                ? ["일", "월", "화", "수", "목", "금", "토"]
                : ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
            return weekName[d.getUTCDay()];
          }
          case "NN": {
            if (util.currentLan === "ko") {
              return `${(d.getUTCMonth() + 1).pad(2)}월`;
            } else {
              return [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ][d.getUTCMonth()];
            }
          }
          case "HH":
            return d.getUTCHours().pad(2);
          // eslint-disable-next-line
          case "hh":
            return ((h = d.getUTCHours() % 12) ? h : 12).pad(2);
          case "mm":
            return d.getUTCMinutes().pad(2);
          case "ss":
            return d.getUTCSeconds().pad(2);
          // tslint:disable-next-line: max-line-length
          case "A/P":
            return d.getUTCHours() < 12
              ? util.currentLan === "ko"
                ? "오전"
                : "AM"
              : util.currentLan === "ko"
              ? "오후"
              : "PM";
          case "a/p":
            return d.getUTCHours() < 12 ? "am" : "pm";
          case "fff":
            return d.getUTCMilliseconds().pad(3);
          case "T":
            return "T";
          case "Z":
            return "Z";
          default:
            return $1;
        }
      }
    );
  } else {
    return f.replace(
      /(yyyy|yy|MM|M|dd|d|E|NN|HH|hh|mm|ss|a\/p|fff|T)/gi,
      ($1): string => {
        switch ($1) {
          case "yyyy":
            return d.getFullYear().toString();
          case "yy":
            return (d.getFullYear() % 1000).pad(2);
          case "MM":
            return (d.getMonth() + 1).pad(2);
          case "M":
            return (d.getMonth() + 1).toString();
          case "dd":
            return d.getDate().pad(2);
          case "d":
            return d.getDate().toString();
          case "E": {
            // tslint:disable-next-line: max-line-length
            const weekName =
              util.currentLan === "ko"
                ? ["일", "월", "화", "수", "목", "금", "토"]
                : ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
            return weekName[d.getDay()];
          }
          case "NN": {
            if (util.currentLan === "ko") {
              return `${(d.getMonth() + 1).pad(2)}월`;
            } else {
              return [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ][d.getMonth()];
            }
          }
          case "HH":
            return d.getHours().pad(2);
          // eslint-disable-next-line
          case "hh":
            return ((h = d.getHours() % 12) ? h : 12).pad(2);
          case "mm":
            return d.getMinutes().pad(2);
          case "ss":
            return d.getSeconds().pad(2);
          case "A/P":
            return d.getHours() < 12
              ? util.currentLan === "ko"
                ? "오전"
                : "AM"
              : util.currentLan === "ko"
              ? "오후"
              : "PM";
          case "a/p":
            return d.getHours() < 12 ? "am" : "pm";
          case "fff":
            return d.getMilliseconds().pad(3);
          case "T":
            return "T";
          default:
            return $1;
        }
      }
    );
  }
};
const checkExpiryDate = (date: string) => {
  const expiryDate = new Date(date);
  expiryDate.setDate(expiryDate.getDate() + 1);
  const now = new Date();
  if (date == undefined || now >= expiryDate) {
    return false;
  }
  return true;
};
enum Permission {
  "STATEMENT" = "STATEMENT",
  "OTHER_STATEMENT" = "OTHER_STATEMENT",
  "ESTIMATE" = "ESTIMATE",
}
export const util = {
  currentLan: "ko",
  setCookie: (args: {
    cookieName: string;
    cookieValue: string;
    cookieExpire?: Date;
    cookieDomain?: string;
    cookiePath?: string;
    cookieSecure?: string;
  }) => {
    const {
      cookieName,
      cookieValue,
      cookieExpire,
      cookieDomain,
      cookiePath,
      cookieSecure,
    } = args;
    let cookieText = escape(cookieName) + "=" + escape(cookieValue);
    cookieText += cookieExpire ? "; EXPIRES=" + cookieExpire.toUTCString() : "";
    cookieText += cookiePath ? "; PATH=" + cookiePath : "";
    cookieText += cookieDomain ? "; DOMAIN=" + cookieDomain : "";
    cookieText += cookieSecure ? "; SECURE" : "";
    document.cookie = cookieText;
  },

  getCookie: (cookieName: string) => {
    let cookieValue = null;
    if (document.cookie) {
      const array = document.cookie.split(escape(cookieName) + "=");
      if (array.length >= 2) {
        const arraySub = array[1].split(";");
        cookieValue = unescape(arraySub[0]);
      }
    }
    return cookieValue;
  },

  deleteCookie: (cookieName: string) => {
    const temp = util.getCookie(cookieName);
    if (temp) {
      util.setCookie({
        cookieName,
        cookieValue: null!,
        cookieExpire: new Date(1),
        cookieDomain: process.env.REACT_APP_DOMAIN,
      });
    }
  },
  isLogin: () => {
    const token = util.getCookie(Cookies.authKey);
    if (token) {
      return true;
    }
    return false;
  },
  toJson: (object: any) => {
    return JSON.stringify(object, null, 2);
  },

  // 천단위 마다 콤마(,) 추가하는 함수
  addComma(value: any): string {
    let temp = value;
    if (typeof value === "string") {
      temp = value.replace(/,/gi, "");
    }
    temp = this.isNumber(temp);
    if (!temp) return "";

    return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  // 숫자 유무 판단
  isNumber(checkValue: any) {
    checkValue = "" + checkValue;
    if (isNaN(checkValue) || checkValue == "") {
      return;
    }
    return checkValue;
  },
  subtractionDateFromNow(date: Date) {
    return date.getTime() - new Date(new Date().format("yyyy-MM-dd")).getTime();
  },
  groupByDictionary(
    dict: { [key: string]: any },
    propertyName: string
  ): { [key: string]: any } {
    return _.mapValues(_.groupBy(dict, propertyName), (list) =>
      list.map((item) => _.omit(item, propertyName))
    );
  },
  async getIsPurchased() {
    const userInfoRes: any = await api.getMyInfo();
    const expiryDates = userInfoRes.data.expiry_dates;
    // expiryDates순회해서 현재 날짜보다 큰 것이 있는지 확인
    const isPurchased = expiryDates?.some((model: any) => {
      return checkExpiryDate(model.expiry_date);
    });
    return isPurchased;
  },
  downloadFile(url: string) {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  },
  transformObject: <T extends object, U extends object>(
    obj: T,
    transformFn: (key: string, value: any) => any
  ): U => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: transformFn(key, value)
      };
    }, {} as U);
  },
  snakeToCamel: (str: string): string => {
    return str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
  },

  transformSnakeToCamelCase: <T extends object, U extends object>(obj: T): U => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const camelKey = util.snakeToCamel(key);
      let camelValue = value;
      if (value && typeof value === 'object') {
        if (Array.isArray(value)) {
          camelValue = value.map(item => 
            typeof item === 'object' ? util.transformSnakeToCamelCase(item) : item
          );
        } else {
          camelValue = util.transformSnakeToCamelCase(value);
        }
      }
      return {
        ...acc,
        [camelKey]: camelValue
      };
    }, {} as U);
  },
};
