import React, { useState, useEffect, useContext } from 'react';
import { List, Typography, Button, Modal, Pagination, Radio, Spin, Input, message } from 'antd';
// import { getCategoryName, CategoryValue } from '../../constants/Categories';
// import { getPosts, Post, deletePost } from '../../services/postService';
import PostCard from '../Post/PostCard';
import styles from './CommunityPage.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import { Post } from '../../models/communityModel';
import { api } from '../../config/api';
import { useLocation } from 'react-router-dom';
import PostForm from '../Post/PostForm';
import AlertContext from '../../contexts/AlertContext';
import { useCategories } from '../../contexts/CategoryContext';
import { useHistory } from 'react-router-dom';
import { util } from '../../config/util';
import { pages } from '../../constants/PagePaths';

const { Title } = Typography;

const CommunityPage: React.FC = () => {
  const location = useLocation();
  const categoryId = parseInt(location.pathname.split("/")[2], 10);
  const { categories, isLoading: isCategoryLoading } = useCategories();
  const [category, setCategory] = useState<any>(null);
  const [posts, setPosts] = useState<Post[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortType, setSortType] = useState<'latest' | 'popular'>('latest');
  const [isLoading, setIsLoading] = useState(true);
  const pageSize = 5;
  const [searchTerm, setSearchTerm] = useState('');
  const [totalPosts, setTotalPosts] = useState(0);
  const alert = useContext(AlertContext);
  const history = useHistory();

  useEffect(() => {
    if (!isCategoryLoading && categories.length > 0) {
      const foundCategory = categories.find(cat => cat.id === categoryId);
      setCategory(foundCategory || null);
    }
  }, [categories, categoryId, isCategoryLoading]);

  const fetchPosts = async (categoryId: number, offset: number, limit: number, search?: string, sortType?: 'latest' | 'popular') => {
    setIsLoading(true);
    try {
      const response = await api.getCommunityPosts(categoryId, offset, limit, search, sortType);
      setPosts(response.posts);
      setTotalPosts(response.total);
    } catch (error) {
      console.error('Failed to fetch posts:', error);
      if ((error as any).response?.status === 401) {
        alert.showAlert('로그인이 필요합니다.');
      } else {
        alert.showAlert('게시글을 불러오는데 실패했습니다.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (category) {
      const offset = (currentPage - 1) * pageSize;
      fetchPosts(category.id, offset, pageSize, searchTerm, sortType);
    }
  }, [category, currentPage, pageSize, searchTerm, sortType]);

  const handleSubmit = async (newPost: FormData) => {
    const createdPost = await api.createCommunityPost(newPost)
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          alert.showAlert('로그인이 필요합니다.');
        } else if (error.response && error.response.status === 403) {
          alert.showAlert('로그인 상태를 확인해주세요.');
        } else {
          alert.showAlert('게시글 작성에 실패했습니다.');
        }
        return null;
      });
    
    if (createdPost) {
      // 새 게시글을 추가하고 페이지를 첫 번째 페이지로 리셋합니다.
      setCurrentPage(1);
      fetchPosts(category?.id || '', 0, pageSize);
      setIsModalVisible(false);
      message.success('게시글이 작성되었습니다.');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSortChange = (e: any) => {
    setSortType(e.target.value);
    setCurrentPage(1);
    if (category) {
      fetchPosts(category.id, 0, pageSize, searchTerm, e.target.value);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleWriteButtonClick = () => {
    try {
      if (!util.isLogin()) {
        message.warning('로그인이 필요한 서비스입니다.');
        history.push(pages.login);
        return;
      }
      
      setIsModalVisible(true);
    } catch (error) {
      console.error('Error checking auth status:', error);
      message.error('오류가 발생했습니다.');
    }
  };

  if (isCategoryLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" />
      </div>
    );
  }

  if (!category) {
    return (
      <div>
        <p>카테고리를 찾을 수 없습니다. (ID: {categoryId})</p>
        <p>Available categories: {JSON.stringify(categories)}</p>
      </div>
    );
  }

  return (
    <div className={styles.communityPageContainer}>
      <div className={styles.communityPageHeader}>
        <Title level={2} className={styles.categoryTitle}>{category.name}</Title>
      </div>
      <div className={styles.controlsContainer}>
        <div className={styles.searchContainer}>
          <Input
            placeholder="검색어를 입력하세요"
            prefix={<SearchOutlined className={styles.searchIcon} />}
            onPressEnter={(e) => handleSearch((e.target as HTMLInputElement).value)}
            onChange={(e) => {
              if (!e.target.value) {
                handleSearch('');
              }
            }}
            className={styles.searchInput}
            allowClear
          />
        </div>
        <Radio.Group onChange={handleSortChange} value={sortType} buttonStyle="solid">
          <Radio.Button value="latest" className={styles.sortButton}>최신순</Radio.Button>
          <Radio.Button value="popular" className={styles.sortButton}>인기순</Radio.Button>
        </Radio.Group>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <List
            className={styles.postList}
            itemLayout="vertical"
            dataSource={posts}
            renderItem={(post: Post) => (
              <List.Item>
                <PostCard
                  id={post.id}
                  title={post.title}
                  author={post.author}
                  date={post.createdAt}
                  likes={post.likes}
                  comments={post.commentCount}
                  category={category.id}
                />
              </List.Item>
            )}
          />
          <div className={styles.writeButtonContainer}>
            <Button 
              type="primary" 
              onClick={handleWriteButtonClick} 
              className={styles.writeButton}
            >
              글쓰기
            </Button>
          </div>
          <div className={styles.paginationContainer}>
            <Pagination
              current={currentPage}
              total={totalPosts}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
      <Modal
        title={`${category.name} 글쓰기`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className={styles.writeModal}
        width={900}
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
        zIndex={1200}
        mask={true}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
        getContainer={() => document.body}
      >
        <PostForm onSubmit={handleSubmit} category={category.id}/>
      </Modal>
    </div>
  );
};

export default CommunityPage;
