import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { LevelTestResContext } from "../../contexts/LevelTestContext";
import Timer from "./components/Timer";

export default () => {
  const levelTestRes = useContext(LevelTestResContext);
  const history = useHistory();
  const [levelTestAnswer, setLevelTestAnswer] = useState(0);
  const levelTestAnswerTimerRef = useRef<HTMLInputElement | null>(null);

  const next = () => {
    levelTestRes.setLevelAnswer(
      10,
      levelTestAnswer,
      levelTestAnswerTimerRef.current!.value
    );
    history.push(pages.leveltest + "/complete");
  };

  const previous = () => {
    history.push(pages.leveltest + "/9");
  };
  return (
    <main id="wrap">
      <div className="progress top-progress">
        <div
          className="progress-bar "
          role="progressbar"
          aria-label="Basic example"
          style={{ width: "100%" }}
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <section className="bg-light-md">
        <div className="container page py-120">
          <div className="form-wrap col-md-7 mx-auto ">
            <form>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="badge body2 text-bg-secondary">Question.10</div>
                <Timer forwardedRef={levelTestAnswerTimerRef} />
              </div>
              <h4 className="text-dark mb-5">
                다음 내용의 서류는 무엇인지 보기를 보고 선택하세요.
              </h4>
              <div className="mb-5">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="text-center bg-gray-50" rowspan="3">
                        노무비
                      </td>
                      <td>직접 노무비</td>
                    </tr>
                    <tr>
                      <td>간접 노무비</td>
                    </tr>
                    <tr>
                      <td>소계</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="d-flex flex-wrap gap-3 mb-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option3-1"
                    value={0}
                    checked={levelTestAnswer === 0}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option3-1"
                  >
                    설계내역서
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option3-2"
                    value={1}
                    checked={levelTestAnswer === 1}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option3-2"
                  >
                    원가계산서
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option3-3"
                    value={2}
                    checked={levelTestAnswer === 2}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option3-3"
                  >
                    일위대가표
                  </label>
                </div>
              </div>
              <div className="mo-fixed-bottom w-100 d-flex gap-3 py-3">
                <a
                  onClick={() => {
                    previous();
                  }}
                  className="btn btn-lg btn-light flex-1"
                >
                  <span className="material-symbols-outlined me-2">
                    arrow_left_alt
                  </span>
                  <span className="pe-3">이전</span>
                </a>
                <a
                  onClick={() => {
                    next();
                  }}
                  className="btn btn-lg btn-primary-soft flex-1"
                >
                  <span className="ps-3">완료</span>
                  <span className="material-symbols-outlined ms-2">
                    arrow_right_alt
                  </span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};
