import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function FreelyContract(props) {
  const worklist = props.worklist;
  if(!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table className="bbb" style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td className="doc-title" style={{ fontSize: "50px", textAlign: "center", height: "150px" }} colspan="4">각 　　　　서</td>
            </tr>

            <tr style={{ borderBottom: "hidden", height: "60px" }}>
              <td colspan="4">　</td>
            </tr>
          </table>
          <table className="aaa" style={{ border: "1", tableLayout: "fixed", textAlign: "center" }}>
            <tr>
              <td style={{ height: "50px", width: "100px" }}>
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>업</span>
                  <span>체</span>
                  <span>명</span>
                </span>
              </td>
              <td>{worklist?.companyName}</td>
              <td style={{ width: "100px" }}>
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>소</span>
                  <span>재</span>
                  <span>지</span>
                </span>
              </td>
              <td>{worklist?.businessAddress}</td>
            </tr>
            <tr>
              <td style={{ height: "50px" }}>
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>대</span>
                  <span>표</span>
                  <span>자</span>
                </span>
              </td>
              <td style={{ letterSpacing: "10px " }}>{worklist?.representativeName}</td>
              <td>
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>업</span>
                  <span>종</span>
                  <span>(등</span>
                  <span>록)</span>
                </span></td>
              <td>{worklist?.typeOfBusiness}</td>
            </tr>
          </table>
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden", height: "120px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px", textIndent: "5px", wordBreak: "keep-all", fontSize: "15px" }} colspan="4">상기 본인(법인)은 귀 청과 수의계약을 체결함에 있어서 붙임 결격사유중 어느 사유에도 해당되지 않으며 차후에 이러한 사실이 발견된 경우 계약의 해지(해제) 및 부정당업자의 제재처분을 받아도 하등의 이유를 제기하지 않겠습니다.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "100px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ textAlign: "center", fontSize: "15px" }}>
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "100px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "110px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>

        <div className="page-doc">
          <div style={{marginBottom: "5px"}}>{`< 붙 임 >`}</div>
          <table>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "50px", paddingLeft: "10px", height: "80px" }}>ⓐ 수의계약 견적서 제출일 현재 부도·파산·해산·영업정지 등이 확정된 사실</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "50px", paddingLeft: "10px", height: "130px" }}>ⓑ 「건설산업기본법」에 의한 일반공사 또는 「엔지니어링기술진흥법」에 의한 기술용역의 경우 수의계약 견적서 제출일 현재 기술자 보유 현황이 당해공사 시공에 필요한 업종등록 기준에 미달하는 사실</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "50px", paddingLeft: "10px", height: "80px" }}>ⓒ 부정당업자 제재가 진행중인 사실</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "50px", paddingLeft: "10px", height: "180px" }}>ⓓ 수의계약 안내공고일 기준 최근 1년 이내에 지방계약법 시행령 제 92조 또는 다른 법령의 규정에 의하여 부실시공, 담합행위, 입찰 및 계약서류 위조 또는 허위제출, 입·낙찰 또는 계약이행 관련 뇌물제공자로서 부정당업자 제재처분을 받은 사실이 있는 자</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "50px", paddingLeft: "10px", height: "180px" }}>ⓔ 수의계약 안내공고일 기준 최근 1년 이내에 당해 자치단체의 입찰 및 계약, 계약이행 과정에서 10일이상 계약이행 지연, 시공과정에 정당한 이행명령 거부, 하자보증기간내 5회 이상 하자보수, 기타 당해 자치단체와의 계약이행 과정에서 물의를 일으키는 등 신용이 떨어져 계약체결이 곤란하다고 판단되는자</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "50px", paddingLeft: "10px", height: "130px" }}>ⓕ 수의계약 안내공고일 기준 최근 1년 이내에 당해 자치단체 계약체결과 관련하여 계약에 응하지 않거나, 포기서를 제출한 사실이 있거나 계약이행도중 정당한 이유없이 포기서를 제출한 사실이 있는 자</td>
            </tr>
            <tr>
              <td style={{ lineHeight: "50px", paddingLeft: "10px", height: "80px" }}>ⓖ 수의계약체결일 현재 지방계약법 제 33조에 해당되는 자</td>
            </tr>
          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default FreelyContract;