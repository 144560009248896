import { Checkbox } from "antd";
import GA4React, { useGA4React } from "ga-4-react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../../../config/api";
import { util } from "../../../../config/util";
import { Cookies } from "../../../../constants/Cookies";
import { pages, videoPages } from "../../../../constants/PagePaths";
import AlertContext from "../../../../contexts/AlertContext";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import styles from "./videoLogin.module.scss";

interface Props {
  checkUser: () => void;
}

const VideoLogin = (props: Props) => {
  const [autoLogin, setAutoLogin] = useState(false);
  const [saveId, setSaveId] = useState(
    util.getCookie(Cookies.loginId) ? true : false
  );
  const [id, setId] = useState<string>(util.getCookie(Cookies.loginId) || "");
  const [pw, setPw] = useState<string>();
  const history = useHistory();
  const { showAlert } = useContext(AlertContext);
  // const ga4 = useGA4React();

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (key) {
      history.replace(videoPages.index);
    }
  }, []);

  const changeAutoLogin = () => {
    setAutoLogin(!autoLogin);
  };
  const changeSaveId = () => {
    setSaveId(!saveId);
  };
  const changeId = (event: any) => {
    const value: any = event.target.value;
    if (value.length <= 100) setId(value);
  };
  const changePw = (event: any) => {
    const value: any = event.target.value;
    if (value.length <= 100) setPw(value);
  };
  const login = async () => {
    if (!id || !pw) {
      showAlert("아이디 혹은 비밀번호를 입력해주세요.");
      return;
    }
    try {
      const res = await api.loginWithOption(
        id || "",
        pw || "",
        autoLogin,
        saveId
      );
      const status = res.status;
      if (status === 200) {
        props?.checkUser?.();
        history.replace(videoPages.index);
        // if (ga4 instanceof Object) {
        //   ga4.gtag("config", process.env.REACT_API_GTAG_ID, {
        //     user_id: res.data.key,
        //   });
        // }
      }
    } catch (error: any) {
      if (error.hasOwnProperty("response")) {
        const status = error.response?.status;
        if (status === 400) {
          showAlert("아이디/비밀번호가 맞지 않습니다.", undefined, true);
        } else {
          showAlert("에러가 발생하였습니다.");
        }
      } else {
        console.log(error);
        showAlert("에러가 발생하였습니다.");
      }
    }
  };

  const handleFindAccount = () => {
    history.push(videoPages.findaccount);
    // navigate('/video/findaccount')
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      login();
    }
  };

  return (
    <React.Fragment>
      <div className={styles.app_container} onKeyPress={handleKeyPress}>
        <div className={styles.login_container}>
          <div className={styles.title}>로그인</div>
          <div className={styles.des}>
            로그인 후<br />
            무료체험 강의 서비스를 이용해 보세요.
          </div>
          <div style={{ width: "100%" }}>
            <input
              placeholder="아이디(이메일)"
              value={id || ""}
              onChange={changeId}
              maxLength={100}
            />
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <input
              type="password"
              placeholder="비밀번호"
              value={pw || ""}
              onChange={changePw}
              maxLength={100}
            />
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Checkbox checked={autoLogin} onChange={changeAutoLogin}>
              자동로그인
            </Checkbox>
            <Checkbox checked={saveId} onChange={changeSaveId}>
              아이디 저장
            </Checkbox>
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <button className="video" onClick={login}>
              로그인
            </button>
            <Link to={"/video/findaccount"}>
              <div
                style={{ width: "100%", marginTop: "50px" }}
                className={styles.forget}
              >
                <span
                  onClick={() =>
                    // console.log("clicked ID")
                    handleFindAccount
                  }
                >
                  아이디
                </span>
                &nbsp;&nbsp;&nbsp;혹은&nbsp;&nbsp;&nbsp;
                <span
                  onClick={() =>
                    // console.log("clicked PW")
                    handleFindAccount
                  }
                >
                  비밀번호
                </span>
                를 잊으셨나요?
              </div>
            </Link>

            <Link to={"/video/signUp"}>
              <button className={styles.signUp}>회원가입</button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VideoLogin;
