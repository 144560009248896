import { useState } from 'react';

function DeputyinputInfo(props) {
  const worklist = props.worklist;

  //수정, 저장 버튼에 대한 동작.
  const [input, setinput] = useState(true);

  const changeInput = (e) => {
    e.preventDefault();
    if (input === false) {
      setinput(!input);
    }
  };

  const handleSubmitAsync = async (e) => {
    let data = {};
    for (let index = 0; index < e.target.length; index++) {
      const element = e.target[index];
      data[element.name] = element.value;
    }
    data = {
      ...props.worklist,
      ...data,
    };
    e.preventDefault();
    if (input === true) {
      setinput(!input);
    }
    await props.putWorkListAsync(data);
  };

  return (
    <div>
      <div className="doc-container-box">
        <form
          name="infoform"
          onSubmit={handleSubmitAsync}
        >
          <div className="doc-header">
            <h1>현장대리인 정보</h1>
          </div>
          <div>
            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 성명
              </div>
              <input
                defaultValue={worklist?.deputyName}
                className="doc-submit-input"
                placeholder="ex) 이ㅇㅇ"
                disabled={!input}
                name="deputyName"
                maxLength="10"
              />
            </div>

            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 주민번호
              </div>
              <input
                defaultValue={worklist?.deputyResidentNum}
                className="doc-submit-input"
                placeholder="ex) 000000-0000000"
                disabled={!input}
                name="deputyResidentNum"
                maxLength="14"
              />
            </div>
            <div className="info_sign">
              ( - )를 포함하여 입력해주세요.
            </div>

            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 주소
              </div>
              <input
                defaultValue={worklist?.deputyAddress}
                className="doc-submit-input"
                placeholder="ex) 서울시 강서구 등촌동 ㅇㅇㅇ"
                disabled={!input}
                name="deputyAddress"
                maxLength="100"
              />
            </div>

            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 직위
              </div>
              <input
                defaultValue={worklist?.deputyPosition}
                className="doc-submit-input"
                placeholder="ex) 과장 / ex) 부장"
                disabled={!input}
                name="deputyPosition"
                maxLength="10"
              />
            </div>

            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 종목
              </div>
              <input
                defaultValue={worklist?.deputyEvent}
                className="doc-submit-input"
                placeholder="ex) 조경기능사 / ex) 토목산업기사 / ex) 건축기사"
                disabled={!input}
                name="deputyEvent"
                maxLength="10"
              />
            </div>

            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 등급
              </div>
              <input
                defaultValue={worklist?.deputyGrade}
                className="doc-submit-input"
                placeholder="ex) 초급 / ex) 중급 / ex) 고급"
                disabled={!input}
                name="deputyGrade"
                maxLength="10"
              />
            </div>

            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 등록번호
              </div>
              <input
                defaultValue={worklist?.deputyRegistNum}
                className="doc-submit-input"
                placeholder="자격증등록번호를 입력하세요."
                disabled={!input}
                name="deputyRegistNum"
                maxLength="20"
              />
            </div>
            <div className="info_sign">( - )를 포함하여 입력해주세요.</div>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 취득일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.deputyGetDate}
                disabled={!input}
                name="deputyGetDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>
            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 갱신일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.deputyRenewDate}
                disabled={!input}
                name="deputyRenewDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>
            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 입사날짜
              </div>
              <input
                type="date"
                defaultValue={worklist?.deputyJoinDate}
                disabled={!input}
                name="deputyJoinDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>
            <div className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                현장대리인 근무처
              </div>
              <input
                defaultValue={worklist?.deputyWorkPlace}
                className="doc-submit-input"
                placeholder="현장대리인 근무처를 입력해주세요."
                disabled={!input}
                name="deputyWorkPlace"
                maxLength="10"
              />
            </div>
          </div>

          <div className="button_container">
            <button className={`doc_button_modi ${!input ? '' : 'btn_hidden'}`} onClick={changeInput}>
              <span style={{ fontSize: "15px", color: "white" }}>수정</span>
            </button>
            <button className={`doc_submit ${input ? '' : 'btn_hidden'}`} htmlType="submit">
              <span style={{ fontSize: "15px", color: "white" }}>저장</span>
            </button>
          </div>
        </form>
      </div>
    </div >
  );
}

export default DeputyinputInfo;