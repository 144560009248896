import { Dialog } from "@material-ui/core";
import React, { useRef, useState } from "react";
import ConfirmAlert from "../components/modal/alert/ConfirmAlert";
import DocPrintAlert from "../components/modal/alert/DocPrintAlert";
import SimpleAlert from "../components/modal/alert/SimpleAlert";
import ContentModal from "../components/modal/contentModal/ContentModal";
import PromptModal from "../components/modal/promptModal/PromptModal";
import TextContentModal from "../components/modal/textContentModal/TextContentModal";
import VideoChoiceAlert from "../pages/video/modal/alert/VideoChoiceAlert";
import VideoConfirmAlert from "../pages/video/modal/alert/VideoConfirmAlert";

interface AlertContextProps {
  showAlert: (
    message: string,
    onConfirm?: () => void,
    isEnterClose?: boolean
  ) => void;
  showTextModal: (message: string, onConfirm?: () => void) => void;
  showDocPrintAlert: (
    title: string,
    message: string,
    okText: string,
    cancelText: string,
    onOk?: () => void
  ) => void;
  showConfirmAlert: (
    title: string,
    message: string,
    okText: string,
    cancelText: string,
    onOk?: () => void
  ) => void;
  showContentModal: (
    child: React.ReactNode,
    size?: { width?: string; height?: string },
    onConfirm?: () => void
  ) => void;
  showPrompt: (
    title: string,
    defulatValue?: string,
    onConfirm?: (value?: string) => void
  ) => void;
  closeAlert: () => void;

  //강의사이트 로그인 안내 모달
  videoShowAlert: (
    message: string,
    onConfirm?: () => void,
    isEnterClose?: boolean
  ) => void;

  videoConfirmAlert: (
    title: string,
    message: string,
    okText: string,
    cancelText: string,
    onOk?: () => void
  ) => void;
}

export const AlertContext = React.createContext<AlertContextProps>(undefined!);

export interface AlertContextProviderProps {
  children?: React.ReactNode;
}

enum AlertCase {
  Normal,
  Confirm,
  DocPrint,
  TextModal,
  Content,
  Prompt,

  //강의사이트 로그인 확인 모달
  videoConfirm,
  videoChoice,
}

export const AlertContextProvider = ({
  children,
}: AlertContextProviderProps) => {
  const [alertCase, setAlertCase] = useState<AlertCase>();
  const [isOpen, setIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [confirmHandler, setConfirmHandler] =
    useState<() => (() => void) | undefined>();
  const [size, setSize] = useState<{ width?: string; height?: string }>();
  const [content, setContent] = useState<React.ReactNode>();
  const [alertTitle, setAlertTitle] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [okText, setOkText] = useState("");
  const [okHandler, setOkHandler] =
    useState<(value?: any) => ((value?: any) => any) | undefined>();
  const [isEnterClose, setIsEnterClose] = useState<boolean | undefined>(false);
  const promptTitle = useRef("");
  const promptDefaultValue = useRef("");
  const resolveRef = useRef<(value?: unknown) => void>(() => {});


  const waitModalClose = async () => {
    await new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const showAlert = async (
    message: string,
    onConfirm?: () => void,
    isEnterClose?: boolean
  ) => {
    setAlertCase(AlertCase.Normal);
    setConfirmHandler(() => onConfirm);
    setAlertMessage(message);
    setIsFullWidth(false);
    setIsOpen(true);
    setIsEnterClose(isEnterClose);
    await waitModalClose();
  };

  const showTextModal = async (message: string, onConfirm?: () => void) => {
    setAlertCase(AlertCase.TextModal);
    setConfirmHandler(() => onConfirm);
    setAlertMessage(message);
    setIsFullWidth(false);
    setIsOpen(true);
    await waitModalClose();
  };

  const closeAlert = async () => {
    setIsOpen(false);
    setAlertMessage("");
    setConfirmHandler(undefined);
    setContent(undefined);
    setAlertTitle("");
    setCancelText("");
    setOkText("");
    setOkHandler(undefined);
    setIsFullWidth(false);
    setAlertCase(undefined);
    resolveRef.current(true);
  };

  const showConfirmAlert = async (
    title: string,
    message: string,
    okText: string,
    cancelText: string,
    onOk?: () => void
  ) => {
    setOkHandler(() => onOk);
    setAlertCase(AlertCase.Confirm);
    setAlertMessage(message);
    setAlertTitle(title);
    setOkText(okText);
    setCancelText(cancelText);
    setIsFullWidth(false);
    setIsOpen(true);
    await waitModalClose();
  };

  const showDocPrintAlert = async (
    title: string,
    message: string,
    okText: string,
    cancelText: string,
    onOk?: () => void
  ) => {
    setOkHandler(() => onOk);
    setAlertCase(AlertCase.DocPrint);
    setAlertMessage(message);
    setAlertTitle(title);
    setOkText(okText);
    setCancelText(cancelText);
    setIsOpen(true);
    setIsFullWidth(true);
    await waitModalClose();
  };

  const showContentModal = async (
    child: React.ReactNode,
    size?: { width?: string; height?: string },
    onConfirm?: () => void
  ) => {
    setIsOpen(true);
    setIsFullWidth(false);
    setAlertCase(AlertCase.Content);
    setContent(child);
    setSize(size);
    setOkHandler(() => onConfirm);
    await waitModalClose();
  };

  const showPrompt = async (
    title: string,
    defaultValue?: string,
    onConfirm?: (value?: string) => void
  ) => {
    promptTitle.current = title;
    promptDefaultValue.current = defaultValue ?? "";
    setIsOpen(true);
    setIsFullWidth(false);
    setAlertCase(AlertCase.Prompt);
    setSize(size);
    setOkHandler(() => onConfirm);
    await waitModalClose();
  };

  const handleAlertClose = () => {
    setIsOpen(false);
    setIsFullWidth(false);
    setAlertCase(undefined);
    confirmHandler?.();
    resolveRef.current(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    confirmHandler?.();
    resolveRef.current(true);
    setConfirmHandler(undefined)
  };

  const handlePromptConfirm = (value?: string) => {
    setIsOpen(false);
    okHandler?.(value);
    resolveRef.current(true);
  };

  const handleOk = () => {
    setIsOpen(false);
    okHandler?.();
    resolveRef.current(true);
  };

  const handleKeyDown = (e: any) => {
    if (isEnterClose && e.key === "Enter") {
      closeAlert();
    }
  };

  const videoShowAlert = async (
    message: string,
    onConfirm?: () => void,
    isEnterClose?: boolean
  ) => {
    setAlertCase(AlertCase.videoConfirm);
    setConfirmHandler(() => onConfirm);
    setAlertMessage(message);
    setIsFullWidth(false);
    setIsOpen(true);
    setIsEnterClose(isEnterClose);
    await waitModalClose();
  };

  const videoConfirmAlert = async (
    title: string,
    message: string,
    cancelText: string,
    okText: string,
    onOk?: () => void
  ) => {
    setOkHandler(() => onOk);
    setAlertCase(AlertCase.videoChoice);
    setAlertMessage(message);
    setAlertTitle(title);
    setOkText(okText);
    setCancelText(cancelText);
    setIsFullWidth(false);
    setIsOpen(true);
    await waitModalClose();
  };

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        showTextModal,
        showConfirmAlert,
        showDocPrintAlert,
        showContentModal,
        showPrompt,
        closeAlert,

        //강의사이트 로그인 확인 모달
        videoShowAlert,
        videoConfirmAlert,
      }}
    >
      {children}
      {isOpen && (
        <Dialog
          fullWidth={isFullWidth}
          maxWidth={isFullWidth && "lg"}
          open={isOpen}
          onClose={handleAlertClose}
          onKeyDown={handleKeyDown}
        >
          {alertCase === AlertCase.Normal && (
            <SimpleAlert text={alertMessage} onClickConfirm={handleConfirm} />
          )}
          {alertCase === AlertCase.TextModal && (
            <TextContentModal
              contents={alertMessage}
              onConfirm={handleConfirm}
              onClose={handleAlertClose}
            />
          )}
          {alertCase === AlertCase.Confirm && (
            <ConfirmAlert
              title={alertTitle}
              text={alertMessage}
              onClickCancel={handleAlertClose}
              onClickOk={handleOk}
              cancelText={cancelText}
              okText={okText}
            />
          )}
          {alertCase === AlertCase.DocPrint && (
            <DocPrintAlert
              title={alertTitle}
              text={alertMessage}
              onClickCancel={handleAlertClose}
              onClickOk={handleOk}
              cancelText={cancelText}
              okText={okText}
            />
          )}
          {alertCase === AlertCase.Content && (
            <ContentModal size={size} onConfirm={handleOk}>
              {content}
            </ContentModal>
          )}
          {alertCase === AlertCase.Prompt && (
            <PromptModal
              onClose={handlePromptConfirm}
              title={promptTitle.current}
              defaultValue={promptDefaultValue.current}
            >
              {content}
            </PromptModal>
          )}
          {alertCase === AlertCase.videoConfirm && (
            <VideoConfirmAlert
              text={alertMessage}
              onClickConfirm={handleConfirm}
            />
          )}
          {alertCase === AlertCase.videoChoice && (
            <VideoChoiceAlert
              title={alertTitle}
              text={alertMessage}
              onClickCancel={handleAlertClose}
              onClickOk={handleOk}
              cancelText={cancelText}
              okText={okText}
            />
          )}
        </Dialog>
      )}
    </AlertContext.Provider>
  );
};

export default AlertContext;
