import styles from "./videoConfirmAlert.module.scss"


interface Props {
  text: string;
  onClickConfirm: () => void;
}

export default (props: Props) => {
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.alerts}>
          <div className={styles.title}>알림</div>
          <div className={styles.content}>{props.text}</div>
          <div className={styles.btn_wrapper}>
            <div onClick={props.onClickConfirm}>확인</div>
          </div>
        </div>
      </div>
    </div>
  );
};

