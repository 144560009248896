/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { FreeUseDay } from "../../constants/General";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { CategoryItem } from "../../models/CategoryItem";
import { LectureCategoryModel, LectureItem } from "../../models/LectureModel";
import LectureRoomStudyPresenter from "./LectureRoomStudyPresenter";

interface Props {}

export default (props: Props) => {
  const [lecturesData, setLecturesData] = useState<LectureItem[]>();
  const [previewLectures, setPreviewLectures] = useState<LectureItem[]>();
  const [lectureCategories, setLectureCategories] = useState<CategoryItem[]>();
  const [selectedLecture, setSelectedLecture] = useState<LectureItem>();
  const [playlist] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [categoryIndex] = useState(+location.pathname.split("/")[2]);
  const alert = useContext(AlertContext);

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " +
          FreeUseDay +
          "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
      return;
    }

    new Promise(async () => {
      const lecturesResponse = await api.getLectureAsync();
      const lecturesData: LectureItem[] = lecturesResponse.data.lectures.map(
        (lecture: any) => {
          return {
            id: lecture.id,
            thumbnailUrl: lecture.thumbnail_url,
            description: lecture.description,
            link: lecture.link,
            created: lecture.created,
            categoryId: lecture.category_id,
            order: lecture.order,
            title: lecture.name,
          };
        }
      );
      setLecturesData(lecturesData);

      const categoryRes = await api.getLectureCategoryAsync();
      const categories = categoryRes.data.lecture_categories
        ?.sort((a: any, b: any) => a.order - b.order)
        .map((lectureCategory: LectureCategoryModel) => {
          return {
            title: lectureCategory.name,
            description: lectureCategory.description,
            id: lectureCategory.id,
          };
        });
      setLectureCategories(categories);
      refreshLectureList(lecturesData, categories);
    });
  }, []);

  useEffect(() => {
    refreshLectureList(lecturesData, lectureCategories);
  }, [location]);

  const handleClickCategory = (index: number) => {
    history.push(`${pages.lectureroom}/${index}`);
  };

  const handleClickOtherLecture = (id: number) => {
    history.push(`${pages.lectureroom}/${categoryIndex}/${id}`);
  };

  const refreshLectureList = (
    data?: LectureItem[],
    categories?: CategoryItem[]
  ) => {
    const tempData = data || lecturesData;
    const id = +location.pathname.split("/")[3];
    const selectedLecture = tempData?.find((lecture) => lecture.id === id);
    setSelectedLecture(selectedLecture);
    setPreviewLectures(
      tempData?.filter(
        (lecture) =>
          lecture.categoryId === categories?.[categoryIndex].id &&
          selectedLecture?.id !== lecture.id
      )
    );
  };

  return (
    <LectureRoomStudyPresenter
      selectedCategoryIndex={categoryIndex}
      categories={lectureCategories}
      lectures={previewLectures}
      onClickCategory={handleClickCategory}
      selectedLecture={selectedLecture}
      playlist={playlist}
      onClickOtherLecture={handleClickOtherLecture}
    />
  );
};
