import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "../../config/api";
import { pages } from "../../constants/PagePaths";
import { LevelTestResContext } from "../../contexts/LevelTestContext";

export default () => {
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [isNotCorrect, setIsNotCorrect] = useState(false);
  const levelTestRes = useContext(LevelTestResContext);
  const corrects = [0, 1, 2, 0, 1, 2, 0, 1, 2, 0];

  const clearTime = () => {
    let timers: string[] = [];
    for (const key of levelTestRes.levelAnswerTimers.keys()) {
      timers.push(levelTestRes.levelAnswerTimers.get(key)!);
    }
    return addTime(timers);
  };

  const correctCount = () => {
    let count = 0;
    for (let i = 0; i < corrects.length; i++) {
      if (corrects[i] == levelTestRes.levelAnswers.get(i + 1)) {
        count++;
      }
    }
    return count;
  };

  function addTime(times: string[]) {
    // 분과 초를 저장할 변수 초기화
    let totalMinutes = 0;
    let totalSeconds = 0;

    // 각 매개변수에 대해 반복하여 분과 초를 더함
    for (const time of times) {
      const [min, sec] = time.split(":").map(Number);
      totalMinutes += min;
      totalSeconds += sec;
    }

    // 초가 60 이상인 경우 분에 추가
    totalMinutes += Math.floor(totalSeconds / 60);
    // 초는 60으로 나눈 나머지 값
    const seconds = totalSeconds % 60;

    // 결과를 "분:초" 형식의 문자열로 반환
    return `${String(totalMinutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  }

  function render(): JSX.Element {
    // Map의 각 항목을 순회하면서 JSX를 생성
    const items: JSX.Element[] = [];
    for (const [key, value] of levelTestRes.levelAnswers) {
      //오답만 체크가 됐을때
      if (
        isNotCorrect &&
        corrects[key - 1] == levelTestRes.levelAnswers.get(key)
      ) {
      } else {
        items.push(
          <div
            className={
              corrects[key - 1] == levelTestRes.levelAnswers.get(key)
                ? "commentary true mt-4"
                : "commentary false mt-4"
            }
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="badge body2 text-bg-secondary">
                Question.{key}
              </div>
              <div className="seconds">
                <span>{levelTestRes.levelAnswerTimers.get(key)}</span>초
              </div>
            </div>
            <div className="comment">문제 풀이 결과</div>
            {corrects[key - 1] == levelTestRes.levelAnswers.get(key) ? (
              <div className="text-end text-success">
                <span className="material-symbols-outlined">done</span>
              </div>
            ) : (
              <div className="text-end text-danger">
                <span className="material-symbols-outlined">close</span>
              </div>
            )}
          </div>
        );
      }
    }

    // JSX를 반환
    return <>{items}</>;
  }

  useEffect(() => {
    if (levelTestRes.levelAnswers.size < 10) {
      history.replace(pages.leveltest + "/0");
    }

    new Promise(async () => {
      try {
        const res = await api.getMyInfo();
        setUserName(res.data.last_name);
      } catch (error) {
        console.error(error);
        history.replace(pages.index);
      }
    });

    var options = {
      series: [correctCount() * 10],
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "80%",
          },
        },
      },
      labels: [" "],
    };

    var chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();

    const minute = parseInt(clearTime().split(":")[0]);
    const second = parseInt(clearTime().split(":")[1]);

    const percent =
      minute * 60 + second / 240 > 1 ? 100 : minute * 60 + (second / 240) * 100;

    // minute *  / 240초
    var options = {
      series: [percent],
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "80%",
          },
        },
      },
      labels: [" "],
    };

    var chart = new ApexCharts(document.querySelector("#chart2"), options);
    chart.render();
  }, []);

  return (
    <main id="wrap" className="bg-light">
      <section className="bg-light-md">
        <div className="container page pt-5 pb-8">
          <div className="top-comment col-md-7">
            <img
              src="/images/ddusul/3d-rank.png"
              data-aos="fade-up"
              data-aos-duration="1000"
              className="aos-init aos-animate"
            />
            <h2>
              <div className="avatar-name-lg">
                <span className="avatar-img">
                  <img src="/images/ddusul/icon/level1.png" />
                </span>
                <span>{userName}</span>
              </div>
              님 수고하셨어요!
            </h2>
          </div>
          <div className="complete-box col-md-7 mx-auto mb-3">
            <div className="complete-timer">
              <div className="complete-title">문제 풀이 소요 시간</div>
              <div className="complete-time">
                <span className="material-symbols-outlined me-1">timer</span>
                {clearTime()}
              </div>
            </div>
          </div>

          <div className="complete-box col-md-7 mx-auto mb-3">
            <div className="complete-title">레벨테스트 결과</div>
            <div className="chart-wrap">
              <div className="flex-1">
                <div id="chart" style={{ minHeight: 328.7 }}>
                  <div className="count-q">
                    <span className="active">{correctCount()}</span>/
                    <span>10</span>
                  </div>
                </div>
                <div className="SubTitle2 text-dark text-center">정답 갯수</div>
              </div>
              <div className="flex-1">
                <div id="chart2" style={{ minHeight: 328.7 }}>
                  <div className="count-q">
                    <span className="active">{clearTime().split(":")[0]}</span>:
                    <span>{clearTime().split(":")[1]}</span>
                  </div>
                </div>
                <div className="SubTitle2 text-dark text-center">풀이 시간</div>
                <div className="body1 text-center mt-2 text-gray-400">
                  권장시간 04:00
                </div>
              </div>
            </div>
          </div>
          <div className="complete-box col-md-7 mx-auto mb-8">
            <div className="complete-timer">
              <div className="complete-title">문제 풀이 결과</div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={() => {
                    setIsNotCorrect(!isNotCorrect);
                  }}
                  checked={isNotCorrect}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  오답만 보기
                </label>
              </div>
            </div>
            {render()}
            <div className="mo-fixed-bottom py-3">
              <a
                href="video-content.html"
                className="btn btn-lg btn-primary w-100"
              >
                추천강의 시작하기
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
