import React, { useState } from "react";

interface LevelTestRes {
  userType: string;
  setUserType: (userType: string) => void;
  levelAnswers: Map<number, number>;
  levelAnswerTimers: Map<number, string>;
  setLevelAnswer: (
    level: number,
    levelAnswer: number,
    levelAnswerTimer: string
  ) => void;
}

export const LevelTestResContext = React.createContext<LevelTestRes>(
  undefined!
);

export const LevelTestResProvider = ({ children }: any) => {
  const [userType, setUserType] = useState("");
  const [levelAnswers, setLevelAnswers] = useState(new Map<number, number>());
  const [levelAnswerTimers, setLevelAnswerTimers] = useState(
    new Map<number, string>()
  );

  let res: LevelTestRes = {
    userType: userType,
    setUserType: function (userType: string): void {
      setUserType(userType);
    },

    levelAnswers: levelAnswers,
    levelAnswerTimers: levelAnswerTimers,
    setLevelAnswer: (
      level: number,
      levelAnswer: number,
      levelAnswerTimer: string
    ) => {
      levelAnswers.set(level, levelAnswer);
      setLevelAnswers(levelAnswers);
      levelAnswerTimers.set(level, levelAnswerTimer);
      setLevelAnswerTimers(levelAnswerTimers);
    },
  };

  return (
    <LevelTestResContext.Provider value={res}>
      {children}
    </LevelTestResContext.Provider>
  );
};
