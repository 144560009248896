/* eslint-disable import/no-anonymous-default-export */

import { Dialog } from "@material-ui/core"
import DaumPostcode, { AddressData } from "react-daum-postcode";

interface Props {
  isOpen: boolean;
  changeIsOpen: (flag: boolean) => void;
  onComplete: (zondecode: string, fullAddress: string) => void;
}

export default (props: Props) => {
  const handlePostComplete = (data: AddressData) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }
    props.onComplete(data.zonecode, fullAddress);
  }

  const handleClose = (e: {}, reason: string) => {
    props.changeIsOpen(false);
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
    >
      <DaumPostcode
        onComplete={handlePostComplete}
        style={{
          width: "600px",
          height: "400px"
        }}
      />
    </Dialog>
  )
}