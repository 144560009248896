import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';

function ConstructionSpecialCondition(props) {
  const worklist = props.worklist;
  if(!worklist) {
    return <div>로딩중...</div>
  }
  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }
  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <p className="doc-title" style={{ fontSize: "30px", textAlign: "center", height: "70px" }}>공사계약 특수조건</p>
          <p style={{ lineHeight: "20px" }}><strong>제 1조(목적)</strong> 이 공사계약특수조건(이하 "특수조건"이라 한다)은 {worklist?.contractRegion} 계약담당공무원과 계약상대자가 체결하는 공사도급계약의 내용을 규정함을 목적으로 한다.</p>
          <p style={{ lineHeight: "20px" }}><strong>제 2조(정의)</strong> ① 이 특수조건에서 사용하는 용어의 정의는 특수조건에서 달리 정하는 경우를 제외하고는 회계예규 공사계약일반조건(이하 "일반조건"이라 한다) 제 2조에서 정한 바에 따른다. <br />
            ② 공사의 착공, 감독, 하도급관리, 대가의 지급, 검사, 재해방지조치, 인수, 하자관리 등 공사현장에서 계약이행과 관련된 사항에 대해서는 달리 규정한 경우를 제외하고는 {worklist?.contractRegion}장(그 위임을 받은 공무원을 포함한다. 이하 같다)을 계약담당공무원으로 본다.</p>
          <p style={{ lineHeight: "20px" }}><strong>제 3조(수입인지 및 공채의 매입</strong> 계약상대자는 인지세법 및 제주도지역개발기금설치조례 등에서 정한 바에 따라 수입인지의 매입 및 제주도지역개발공채(대금청구시 마다 청구금액의 2.5%)의 매입필증을 계약담당공무원에게 제출하여야 한다.</p>
          <p style={{ lineHeight: "20px" }}><strong>제 4조(장기계속계약의 잔여공사 계약)</strong> 장기계속계약의 경우 제2차이후 공사계약은 부기한 총공사 부기금액에서 이미 계약된 금액을 공제한 잔여금액의 범위안에서 계약을 체결하여야 한다.</p>
          <p style={{ lineHeight: "20px" }}><strong>제 5조(공사보험의 가입)</strong> ① 계약상대자는 회계예규 공사손해보험 가입업무 집행요령(이하 "집행요령"이라 한다) 및 일반조건 제10조에서 정하는 바에 의하여 계약담당공무원이 선정하는 보험회사와 건설공사보험 또는 조립보험(이하 "공사보험"이라 한다)에 가입하여야 한다. <br />
            ② 집행요령 제5조에서 정한 손해의 담보에 대한 계약상대자의 자기부담금은 매 건당 각각 3천만원이하로 한다. <br />
            ③ 보험약관은 특별히 정한 경우를 제외하고는 독일식 보통약관으로 한다. <br />
            ④ 보험회사는 공사보험 계약일로부터 1개월이내에 위험도 조사보고서를 제출하되 보험가입금액이 300억원이상인 경우에는 공정율 50%전후에 기 제출한 위험도 조사보고서의 내용을 보완하여 제출하여야 하며, 계약상대자는 보험회사로부터 제출된 위험도 조사보고서에 따른 적절한 위험방지 조치를 취하여야 한다. <br />
            ⑤ 공사계약금액이 증감된 경우 보험가입금액의 증감은 집행요령 제5조제4항에 의하며, 계약상대자는 계약금액 변경일로부터 14일이내에 보험계약을 변경처리하여야 한다. <br />
            ⑥ 계약상대자는 운용요령 제10조제2호에 따라 계약금액의 변경, 설계변경, 공사중단 등의 공사계약 변경사항을 보험회사에 통지하여야 한다. <br />
            ⑦ 공사보험대상공사에 대한 공사보험가입 지연으로 발생하는 보험사고의 보상․배상은 계약상대자 부담으로 하며 보험대상공사의 준공일까지 공사보험에 가입하지 않는 경우에는 예정가격조서상에 계상된 보험료를 감액 조치한다. <br />
            ⑧ 계약상대자는 보험사 선정시 당해보험사의 재정상태, 담보등력등을 감안하여 건실한 보험사를 선정하여야 하며 부실보험사 선정으로 보상 또는 배상을 받지 못한 경우에는 계약상대자가 부담으로 한다. <br />
            ⑨ 계약상대자는 예정가격조서상에 계상된 보험료와 보험가입시 실제 납입한 보험료간의 차액발생을 이유로 보험가입을 거절하거나 동 차액의 정산을 요구할 수 없으며, {worklist?.contractRegion}는 보험가입 이전에 청구하는 기성대가에 대하여는 지급을 유보할 수 있다. <br />
            ⑩ 보험회사 및 계약상대자는 보험계약서류, 위험도 조사보고서, 보험사고 발생 및 처리현황, 보험계약종결보고서를 {worklist?.contractRegion}에 제출하여야 하며, 보고 또는 제출을 태만히 한 경우에는 {worklist?.contractRegion} 입찰․계약과정과 보험사 선정에 불이익을 부여하여도 이의를 제기할 수 없다. </p>
          <p style={{ lineHeight: "20px" }}><strong>제6조(공사의 착공)</strong> 계약상대자는 계약체결일로부터 7일이내에 공사를 착공하고 착공계를 제출하여야 한다.</p>
        </div>

        <div className="page-doc">
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제7조(선금의 지급 및 반환)</strong> ① 선금급을 지급 요청할 때는 지방자치단체 선금지급요령에 의거 증권 또는 보증서 및 선금급 사용계획서와 선금급율에 의거 하도급 대금을 현금으로 지급하고 이를 이행하지 않을 경우에는 {worklist?.contractRegion}의 어떠한 조치에도 이의를 제기하지 않겠다는 각서를 제출하여야 한다. <br />
            ② 선금급 수령 후 하도급 계약체결 통보시 하수급인에 대한 선금급 지급증빙서(세금계산서 등)를 첨부하여야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제8조(기성부분 지급)</strong> 공사 착공후 준공시 까지 기성부분 검사를 필하고 기성부분에 대한 대가를 지급할 수 있다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제9조(하수급인의 선정)</strong> 계약상대자는 건설산업기본법령등의 규정에 의한 공사의 하도급을 시행하고자 할 때에는 제주도내에 주된 영업소를 둔 전문건설업자에게 하도급 계약을 하여야 한다.  다만, 제주도내에 해당업종의 전문건설업자가 없을 경우에는 그러하지 아니하다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제10조(하도급 대금의 지급보증 등)</strong> 계약상대자는 건설산업기본법령 및 공사계약일반조건의 규정에 의한 하도급 계약 체결시에 하수급인에 대하여 하도급 대금의 지급보증을 이행하여야 하며, 하도급 승낙요청 또는 통지시에 계약상대자는 계약담당무원에게 하도급대금 지급을 보증하는 문서를 제출하여야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제11조(노임지급)</strong> 계약담당공무원이 원활한 계약이행을 위하여 필요하다고 인정할 경우에는 공사현장에 종사하는 근로자의 노임지급과 관련하여 필요한 지시를 할 수 있으며, 이에 대하여 계약상대자가 이행하지 아니할 경우 계약담당공무원은 당해 공사대금에서 노임을 공제하여 근로자에게 지불할 수 있다. 다만, 현장근로자의 노임을 직접 공제 해서는 아니되는 정당한 사유가 있음을 계약상대자가 증명한 경우에는 그러하지 아니하다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제12조(환경오염 방지등)</strong> ① 계약상대자는 대기오염, 수질오염, 소음, 진동, 악취등으로 환경피해 발생 및 인근 주민이나 통행인에게 불편이 없도록 대기환경보전법, 수질환경보전법, 소음․진동규제법, 폐기물관리법, 오수․염물질 종류와 예상량, 처리방법, 처리시행자등이 포함된 환경오염방지계획을 수립하여야 한다. <br />
            ② 계약상대자는 공사착공신고서 제출시 제1항의 환경오염방지계획서를 계약담당공무원에게 제출하여 승인을 받아야 하며, 계약담당공무원이 보완을 요청하였을 때에는 이에 따라야 한다. <br />
            ③ 계약상대자는 환경오염방지계획서를 현장에 비치하고, 오염방지 이행실적을 기록 유지하여야 하며, 계약담당공무원의 환경오염방지계획 및 이행실적 제출 요청시에는 이에 따라야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제13조(적격심사 관련사항의 준수의무)</strong> ① 지방자치단체 시설공사적격심사 세부기준에 따라 제출한 다음사항은 일반조건 제61조에 의한다.  다만, 적격심사시 제출한 서류중 부적정 판정 또는 관계 법령에 적합하지 아니한 사항에 대하여는 착공시까지 {worklist?.contractRegion}장에게 수정 제출하여 승인을 받아야 한다. <br />
            1. 현장관리계획의 적정성 평가를 위하여 제출한 현장인력관리계획 <br />
            2. 공사관리계획의 적정성 평가를 위하여 제출한 공정․품질․안전․하도급관리계획 및 환경보전계획 <br />
            ② 계약상대자가 시공중 불가피한 사유로 제1항 각호의 계획을 변경하고자 할 경우에는 당초의 내용과 비교하여 동등이상의 범위내에서 {worklist?.contractRegion}장의 사전 승인을 받아야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제14조(안전관리비 및 건설근로자퇴직공제부담의 목적외 사용금지 등)</strong> ① 계약상대자가 계약금액에 포함된 안전관리비 및 건설근로자퇴직공제부금을 산업안전보건관계법령 또는 건설산업기본법령에서 정한 목적 이외에 사용하거나 상기 목적에 사용하지 아니한 경우 그 금액에 대하여는 계약금액에서 감액조정한다. <br />
            ② 계약상대자는 제1항에서 정한 안전관리비를 목적외에 사용하지 않았음을 이유로 안전관리를 소홀히 하여서는 아니된다. <br />
            ③ 계약상대자가 산업안전보건관계법령등에 의한 재해예방 기술지도중 일반기술지도를 받지 아니한 경우에는 안전관리비의 40%에 해당하는 금액을, 전문기술지도를 받지 아니한 경우에는 안전관리비의 30%에 해당하는 금액을 계약대금에서 감액한다.  다만, 다음의 각호의 경우에는 그러하지 아니한다. <br />
            1. 공사기간이 3월미만의 건설공사 </p>
        </div>

        <div className="page-doc">
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}>2. 육지와 연결되지 아니한 도서지역(제주도를 제외한다)에서 행하여지는 공사 <br />
            3. 법 제48조 제3항의 규정에 의한 유해․위험방지계획서를 제출대상 <br />
            ④ 계약상대자가 산업안전보건법령 등에 의하여 기술지도계약을 체결하지 아니한 경우에는 법30조 제1항의 규정에 의하여 계상한 안전관리비의 20%에 해당하는 금액을 감액하며, 기술지도계약을 지연체결하여 수수료가 조정된 경우에는 조정된 금액만큼 안전관리비를 감액한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제15조(공사감독관의 지시)</strong> 계약상대자는 공사감독관의 구두지시가 있을 경우(지시내용의 이행 전후에 관계없이) 이를 지체없이 서면으로 확인하여야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제16조(계약상대자에 의한 도면)</strong> ① 공사 일부분의 시공상세도면을 계약상대자가 작성토록 설계서에 명시된 경우 계약상대자는 필요한 시공상세도면․계산서 등을 작성 제출하여 당해 공종의 착공전까지 공사감독관의 확인을 받아야 한다. <br />
            ② 계약상대자는 제1항의 시공상세도를 제출한 후 공사감독관의 이에 대한 서면승인을 받기 이전까지 당해 공종을 착수해서는 아니된다.  시공상세도에 대한 공사감독관의 승인을 제1항에 따른 계약상대자의 책임을 감면시키지 아니한다. <br />
            ③ 공사감독관은 공사전․공사중 또는 준공여부와 관계없이 계약상대자가 제공한 시공상세도에서의 결함을 발견한 경우에는 그의 시공상세도를 변경․수정토록 하고 그에 따라 시공토록 지시할 수 있다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제17조(인접공사 계약자에 대한 협조)</strong> ① 계약상대자는 {worklist?.contractRegion}의 요구가 있을 경우 본 공사현장내 또는 인접 공사현장(이하 “인접공사”라 한다)에서 다음 각호의 자가 행하는 그들의 공사 이행에 필요한 모든 편의를 제공하야 한다. <br />
            1. {worklist?.contractRegion}와 계약된 타 계약자 및 그들의 고용인 <br />
            2. 인접 공사현장의 시공자 <br />
            ② 본 계약의 이행에 있어 공사 전체 또는 일부분이 인접공사 계약자에 의한 공사의 적절한 시행 또는 그 결과에 의존할 때에는, 계약상대자는 본 계약의 이행에 악영향을 끼칠 수 있는 명백한 위반 또는 결함을 조사하여 즉시 서면으로 공사감독관에게 통보하여야 한다.  계약상대자가 이러한 사항을 공사감독관에게 통보하지 아니한 경우 계약상대자는 인접공사 계약자에 의한 상기 작업이 본 공사를 진행하는데 적합함을 인정하는 것으로 본다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제18조(하자담보)</strong> 일반조건 제39조에서 정한 하자담보책임기간은 준공검사를 완료한 날로부터 계약서에 정한 바에 따른다.  다만, 공종별 하자담보책임기간은 시행규칙 제68조에서 정한 바에 따른다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제19조(하자보수 책임승계 등)</strong> ① 전 계약상대자가 이행한 공사를 계속하여 시공하는 계약상대자 또는 그 연대보증인은 그 자신의 귀책사유로 인하여 하자가 발생하지 아니하였다는 것을 증명하지 못하는한 전 계약상대자에 의하여 이행된 부분에 보수의 책임을 진다.  다만, 하자책임 구분이 분명한 경우는 그러하지 아니한다. <br />
            ② 일반조건 제41조에서 정한 하자보수보증금은 계약서에 정한 바에 따른다.  다만, 공종별 하자보수보증금율은 시행규칙 제70조에서 정한 바에 따른다. <br />
            ③ 계약상대자는 하자보수 보증기간 계류중에는 6개월마다 1회씩 정기적으로 하자발생여부를 순회점검 확인하고 하자가 발생하였을 경우에는 {worklist?.contractRegion}에 서면 보고하여야 하며, {worklist?.contractRegion}의 지시에 따라 지체없이 하자 보수를 하여야 한다.</p>
          <p style={{ lineHeight: "20px" }}><strong>제20조(공사관리)</strong> ① 계약담당공무원은 계약관리를 위하여 필요하다고 인정할 경우에는 공사현장에 대하여 다음 각호의 사항을 조사․점검하거나 계약상대자에게 자료를 요구할 수 있다. <br />
            1. 시공상태 <br />
            2. 안전관리 상태 <br />
            3. 설계변경 등 계약내용 변경에 관한 사항 <br />
            4. 공사현장 관리상태 <br />
            5. 하도급에 관한 사항 <br />
            6. 기타 계약조건 이행사항</p>
        </div>

        <div className="page-doc">
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}>② 계약담당공무원은 제1항 의한 조사․점검 결과 계약상대자가 계약조건 및 설계서 등의 계약내용과 다르게 시공한 사항에 대하여는 계약상대자에게 통보하여 시정토록 조치할 수 있다. <br />
            ③ 계약담당공무원은 제1항 또는 제2항의 결과에 대하여 시행령 제23조 및 제41조의 규정에 의한 심사에 반영할 수 있다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제21조(영세민 우선고용)</strong> 계약상대자는 취로증 소지자를 우선적으로 공사 현장에 고용하여야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제22조(감액 또는 환불)</strong> 계약상대자는 본 계약을 위하여 설계서 내역중 정부가 정한 건설표준품셈, 물품단가 또는 노임단가 기준보다 과다하게 책정되었거나 제잡비율 적용에 착오가 있을 경우 계약체결 및 준공 후라도 발견되어 정부 또는 감사기관 및 {worklist?.contractRegion}로부터 감액 또는 환불 요구가 있을 때에는 이의없이 수락하여야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제23조(법령의 준수 등)</strong> ① 계약상대자는 각종 법령, 조례, 규칙 또는 공사 수행과 관련하여 공공기관에 의해 요구되는 사항을 준수하여야 하며, 법령 및 계약내용등이 상호 일치되지 않거나 모순으로 계약이행에 어려움이 예상될 경우 계약상대자는 지체없이 계약담당공무원에게 이를 서면으로 확인하여야 한다. <br />
            ② 계약상대자는 여하한 경우에도 이 계약체결․이행함에 있어서 관련공무원에게 금품 또는 향응을 제공하여서는 아니된다. <br />
            ③ 제2항의 규정을 위반함으로써 받게 되는 모든 불이익에 대한 책임은 계약상대자에게 있다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제24조(공사안전관리)</strong> 계약상대자는 안전관리자를 현장에 상주시켜 공사시 발생되는 제반위험상태를 사전에 방지하도록 하며, 공사 현장 표지판에 안전관리 책임자의 성명을 명시하여야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제25조(과적운행금지)</strong> 공사현장 건설자재 등 운송하는 모든 차량 및 중기는 과적 운행을 해서는 아니된다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제26조(계약의 해지․해제사유로 인한 보증시공 청구)</strong> ① 일반조건 제51조 제1항 각호의 사유로 인하여 계약을 해지․해제할 수 있을 경우에는 연대보증인 또는 공사이행보증회사에게 보증시공을 청구하거나 공동수급체구성원에게 잔여공사를 완성하게 할 수 있다. <br />
            ② 계약상대자(공동수급체 구성원을 포함한다.  이하 같다)의 부도발생으로 인하여 준공기한까지 공사를 완성할 가능성이 없다고 인정될 경우에는 제1항을 준용한다.  이 때에 계약상대자의 동의를 필요요건으로 하지 않을 수 있다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제27조(피해보상)</strong> 공사목적물의 인도전에 발생한 손해가 {worklist?.contractRegion}의 책임이 아닌 사유로 인한 것은 모두 이를 계약상대자의 부담으로 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제28조(보칙)</strong> ① 회계예규 공동도급계약운용요령에 의거 공동수급체가 제출한 공동수급협정서는 계약담당공무원의 사전 승인 없이는 변경할 수 없다. <br />
            ② 계약이행과 관련한 기간의 계산은 특별한 규정이 없는 한 민법에서 정한 바에 따른다. <br />
            ③ 일반조건 제28조에 의하여 물가변동에 의한 계약금액을 조정할 경우에는 특별한 사유가 없는 한 지수조정율을 적용한다. <br />
            ④ 계약상대자는 계약서에 명시된 주소가 변경되었을 때에는 즉시 계약담당공무원 및 공사감독관에게 서면으로 주소변경 신고를 하여야 하며, 주소변경 신고를 하지 아니함으로써 발생하는 불이익은 계약상대자에게 귀속된다. <br />
            ⑤ 계약상대자의 전화․팩스번호 등 의사전달 수단의 변경시에도 제4항을 준용한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제29조(공사 시공 관리대장 작성 및 통보.의무이행 등)</strong> 계약상대자는 시공관리 대장을 작성하여 착공일로부터 30일이내에 계약담당공무원에게 통보하여야 한다.</p>
          <p style={{ lineHeight: "20px", marginBottom: "10px" }}><strong>제30조(조문의 해석)</strong> 조문의 해석에 의문이 있을 경우에는 계약담당공무원의 해석에 따른다.</p>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default ConstructionSpecialCondition;