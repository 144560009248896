import React, { useMemo } from 'react';
import { Form, Input, Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './AIPostForm.module.scss';
import { api } from '../../config/api';
import { message } from 'antd';

interface AIPostFormProps {
  onSubmit: (values: { title: string; content: string }) => void;
  initialValues?: { title: string; content: string };
  isEditing?: boolean;
}

const AIPostForm: React.FC<AIPostFormProps> = ({ onSubmit, initialValues, isEditing = false }) => {
  const [form] = Form.useForm();

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0];
      if (file) {
        try {
          // 이미지 업로드 API 호출
          const response = await api.uploadFileAsync(file, 'community');
          const imageUrl = response.data.url;

          // 에디터에 이미지 삽입
          const quill = document.querySelector('.ql-editor');
          const range = document.getSelection()?.getRangeAt(0);
          if (quill && range) {
            const img = document.createElement('img');
            img.src = imageUrl;
            range.insertNode(img);
          }
        } catch (error) {
          console.error('이미지 업로드 실패:', error);
          message.error('이미지 업로드에 실패했습니다.');
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    },
    clipboard: {
      matchVisual: false,
      allowed: {
        tags: ['img', 'p', 'br', 'strong', 'em', 'span'],
        attributes: ['src', 'alt']
      }
    }
  }), []);

  const handleSubmit = (values: { title: string; content: string }) => {
    onSubmit(values);
    if (!isEditing) {
      form.resetFields();
    }
  };

  return (
    <div className={styles.aiPostFormContainer}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
        className={styles.aiPostForm}
      >
        <Form.Item
          name="title"
          label="제목"
          rules={[{ required: true, message: '제목을 입력해주세요' }]}
        >
          <Input size="large" placeholder="제목을 입력하세요" />
        </Form.Item>
        <Form.Item
          name="content"
          label="내용"
          rules={[{ required: true, message: '내용을 입력해주세요' }]}
        >
          <ReactQuill 
            theme="snow" 
            style={{ height: '300px', marginBottom: '50px' }}
            placeholder="내용을 입력하세요"
            modules={modules}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            {isEditing ? '수정하기' : '작성하기'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AIPostForm;
