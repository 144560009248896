import { Link } from "react-router-dom";
import { pages } from "../../../constants/PagePaths";

export default () => {
  return (
    <section>
      <div className="container py-120">
        <div className="section-header">카테고리</div>
        <div className="category-menu-wrap">
          <div className="category-menu-section w-lg">
            <div className="header-title1">업무 자동화 프로그램</div>
            <div className="category-item c-4">
              <Link
                className="category-btn"
                to={pages.costprojects + "/normal"}
              >
                <span className="category-badge">추천</span>
                <img src="/images/ddusul/icon/01.png" />
                설계내역
              </Link>
              <Link
                className="category-btn"
                to={pages.costprojects + "/estimate"}
              >
                <img src="/images/ddusul/icon/08.png" />
                견적서
              </Link>
              <Link className="category-btn" to={pages.costprojects + "/copy"}>
                <img src="/images/ddusul/icon/10.png" />
                입찰내역
              </Link>
              <Link className="category-btn" to={pages.excel}>
                <span className="category-badge">인기</span>
                <img src="/images/ddusul/icon/03.png" />
                엑셀 자동화
              </Link>
            </div>
          </div>
          <div className="category-menu-section w-sm">
            <div className="header-title1">동영상</div>
            <div className="category-item c-2">
              {/* <Link className="category-btn" to="#">
                <img src="/images/ddusul/icon/05.png" />
                취업
              </Link> */}
              <Link className="category-btn" to={pages.video}>
                <img src="/images/ddusul/icon/06.png" />
                동영상 강의
              </Link>
              <Link className="category-btn" to={pages.lectureroom + "/0"}>
                <img src="/images/ddusul/icon/09.png" />
                사용법 강의
              </Link>
              {/* <Link className="category-btn" to="#">
                <img src="/images/ddusul/icon/07.png" />
                프리랜서
              </Link> */}
            </div>
          </div>
          <div className="category-menu-section w-sm">
            <div className="header-title1">업무지원</div>
            <div className="category-item c-2">
              <Link className="category-btn" to={pages.communityAi}>
                <img src="/images/ddusul/icon/ai-community-icon.svg" />
                AI 질의응답
              </Link>
              <Link className="category-btn" to={pages.contact}>
                <img src="/images/ddusul/icon/02.png" />
                설계 용역
              </Link>
              <Link className="category-btn" to={pages.data}>
                <img src="/images/ddusul/icon/04.png" />
                서류 자료실
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
