import { EnviManagePlanModel } from "../models/beginConstDoc/EnviManagePlanModel";
import { EnviManageStateModel } from "../models/completionConstDoc/EnviManageStateModel";

export const EnviManageData: EnviManagePlanModel = {
  EnviPlanItems: [
    {
      //// 1. 비산먼지방지시설
      itemName: "비산먼지방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "세륜시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "살수시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "살수차량",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진덮개",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진벽",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진망(막)",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "진공청소기",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "집진시설(이동식, 분무식)",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "기계식청소장비",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 2. 소음, 진동방지시설
    {
      itemName: "소음진동방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "방음벽",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "방음망",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "소음기",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방음덮개",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방음터널",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방음림",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "흡음장치및시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "제진시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "탄성지지시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진구시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진고무",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "배관진동절연장치및시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 3. 폐기물처리시설
    {
      itemName: "폐기물처리시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "소각시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "쓰레기슈트",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "폐자재수거박스",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "폐기물보관시설(덮개,배수로)",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "건설오니처리시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "브레이커",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "폐기물선별기",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 4. 수질오염방지시설
    {
      itemName: "수질오염방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "오폐수처리시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "가배수로",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "임시용측구",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "절성토면비닐덮개",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "침사및응집시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "오탁방지막",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "오일펜스",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "유화제",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "흡착포",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "단독정화조",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "이동식간이화장실",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 5. 기타방지시설
    {
      itemName: "기타방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "살수차운행 외",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "쓰레기 및 도로청소",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "분진망 설치",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "유류저장소",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "토목용부직포",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "차광막",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "차광막 설치",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    }
  ]
}

export const EnviManageStateData: EnviManageStateModel = {
  EnviStateItems: [
    {
      //// 1. 비산먼지방지시설
      itemName: "비산먼지방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "세륜시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "살수시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "살수차량",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진덮개",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진벽",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진망(막)",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "진공청소기",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "집진시설(이동식, 분무식)",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "기계식청소장비",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 2. 소음, 진동방지시설
    {
      itemName: "소음진동방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "방음벽",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "방음망",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "소음기",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방음덮개",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방음터널",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방음림",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "흡음장치및시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "제진시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "탄성지지시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진구시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "방진고무",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "배관진동절연장치및시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 3. 폐기물처리시설
    {
      itemName: "폐기물처리시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "소각시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "쓰레기슈트",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "폐자재수거박스",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "폐기물보관시설(덮개,배수로)",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "건설오니처리시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "브레이커",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "폐기물선별기",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 4. 수질오염방지시설
    {
      itemName: "수질오염방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "오폐수처리시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "가배수로",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "임시용측구",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "절성토면비닐덮개",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "침사및응집시설",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "오탁방지막",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "오일펜스",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "유화제",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "흡착포",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "단독정화조",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "이동식간이화장실",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    },
    ///// 5. 기타방지시설
    {
      itemName: "기타방지시설",
      totalPrice: 0,
      EnviItems: [{
        EnviSubItem: "살수차운행 외",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ''
      },
      {
        EnviSubItem: "쓰레기 및 도로청소",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "분진망 설치",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "유류저장소",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "토목용부직포",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "차광막",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      },
      {
        EnviSubItem: "차광막 설치",
        productName: "",
        productPrice: 0,
        commaProductPrice: '',
        note: ""
      }]
    }
  ]
}